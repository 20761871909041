import { TariffType } from "../utils/dialogs";
import { CurrenciesType } from "../views/Settings/CompanySettings/currency";
import { AllConnections } from "./connections/types";

export type Company = {
  id: number;
  name: string;
  ownerId: number;
  isPartner: boolean;
  paymentsLocked?: boolean;
  timezone: string;
  paymentCurrency: CurrenciesType;
  waba360DialogPartnerId?: TariffType;
  hadConnections?: boolean;
};

export type Companies = {
  count: number;
  companies: Company[];
};

export type Members = {
  count: number;
  companyId: number;
  members: UsersType[];
  currentMemberRole?: RoleType;
};
export type UsersType = {
  roleId: number;
  isOwner: true
  login: string;
  id: number;
  userId: number;
  email: string;
  displayName: string;
  companyId: number;
};

export type SubscriptionPlan = 'FREE' | 'START' | 'STANDARD' | 'ADVANCED';

export type ResGetAllConnForChatType = {
  connectionId: number;
  name: string;
  type: AllConnections;
  createChat: {
    withPhone: boolean;
    withUsername: boolean;
  };
  subscription: {
    id: number | null;
    paidUntil: string | null;
    subscriptionPlan: SubscriptionPlan;
  };
};

export type ReqGetAllConnForChatType = {
  companyId: number;
};

export type CreateCompanyRequest = {
  name: string;
  promo_code?: string;
  timezone: string,
  phone?: string;
  payment_currency?: string;
};

export type UpdateCompanyParam = {
  companyId: string;
  name: string;
  timezone: string;
  payment_currency: string;
};

export type UserInfo = {
  id: string;
  login: string;
  email: string;
  scopes: string[];
  roles: string[];
  locale: string;
  name: string;
};

export type ProfileSettings = {
  userId: number;
  language: string;
  notificationsEnabled: boolean;
  firstLogin: boolean;
};

export type EditProfileSettings = {
  language: string;
  notifications_enabled: boolean;
};

export type ConnectionBlacklist = {
  id: number;
  phone: string;
  createdAt: string;
};

export type RoleType = {
  id: number;
  name: string;
  systemType: string | null;
  permissions: string[];
  mockId?: number;
};

export type RemoveMemberType = {
  companyId: number;
  memberId: number;
};

export type AddRoleParamType = {
  companyId: string;
  name: string;
  permissions?: string[];
};

export type ChangeRoleParamType = {
  companyId: string;
  roleId: number;
  name: string;
  permissions?: string[];
};

export type DeleteRoleParamType = {
  companyId: number;
  roleId: number;
  replacement_role_id: number;
};

export type ChangeRoleMemberParamType = {
  companyId: string;
  memberId: number;
  roleId: number;
};

export type CompaniesListRequest = {
  limit?: number;
  offset?: number;
  with_partner_companies?: boolean;
};

// onboarding types
export const roles = ['sales_head', 'technical_specialist', 'integrator', 'business_owner', 'marketing', 'manager', 'other'] as const;

export const niches = [
  'automotive', 'administrative_staff', 'security', 'top_middle_management', 'mining', 'home_service_personnel',
  'procurement', 'information_technologies', 'art_entertainment_media', 'marketing_pr', 'medicine_pharmaceutical',
  'science_education', 'sales_customer_service', 'manufacturing_service', 'working_personnel', 'retail', 'agriculture',
  'sport_fitness_beauty', 'strategy_investments_consulting', 'insurance', 'construction_real_estate',
  'transport_logistics', 'tourism_hotels_restaurants', 'hr_management_training', 'finance_accounting', 'lawyers',
  'other'] as const;

export type RolesType = typeof roles[number];
export type NichesType = typeof niches[number];

export const companyOnboardingSteps = [
  'watch_intro_video', 'choose_role', 'choose_niche', 'choose_crm', 'add_connection',
  'add_integration', 'setup_notifications', 'remind_test_payment'
] as const;

export const connectionsOnboarding = [
  'whatsapp', 'telegram', 'waba', 'telegram_bot', 'odnoklassniki',
  'tinkoff', 'modulbank', 'paykeeper', 'bepaid', 'yookassa'] as const;

export const connectionsMessengerOnboarding = [
  'whatsapp', 'telegram', 'waba', 'telegram_bot'] as const;

export type ConnectionsMessengerOnboarding = typeof connectionsMessengerOnboarding[number];

export type ConnectionsOnboarding = typeof connectionsOnboarding[number];

export type CompanyOnboardingSteps = typeof companyOnboardingSteps[number];

export type CompanyOnboardingStepResult = {
  value: string;
  customValue?: string;
};

export type CompanyOnboardingStep = {
	isFinished: boolean;
	type: CompanyOnboardingSteps;
  result?: CompanyOnboardingStepResult;
};

export type CompanyOnboarding = {
	fromPartner: boolean;
  isCancelled: boolean;
  isFinished: boolean;
	steps: CompanyOnboardingStep[];
};

type GenericOnboardingStepUpdate<T, B extends CompanyOnboardingSteps> = {
  is_finished: boolean;
  type: B;
  result?: T;
};

export type CompanyOnboardingStepResultUpdate = {
  value: string;
  custom_value?: string;
};

type ChooseRoleResult = CompanyOnboardingStepResultUpdate;
type ChooseNicheResult = CompanyOnboardingStepResultUpdate;
type ChooseCRMResult = CompanyOnboardingStepResultUpdate;
type AddConnectionResult = {
  value: ConnectionsOnboarding;
};

type WatchVideoStepUpdate = GenericOnboardingStepUpdate<undefined, 'watch_intro_video'>;
type ChooseRoleStepUpdate = GenericOnboardingStepUpdate<ChooseRoleResult, 'choose_role'>;
type ChooseNicheStepUpdate = GenericOnboardingStepUpdate<ChooseNicheResult, 'choose_niche'>;
type ChooseCRMStepUpdate = GenericOnboardingStepUpdate<ChooseCRMResult, 'choose_crm'>;
type AddConnectionStepUpdate = GenericOnboardingStepUpdate<AddConnectionResult, 'add_connection'>;
type AddIntegrationStepUpdate = GenericOnboardingStepUpdate<undefined, 'add_integration'>;
type NotificationStepUpdate = GenericOnboardingStepUpdate<undefined, 'setup_notifications'>;
type RemindTestPaymentStepUpdate = GenericOnboardingStepUpdate<undefined, 'remind_test_payment'>;

type CompanyOnboardingStepUpdate =
  WatchVideoStepUpdate
  | ChooseRoleStepUpdate
  | ChooseNicheStepUpdate
  | ChooseCRMStepUpdate
  | AddConnectionStepUpdate
  | AddIntegrationStepUpdate
  | NotificationStepUpdate
  | RemindTestPaymentStepUpdate;

export type CompanyOnboardingUpdate = {
  company_id: number;
  is_cancelled?: boolean;
	steps: CompanyOnboardingStepUpdate[];
};

export type QuestioningType = 'ROLE_AND_NICHE';

export type RoleAndIndustryUserType = {
  role: RolesType;
  custom_role?: string;
  niche: NichesType;
  custom_niche?: string;
};

export type AvailableQuestioning = {
  availableFormTypes: Array<QuestioningType | null>;
};

export type QuestioningCreate = {
  type: QuestioningType;
  company_id: number;
  is_cancelled: boolean;
  params: RoleAndIndustryUserType | null;
};
