import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addRoleMemberAPI,
  createCompany,
  deleteMember,
  getCompanies,
  getCompanyName,
  getMembers,
  getProfileSettings,
  getRolesCompanyAPI,
  getUserInfo,
  joinCompany,
  patchProfileSettings,
  unsetFirstLogin,
  changePermissionRoleMemberAPI,
  deleteRoleMemberAPI,
  changeRoleMemberAPI,
  getConnForChatAPI,
  getCompany,
  updateCompanyAPI,
  getCompanyOnboarding,
  updateCompanyOnboarding, createQuestioningAPI, getAvailableQuestioning,
} from './CompanyAPI';
import { startOnboarding } from '../modals/Onboarding/onboardingSlice';
import { clearSubscriptionsInfo } from '../views/Settings/Subscriptions/SubscriptionsSlice';
import { clearSales } from '../views/Sales/SalesSlice';
import { clearShops } from '../views/Sales/Shops/shopsSlice';
import { YandexMetrikaCall } from '../utils/utils';
import { clearContactsChats } from '../views/Chat/chatsSlice';
import {
  AddRoleParamType,
  AvailableQuestioning,
  ChangeRoleMemberParamType,
  ChangeRoleParamType,
  CompaniesListRequest,
  Company,
  CompanyOnboarding,
  CompanyOnboardingStep,
  CompanyOnboardingSteps,
  CompanyOnboardingUpdate,
  CreateCompanyRequest,
  DeleteRoleParamType,
  EditProfileSettings,
  Members,
  ProfileSettings,
  QuestioningCreate,
  QuestioningType,
  RemoveMemberType,
  ReqGetAllConnForChatType,
  ResGetAllConnForChatType,
  RoleType,
  UpdateCompanyParam,
  UserInfo,
  UsersType
} from './types';
import { clearTemplates } from '../views/Templates/templatesSlice';


export type CompaniesSlice = {
  count: number;
  companies: Company[] | null;
  invitationCompanyName: string | undefined;
  currentCompany: Company;
  profileSettings: ProfileSettings;
  members: Members;
  userInfo: UserInfo | null;
  isLoadingAddNewCompany: boolean;
  rolesCompany: RoleType[];
  allConnectionsForChat: ResGetAllConnForChatType[];
  isViewNavbarMobile: boolean;
  isOpenNavbarMobile: boolean;
  onboarding: CompanyOnboarding | null;
  onboardingGuideOn: CompanyOnboardingSteps | null;
  onboardingSwitchModal: boolean;
  availableQuestioning: (QuestioningType | null);
};

const initialState: CompaniesSlice = {
  count: 0,
  companies: null,
  invitationCompanyName: undefined,
  profileSettings: {} as ProfileSettings,
  currentCompany: {} as Company,
  members: {} as Members,
  userInfo: null,
  isLoadingAddNewCompany: false,
  rolesCompany: [],
  allConnectionsForChat: [],
  isOpenNavbarMobile: false,
  isViewNavbarMobile: true,
  onboarding: null,
  onboardingGuideOn: null,
  onboardingSwitchModal: true,
  availableQuestioning: null
};

export const fetchInitialData = createAsyncThunk<Company, number | undefined>(
  'companies/fetchCompanies', async (companyId, { dispatch }) => {
    dispatch(fetchUserInfo());
    dispatch(profileSettings());
    let company: Company;
    if (companyId) {
      company = await getCompany(companyId);
    } else {
      const { companies } = await getCompanies({ limit: 1, offset: 0, with_partner_companies: false });
      company = companies[0];
    }
    dispatch(getMember(company.id));
    dispatch(getRolesCompany(company.id));
    return company;
  }
);

export const fetchCompanies = createAsyncThunk<Company[], CompaniesListRequest, { rejectValue: number }>(
  'companies/fetchCompaniesList', async (requestOptions, { rejectWithValue }) => {
    try {
      const { companies } = await getCompanies(requestOptions);
      return companies;
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const addNewCompany = createAsyncThunk<Company, CreateCompanyRequest, { rejectValue: number }>(
  'companies/addNewCompany', async (requestOptions, { dispatch, rejectWithValue }) => {
    try {
      const company = await createCompany(requestOptions);
      await dispatch(getMember(company.id));
      dispatch(clearSubscriptionsInfo());
      dispatch(clearSales());
      dispatch(clearShops());
      dispatch(clearContactsChats());
      dispatch(clearTemplates());
      return company;
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const updateCompany = createAsyncThunk<Company, UpdateCompanyParam,{ rejectValue: number }>(
  'companies/updateCompany',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await updateCompanyAPI(requestOptions);
    }catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchCompanyName = createAsyncThunk('companies/fetchCompanyName', async (requestOptions: string) =>
  await getCompanyName(requestOptions)
);

export const getAllConnectionForChat = createAsyncThunk<ResGetAllConnForChatType[],
  ReqGetAllConnForChatType,
  { rejectValue: number }>('companies/getAllConnectionForChat', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getConnForChatAPI(requestOptions.companyId);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const profileSettings = createAsyncThunk('companies/settingsProfile', async () => await getProfileSettings());

export const editProfileSettings = createAsyncThunk(
  'companies/editProfileSettings',
  async (requestOptions: EditProfileSettings) => await patchProfileSettings(requestOptions)
);

export const joinMemberToCompany = createAsyncThunk<UsersType, string, { rejectValue: number }>(
  'companies/joinCompany',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await joinCompany(requestOption);
    } catch (err) {
      return rejectWithValue(err.response.data.error_code);
    }
  }
);

export const fetchUserInfo = createAsyncThunk('companies/getUserInfo', async () => await getUserInfo());

export const getMember = createAsyncThunk<Members, number, { rejectValue: number }>(
  'companies/getMember', async (requestOption, { rejectWithValue }) => {
    try {
      return await getMembers(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const getRolesCompany = createAsyncThunk<RoleType[], number, { rejectValue: number }>(
  'companies/getRolesCompany', async (companyId) => {
  try {
    return await getRolesCompanyAPI(companyId);
  } catch (e) {
    return e.response.data.error_code;
  }
});

export const addRoleMember = createAsyncThunk<RoleType, AddRoleParamType, { rejectValue: number }>(
  'companies/addRoleMember',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await addRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);
export const changePermissionRoleMember = createAsyncThunk<RoleType, ChangeRoleParamType, { rejectValue: number }>(
  'companies/changePermissionRole',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await changePermissionRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const deleteRoleMember = createAsyncThunk<unknown, DeleteRoleParamType, { rejectValue: number }>(
  'companies/deleteRoleCompany',
  async (requestOption, { rejectWithValue }) => {
    try {
      await deleteRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const changeRoleMember = createAsyncThunk<UsersType, ChangeRoleMemberParamType, { rejectValue: number }>(
  'companies/changeRoleMember',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await changeRoleMemberAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const removeMember = createAsyncThunk<unknown, RemoveMemberType, { rejectValue: number }>(
  'companies/removeMember',
  async (requestOption, {
    rejectWithValue, dispatch,
  }) => {
    try {
      await deleteMember(requestOption).then(() => dispatch(removeDeletedMember({
        memberId: requestOption.memberId }))
      );
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const updateFirstLogin = createAsyncThunk<unknown, undefined, { rejectValue: number }>(
  'companies/updateFirstLogin',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startOnboarding());
      await unsetFirstLogin();
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const fetchOnboarding = createAsyncThunk<CompanyOnboarding, number, { rejectValue: number }>(
  'companies/getOnboarding', async(companyId, { rejectWithValue }) => {
    try {
      return await getCompanyOnboarding(companyId);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const patchOnboarding = createAsyncThunk<CompanyOnboarding, CompanyOnboardingUpdate, { rejectValue: number }>(
  'companies/updateOnboarding', async(requestOptions, { rejectWithValue }) => {
    try {
      return await updateCompanyOnboarding(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchQuestioning = createAsyncThunk<AvailableQuestioning>(
  'companies/fetchQuestioning', async (requestOption, { rejectWithValue }) => {
    try {
      return await getAvailableQuestioning();
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const createQuestioning = createAsyncThunk<AvailableQuestioning, QuestioningCreate, { rejectValue: number }>(
  'companies/createQuestioning', async (requestOption, { rejectWithValue }) => {
    try {
      return await createQuestioningAPI(requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setDeleteRoleCompany: (state, action: PayloadAction<number>) => {
      state.rolesCompany = state.rolesCompany.filter((i) => i.id !== action.payload);
    },
    setCurrentCompany: (state, action) => {
      if (state.companies) {
        const companyById = state.companies.find((company) => company.id === action.payload);
        if (companyById) {
          state.currentCompany = companyById;
        }
      }
    },
    setHadConnections: (state, action: PayloadAction<boolean>) => {
      state.currentCompany = { ...state.currentCompany, hadConnections: action.payload };
      if (state.companies) {
        const indexCompany = state.companies.findIndex((company) => company.id === state.currentCompany.id);
        state.companies[indexCompany].hadConnections = action.payload;
      }
    },
    removeDeletedMember: (state, action: PayloadAction<{ memberId: number }>) => {
      const memberIdx = state.members.members.findIndex((member) => member.id === action.payload.memberId);
      if (memberIdx) {
        state.members.members.splice(memberIdx, 1);
      }
    },
    setUpdateSettingPhonesLanguage: (state, action: PayloadAction<string>) => {
      state.profileSettings.language = action.payload;
    },
    updateIsViewNavbarMobile: (state, action: PayloadAction<boolean>) => {
      state.isViewNavbarMobile = action.payload;
    },
    updateIsOpenNavbarMobile: (state, action: PayloadAction<boolean>) => {
      state.isOpenNavbarMobile = action.payload;
    },
    setOnboardingGuide: (state, action: PayloadAction<CompanyOnboardingSteps | null>) => {
      state.onboardingGuideOn = action.payload;
    },
    setOnboardingSwitchModal: (state, action: PayloadAction<boolean>) => {
      state.onboardingSwitchModal = action.payload;
    },
    updateOnboardingStep: (state, action: PayloadAction<CompanyOnboardingStep>) => {
      if (state.onboarding) {
        const stepIndex = state.onboarding?.steps.findIndex((item) => item.type === action.payload.type);
        if (stepIndex !== -1) {
          state.onboarding.steps[stepIndex] = action.payload;
        }
      }
    },
    clearOnboarding: (state) => {
      state.onboarding = null;
      state.onboardingGuideOn = null;
    },
    clearOnboardingGuide: (state) => {
      state.onboardingGuideOn = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialData.fulfilled, (state, action) => {
        state.currentCompany = action.payload;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        if (state.companies && action.meta.arg.offset && action.meta.arg.offset > 0){
          state.companies.push(...action.payload);
        } else {
          state.companies = action.payload;
        }
      })
      .addCase(profileSettings.fulfilled, (state, action) => {
        state.profileSettings = action.payload;
      })
      .addCase(editProfileSettings.fulfilled, (state, action) => {
        state.profileSettings = action.payload;
      })
      .addCase(getMember.fulfilled, (state, action: PayloadAction<Members>) => {
        state.members = action.payload;
      })
      .addCase(getAllConnectionForChat.fulfilled, (state, action: PayloadAction<ResGetAllConnForChatType[]>) => {
        state.allConnectionsForChat = action.payload;
      })
      .addCase(addNewCompany.pending, (state) => {
        state.isLoadingAddNewCompany = true;
      })
      .addCase(addNewCompany.fulfilled, (state, action: PayloadAction<Company>) => {
        if (state.companies) {
          state.companies.push(action.payload);
          state.isLoadingAddNewCompany = false;
          state.currentCompany = action.payload;
        }
        YandexMetrikaCall('reachGoal', 'createCompany');
      })
      .addCase(updateCompany.fulfilled, (state, action: PayloadAction<Company>) => {
        if (state.companies) {
          state.currentCompany = action.payload;
          state.companies = state.companies.map((company) =>
            company.id === action.payload.id ? action.payload : company
          );
        }
      })
      .addCase(fetchCompanyName.fulfilled, (state, action: PayloadAction<{ companyName: string }>) => {
        state.invitationCompanyName = action.payload.companyName;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.userInfo = action.payload;
      })
      .addCase(updateFirstLogin.fulfilled, (state) => {
        state.profileSettings.firstLogin = false;
      })
      .addCase(joinMemberToCompany.fulfilled, (state, action: PayloadAction<UsersType>) => {
        state.members.members = [action.payload];
        state.members.companyId = action.payload.companyId;
      })
      .addCase(getRolesCompany.fulfilled, (state, action: PayloadAction<RoleType[]>) => {
        state.rolesCompany = action.payload;
      })
      .addCase(addRoleMember.fulfilled, (state, action: PayloadAction<RoleType>) => {
        state.rolesCompany.push(action.payload);
      })
      .addCase(changeRoleMember.fulfilled, (state, action: PayloadAction<UsersType>) => {
        state.members.members = state.members.members.map((i) =>
          i.userId === action.payload.userId
            ? { ...i, roleId: action.payload.roleId }
            : i
        );
      })
      .addCase(changePermissionRoleMember.fulfilled, (state, action: PayloadAction<RoleType>) => {
        state.rolesCompany = state.rolesCompany.map((i) => (i.id === action.payload.id ? action.payload : i));
      })
      .addCase(fetchOnboarding.fulfilled, (state, action: PayloadAction<CompanyOnboarding>) => {
        state.onboarding = action.payload;
      })
      .addCase(patchOnboarding.fulfilled, (state, action: PayloadAction<CompanyOnboarding>) => {
        state.onboarding = action.payload;
      })
      .addCase(fetchQuestioning.fulfilled, (state, action: PayloadAction<AvailableQuestioning>) => {
        state.availableQuestioning = action.payload.availableFormTypes[0];
      })
      .addCase(createQuestioning.fulfilled, (state) => {
        state.availableQuestioning = null;
      });
  },
});

export const {
  setCurrentCompany,
  removeDeletedMember,
  setUpdateSettingPhonesLanguage,
  setDeleteRoleCompany,
  updateIsViewNavbarMobile,
  updateIsOpenNavbarMobile,
  setHadConnections,
  setOnboardingGuide,
  setOnboardingSwitchModal,
  updateOnboardingStep,
  clearOnboarding,
  clearOnboardingGuide
} = companiesSlice.actions;

export default companiesSlice.reducer;
