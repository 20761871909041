import axiosInstance from './AxiosInstance';
import * as typ from './types';

export const addRoleMemberAPI = async (requestOption: typ.AddRoleParamType): Promise<typ.RoleType> => {
  const { companyId, ...rest } = requestOption;
  return await axiosInstance.post(`/companies/${companyId}/roles/`, rest);
};

export const changePermissionRoleMemberAPI = async (requestOption: typ.ChangeRoleParamType): Promise<typ.RoleType> => {
  const { companyId, roleId, ...rest } = requestOption;
  return await axiosInstance.put(`/companies/${companyId}/roles/${roleId}`, rest);
};

export const deleteRoleMemberAPI = async (requestOption: typ.DeleteRoleParamType): Promise<void> =>
  await axiosInstance.delete(`/companies/${requestOption.companyId}/roles/${requestOption.roleId}`, {
    data: {
      replacement_role_id: requestOption.replacement_role_id
    }
  });

export const changeRoleMemberAPI = async (requestOption: typ.ChangeRoleMemberParamType): Promise<typ.UsersType> => {
  const { companyId, roleId, memberId } = requestOption;
  return await axiosInstance.patch(`/companies/${companyId}/members/${memberId}`, { role_id: roleId });
};

export const getConnForChatAPI = async (companyId: number): Promise<typ.ResGetAllConnForChatType[]> =>
  await axiosInstance.get(`companies/${companyId}/messaging/chats/sources/`);

export const getCompanies = async (requestOptions: typ.CompaniesListRequest): Promise<typ.Companies> =>
  await axiosInstance.get('/companies/', { params: { ...requestOptions } });

export const getCompany = async (companyId: number): Promise<typ.Company> =>
  await axiosInstance.get(`/companies/${companyId}`);

export const getCompanyName = async (requestOption: string): Promise<{ companyName: string; }> =>
  await axiosInstance.get(`/companies/name/${requestOption}`);

export const getProfileSettings = async (): Promise<typ.ProfileSettings> => await axiosInstance.get('/users/me/profile');

export const patchProfileSettings = async (requestOption: typ.EditProfileSettings): Promise<typ.ProfileSettings> =>
  await axiosInstance.patch('/users/me/profile', {
    language: requestOption.language,
    notifications_enabled: requestOption.notifications_enabled,
  });

export const createCompany = async (requestOption: typ.CreateCompanyRequest): Promise<typ.Company> =>
  await axiosInstance.post('/companies/', requestOption);

export const updateCompanyAPI = async (requestOption: typ.UpdateCompanyParam): Promise<typ.Company> =>
  await axiosInstance.patch(`/companies/${requestOption.companyId}`, { ...requestOption });

export const joinCompany = async (requestOption: string): Promise<typ.UsersType> =>
  await axiosInstance.post('/companies/join', undefined, { params: { code: requestOption } });

export const getMembers = async (companyId: number): Promise<typ.Members> =>
  await axiosInstance.get(`/companies/${companyId}/members/`);

export const getRolesCompanyAPI = async (companyId: number): Promise<typ.RoleType[]> =>
  await axiosInstance.get(`/companies/${companyId}/roles/`);

export const deleteMember = async (requestOption: typ.RemoveMemberType): Promise<void> =>
  await axiosInstance.delete(`/companies/${requestOption.companyId}/members/${requestOption.memberId}`);

export const getUserInfo = async (): Promise<typ.UserInfo> => await axiosInstance.get('/users/me');

export const unsetFirstLogin = async (): Promise<void> => await axiosInstance.post('/users/me/profile/first_login');

export const getCompanyOnboarding = async (companyId: number): Promise<typ.CompanyOnboarding> =>
  await axiosInstance.get(`/companies/${companyId}/onboarding/`);

export const updateCompanyOnboarding =
  async (requestOptions: typ.CompanyOnboardingUpdate): Promise<typ.CompanyOnboarding> => {
    const { company_id: companyId, ...rest } = requestOptions;
    return await axiosInstance.patch(`/companies/${companyId}/onboarding/`, rest);
  };

export const getAvailableQuestioning = async (): Promise<typ.AvailableQuestioning> => {
  return await axiosInstance.get(`/users/me/user_forms/`);
};

export const createQuestioningAPI = async (requestOption: typ.QuestioningCreate): Promise<typ.AvailableQuestioning> => {
  return await axiosInstance.post(`/users/me/user_forms/`, requestOption);
};
