import { useEffect, useState } from 'react';
import './TelegramBotForm.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';
import { useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from '../../../../../../../store/store';
import { currentCompanySelector } from "../../../../../../../api/companiesSelector";
import { addAlertWithCustomText } from '../../../../../../../components/Alert/alertSlice';
import { createConnection, updateConnection } from '../../../../connectionsSlice';
import { setHadConnections } from "../../../../../../../api/companiesSlice";
import { TelegramBotConnection } from "../../../../../../../api/connections/types";
import { YandexMetrikaCall } from '../../../../../../../utils/utils';
import { useGuide } from '../../../../api/guide';
import Button from "../../../../../../../components/Button";
import GoBackWrapper from '../../../../../../../components/GoBackWrapper';
import Input from '../../../../../../../components/Input';
import Instruction from '../Instruction';


type SubmitSchema = {
  name: string;
  token: string;
};

const TelegramBotForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const currentCompany = useSelector(currentCompanySelector);
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { connectionId } = useParams<{ connectionId: string }>();
  const { service, update } = useGuide();
  const [connection, setConnection] = useState<TelegramBotConnection>();
  const telegramBotsConnections = useAppSelector(
    (state) => state.connections.connectionsStore.telegram_bot as TelegramBotConnection[]);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')),
    token: yup.string(),
  });

  const methods = useForm<SubmitSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name || '',
      token: '',
    },
  });

  useEffect(() => {
    if (connectionId) {
      const itemConnection = telegramBotsConnections.find((item) => item.id === Number(connectionId));
      if (itemConnection) {
        setConnection(itemConnection  as TelegramBotConnection);
        methods.setValue('name', itemConnection.name);
      }
    }
  }, [telegramBotsConnections]);

  const onSubmit = (data: SubmitSchema) => {
    if (currentCompanyId?.params.companyId) {
      if (connection) {
        dispatch(updateConnection({
          itemId: connection.id,
          name: data.name,
          ...(data?.token.length > 0 ? { params: { bot_token: data.token } } : {}),
          type: 'telegram_bot',
          companyId: Number(currentCompanyId?.params.companyId),
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            push(`${currentCompanyId.url}/settings/connections/telegram_bot`);
          }
        });
        return;
      }

      if (data.token.length > 1 && !connection) {
        dispatch(createConnection({
          name: data.name,
          params: {
            bot_token: data.token,
          },
          type: 'telegram_bot',
          companyId: Number(currentCompanyId?.params.companyId),
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            if (currentCompany.hadConnections === false) {
              YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
              dispatch(setHadConnections(true));
            }
            dispatch(addAlertWithCustomText({
              message: t('connections.telegram_bot.add_view.botSuccessConnected'),
              type: 'info',
            }));
            push(`${currentCompanyId.url}/settings/connections/telegram_bot`);
            if (service === 'telegram_bot') update();
          }
        });
      } else {
        methods.setError('token', {
          type: 'required',
          message: t('error.form.empty_field'),
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form className='telegramBotForm' onSubmit={methods.handleSubmit(onSubmit)}>
        <GoBackWrapper title={connection && connection.name
          ? t('edit')
          : t('connections.telegram_bot.add_view.AddTelegramBot')}>
          {connection && (<>
            <p className='regularText'>{t('connections.connection_number')}: #{connection.id}</p>
            <p className='regularText'>@{connection.params.botUsername}</p>
          </>)}
          <Input type='text' label={t('connections.connection_name')} name='name' />
          <Input type='text' name='token' label={t('connections.telegram_bot.add_view.TelegramBotToken')}
                 inputType="password" />
          <Button type='submit' color='orange' textType='regular' text={connection ? t('save_edit') : t('connect')}
                  disabled={!methods.formState.isDirty} />
          {!connection && <Instruction />}
        </GoBackWrapper>
      </form>
    </FormProvider>
  );
};

export default TelegramBotForm;
