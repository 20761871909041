import './OnboardingButton.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchOnboarding, setOnboardingSwitchModal } from '../../../api/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentCompanySelector, onboardingSelector, onboardingSwitchModalSelector
} from '../../../api/companiesSelector';
import { currentOnboardingStep } from '../utils/getStep';
import { getIsOnbourdingModal } from '../utils/onboarding';
import ModalWrapper from '../../../components/Modals/ModalWrapper';
import OnboardingWizard from '../OnboardingWizard';
import RemindTestPayment from '../Steps/RemindTestPayment/RemindTestPayment';
import { ReactComponent as MagicIcon } from '../../../assets/magic.svg';


type OnboardingButtonProps = {
  isRolledUp?: boolean;
};

const OnboardingButton = ({ isRolledUp=false }: OnboardingButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(currentCompanySelector);
  const onboarding = useSelector(onboardingSelector);
  const isSwitchModal = useSelector(onboardingSwitchModalSelector);

  useEffect(() => {
    dispatch(fetchOnboarding(companyId));
  }, [companyId]);

  useEffect(() => {
    const isOnbourdingModal = getIsOnbourdingModal('isOnbourdingModal');
    dispatch(setOnboardingSwitchModal(isOnbourdingModal));
  }, []);

  const onSwitchModal = () => dispatch(setOnboardingSwitchModal(!isSwitchModal));

  if(!onboarding || onboarding?.isCancelled || onboarding?.isFinished) return <></>;

  const currentStep = currentOnboardingStep(onboarding);
  const remindTestPayment = onboarding.steps.find((step) => step.type === 'remind_test_payment');

  return (<>
    {isSwitchModal && currentStep &&
      <ModalWrapper background='light'>
        {(remindTestPayment && !remindTestPayment?.isFinished)
          ? <RemindTestPayment />
          : <OnboardingWizard />
        }
      </ModalWrapper>
    }
    <div className={`onboardingButton ${!onboarding.isFinished && remindTestPayment !== undefined ? 'red' : ''}`}
      onClick={onSwitchModal}>
      {isRolledUp
        ? <MagicIcon />
        : <p className="smallText middle">{t('company_onboarding.title')}</p>
      }
    </div>
  </>);
};

export default OnboardingButton;
