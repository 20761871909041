import { useSelector } from 'react-redux';
import { onboardingGuideSelector, onboardingSelector } from '../../../../../../api/companiesSelector';
import { ConnectionServiceTypes, settingConnections } from '../../../utils/connections';
import ConnectionItem from '../ConnectionItem';
import './ConnectionItems.scss';


type ConnectionItemsProps = {
  serviceTypes: ConnectionServiceTypes[]
};

function ConnectionItems({ serviceTypes }: ConnectionItemsProps) {
  const onboarding = useSelector(onboardingSelector);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);
  const guideConnection = onboardingGuideOn === 'add_connection'
    ? onboarding?.steps.find((step) => step.type === 'add_connection')?.result?.value : null;

  const filterConnections = (services: ConnectionServiceTypes[]) => {
    const filterArray = Object.entries(settingConnections)
      .filter(([_, value]) => services.includes(value.type));
    return filterArray;
  };

  return (
    <div className='connectionItems'>
      {filterConnections(serviceTypes).map(([service, conn]) =>
        <ConnectionItem key={conn.url} conn={conn} guideOn={guideConnection === service} />
      )}
    </div>
  );
}

export default ConnectionItems;
