import { useEffect, useState } from 'react';
import './Tags.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { currentCompanySelector } from '../../../api/companiesSelector';
import { tagsSelector } from './TagsSelector';
import { getTagsList } from './TagsSlice';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg'
import CreateAndEditTag from './CreateAndEditTag';
import Button from '../../../components/Button';
import TagsPlug from "./TagsPlug";
import TagsList from "./TagsList";
import Loader from '../../../components/Loader';


const Tags = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const tags = useSelector(tagsSelector);
  const permissionCreate = useHasPermission('tagsCreate');
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(getTagsList({ companyId: currentCompany.id, limit: 100 }));
    }
  }, [currentCompany]);

  if (tags === null) {
    return (<div className='tags'><Loader /></div>);
  }

  return (
    <div className="tags">
      {isOpen && <CreateAndEditTag setIsDisable={setIsOpen} isEdit={isEdit} setIsEdit={setIsEdit} />}
      {tags.length > 0 ? (
        <>
          <div className="tags__header">
            <h1>{t('settings.tags.title')}</h1>
            {permissionCreate && (
              <Button
                text={t('settings.tags.create_button')}
                color="orange"
                image={<PlusIcon />}
                onClick={() => setIsOpen(true)}
                textType="regular"
              />
            )}
          </div>
          <TagsList setIsEdit={setIsEdit} setIsDisable={setIsOpen} />
        </>
      ) : (
        <TagsPlug setIsDisable={setIsOpen} />
      )}
    </div>
  );
};

export default Tags;
