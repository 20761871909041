import './PlanItem.scss';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { SubscriptionInvoiceSchema } from '../../../schema/schema';
import { CurrenciesType } from '../../../../CompanySettings/currency';
import {
  checkInvoiceItemSubscription, getTariffsPlan, SubscriptionInvoiceItemType, subscriptionsSlotDiscount
} from '../helper';
import { SubscriptionPlanType } from '../../../SubscriptionsTypes';
import { SubscriptionStatusEnum } from '../../../types';
import SubscriptionSlot from '../../../../../shared/subscriptions/SubscriptionSlot';
import SubscriptionPlan from '../../../../../shared/subscriptions/SubscriptionPlan';


type PlanItemProps = {
  id: number;
  currency: CurrenciesType | undefined;
  item: SubscriptionInvoiceItemType | undefined;
} & UseFormReturn<SubscriptionInvoiceSchema> & UseFieldArrayReturn<SubscriptionInvoiceSchema>;

function PlanItem({ id, item, currency, ...methods }: PlanItemProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [opernSlot, setOpernSlot] = useState<boolean>(false);
  const [openPlan, setOpenPlan] = useState<boolean>(false);
  const ref = useRef<HTMLTableRowElement>(null);
  const tariffsPlan = item && checkInvoiceItemSubscription(item) && item.subscriptionPlan &&
    getTariffsPlan(item?.type, item.subscriptionPlan, item?.status === SubscriptionStatusEnum.paid, currency);

  const mininumSlots = () => {
    if (item) {
      if (item.status === SubscriptionStatusEnum.paid) return item.maxSize ?? 1;
      return item.currentSize && item.currentSize > 0 ? item.currentSize : 1;
    }
    return 1;
  };

  const plan = methods.watch(`subscriptions.${id}.plan`);
  const count = methods.watch(`subscriptions.${id}.count`);

  const onOpenPlan = () => setOpenPlan(!openPlan);
  const onOpenSlot = () => setOpernSlot(!opernSlot);

  const setSlotsNumber = (slots: number) => {
    methods.setValue(`subscriptions.${id}.count`, slots, { shouldDirty: true });
  };

  const setSubscriptionPlan = (newPlan: string) => {
    if (newPlan === 'START' && count > 1) {
      dispatch(addAlertWithCustomText({ message: t('subscriptions.invoice.slot_error'), type: 'alarm' }));
    } else {
      methods.setValue(`subscriptions.${id}.plan`, newPlan as SubscriptionPlanType, { shouldDirty: true });
      setOpenPlan(false);
    }
  };

  return (
    <tr className='subscriptionsItem' ref={ref}>
      <td data-title={t('subscriptions.invoice.sub_list.name')}>
        <p id='name' className='regularText'>
          {t(`subscriptions.invoice.title_item.${item?.itemType}`)} {t(`subscriptions.types.${item?.type}`)}
        </p>
        <p id='numbers' className='smallText semibold'>
          {`${t(`subscriptions.invoice.sub_list.${item?.serviceType}`)}${count.toString()}`}&nbsp;
          {!(['FREE', 'START']).includes(plan) &&
            <button className='planItem_button' type='button' onClick={onOpenSlot}>
              <span className='smallText'>{t('add')}</span>
            </button>}
        </p>
        {opernSlot &&
          <div style={{ width: ref.current?.clientWidth }}>
            <SubscriptionSlot currentSlot={count} mininumSlots={mininumSlots()} maximumSlots={9999}
              discounts={subscriptionsSlotDiscount} setOpen={onOpenSlot} setSlot={setSlotsNumber} />
          </div>
        }
        <p id='tariff' className='smallText semibold'>
          {`${t('subscriptions.plans.tariff')}: ${t(`subscriptions.plans.${plan.toString()}`)}`}&nbsp;
          {tariffsPlan && <button className='planItem_button' type='button' onClick={onOpenPlan}>
            <span className='smallText'>{t('switch')}</span>
          </button>}
        </p>
        {openPlan && tariffsPlan &&
          <div style={{ width: ref.current?.clientWidth }}>
            <SubscriptionPlan tariffs={tariffsPlan} setOpen={onOpenPlan} current={plan}
              setTariff={setSubscriptionPlan} />
          </div>}
      </td>
      <td data-title={t('subscriptions.invoice.sub_list.price')} className='leftStyle'>{item?.amount}</td>
      <td data-title={t('subscriptions.invoice.sub_list.discount')} className='leftStyle'>{item?.discount}</td>
      <td data-title={t('subscriptions.invoice.sub_list.amount')} className='amountStyle'>{item?.total}</td>
    </tr>
  );
}

export default PlanItem;
