import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './RolesList.scss';
import { getNameRole } from '../../utlity';
import { WrapperPermission } from '../../../../../../utils/WrapperPermission/WrapperPermission';
import { getRandomInt } from '../../../../../../utils/utils';
import { RoleType } from '../../../../../../api/types';
import Button from '../../../../../../components/Button/Button';
import ToolTip from '../../../../../../components/Modals/ToolTip';
import RoleCreate from '../RoleCreate';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus.svg';
import { ReactComponent as CheckMark } from '../../../../../../assets/checkmark.svg';


type RolesListProps = {
  isOwner: boolean;
  currentAddRole: string;
  currentRole: RoleType | null;
  currentRoles: RoleType[];
  setCurrentRole: React.Dispatch<React.SetStateAction<RoleType | null>>;
  setCurrentRoles: React.Dispatch<React.SetStateAction<RoleType[]>>;
  setCurrentAddRole: React.Dispatch<React.SetStateAction<string>>;
};

const RolesList = ({
  currentRole, setCurrentRole, setCurrentRoles, setCurrentAddRole, currentRoles, currentAddRole,
  isOwner }: RolesListProps) => {
  const { t } = useTranslation();
  const refCreate = useRef<HTMLDivElement>(null);
  const [isAddRole, setIsAddRole] = useState(false);
  const isAddedRole = currentAddRole.length === 0;

  const handlerSwitchRole = (role: RoleType) => {
    setCurrentRole(role);
  };

  const handlerCloseAddRoleModal = () => {
    setCurrentAddRole('');
    setIsAddRole(false);
  };

  const submitAddRoleModal = (roleName: string) => {
    if (roleName.length > 0) {
      const mockId = getRandomInt(100000);
      const newRole: RoleType = {
        id: mockId,
        name: roleName,
        permissions: [],
        systemType: null,
        mockId,
      };
      setCurrentRoles([...currentRoles, newRole]);
      setCurrentRole(newRole);
      setCurrentAddRole(roleName);
      setIsAddRole(false);
    }
  };

  return (
    <div className='rolesList'>
      <h4>{t('settings.users.user.employee_role')}</h4>
      <div className='rolesList__list'>
        {currentRoles?.map(role =>
          <div onClick={() => handlerSwitchRole(role)} key={role.id}
            className={`rolesList__list__item ${currentRole?.id === role.id ? 'selectedRole' : ''} ${role.name.length > 50 ? 'longWord' : ''}`}>
            {currentRole?.id === role.id ? <CheckMark className='checkmask' /> : <PlusIcon className='plus' />}
            {getNameRole(role.name, isOwner, t)}
          </div>
        )}
        {isAddedRole &&
          <WrapperPermission permission='membersAdd'>
            <div className='rolesList__list_add' ref={refCreate}>
              <Button type='button' color='orange' textType='small'
                text={t('settings.users.user.create_role')} image={<PlusIcon />} onClick={() => setIsAddRole(true)} />
            </div>
          </WrapperPermission>
        }
        {isAddRole && 
          <ToolTip refElement={refCreate} modal={true} onClose={handlerCloseAddRoleModal}>
            <RoleCreate roles={currentRoles} onCreate={submitAddRoleModal} onClose={handlerCloseAddRoleModal} />
          </ToolTip>
        }
      </div>
    </div>
  );
};

export default RolesList;
