import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentCompanySelector } from "../../../api/companiesSelector";
import { UpsellRecommendation } from "./SubscriptionsTypes";
import { getUpsellRecommendations } from "./SubscriptionsAPI";


export function useDialogRecommendation() {
  const currentCompany = useSelector(currentCompanySelector);
  const [recommends, setRecommends] = useState<UpsellRecommendation | undefined>();

  useLayoutEffect(() => {
    getUpsellRecommendations(currentCompany.id)
      .then((res) => {
        const recommend = res.find(item => item.type === 'WABA_DIALOGS');
        if (recommend) setRecommends(recommend);
      });
  }, [currentCompany]);

  return recommends;
}
