import './AddUser.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import { useInviteLink } from '../api';
import Button from '../../../../components/Button/Button';
import { ReactComponent as CopyIcon } from '../../../../assets/copy.svg';
import { ReactComponent as ReloadIcon } from '../../../../assets/reload.svg';


const AddUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { inviteLink, fetchLink } = useInviteLink();

  const refreshButton = async () => await fetchLink();

  const onCopy = () => {
    if (inviteLink?.url) navigator.clipboard.writeText(inviteLink?.url);
    dispatch(addAlertWithCustomText({ message: t('settings.users.copy_link') }));
  };

  return (
    <div className='addUser'>
      <h1>{t('settings.users.title')}</h1>
      <p className='regularText'>{t('settings.users.text_info')}</p>
      <div className='addUser__content'>
        <p className='regularText semibold'>{t('settings.users.unique_link')}</p>
        <div className='addUser__content_iventeLink'>
          <input type='text' defaultValue={inviteLink?.url} id='linkInput' readOnly />
          <button type='button' onClick={refreshButton} >
            <ReloadIcon />
            {t('settings.users.refresh_link')}
          </button>
        </div>
      </div>
      <Button type='button' color='orange' textType='regular' text={t('copy')} image={<CopyIcon />} onClick={onCopy} /> 
    </div>
  );
};

export default AddUser;
