import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

export const companiesSelector = (state: RootState) => state.companies.companies;
export const profileSettingsInfo = (state: RootState) => state.companies.profileSettings;
export const currentCompanySelector = (state: RootState) => state.companies.currentCompany;
export const membersSelector = (state: RootState) => state.companies.members.members;
export const userInfoSelector = (state: RootState) => state.companies.userInfo;
export const invitationCompanyNameSelector = (state: RootState) => state.companies.invitationCompanyName;
export const isLoadingAddNewCompanySelector = (state: RootState) => state.companies.isLoadingAddNewCompany;
export const getRolesCompanySelector = (state: RootState) => state.companies.rolesCompany;
export const allConnectionsForChatSelector = (state: RootState) => state.companies.allConnectionsForChat;
export const isViewNavbarMobileSelector = (state: RootState) => state.companies.isViewNavbarMobile;
export const isOpenNavbarMobileSelector = (state: RootState) => state.companies.isOpenNavbarMobile;
export const onboardingSelector = (state: RootState) => state.companies.onboarding;
export const onboardingGuideSelector = (state: RootState) => state.companies.onboardingGuideOn;
export const onboardingSwitchModalSelector = (state: RootState) => state.companies.onboardingSwitchModal;
export const availableQuestioningSelector = (state: RootState) => state.companies.availableQuestioning;

export const getCurrentCompanyRoleSelector = createSelector(
  [profileSettingsInfo, membersSelector, getRolesCompanySelector], (profile, members, roles) => {
  const userId = profile.userId;
  const userMember = members?.find((member) => member.userId === userId);
  if (userMember && roles) return roles?.find((role) => role.id === userMember.roleId);
});
