import { useDispatch, useSelector } from "react-redux";
import { WabaConnection } from "../../../../api/connections/types";
import { addAlertWithCustomText, setAlertWithClosing } from "../../../../components/Alert/alertSlice";
import { currentCompanySelector } from "../../../../api/companiesSelector";
import { linkFacebook } from "../ConnectionsAPI";
import { addWabaLinkError, getWabaCatalogStatus } from "../connectionsSlice";
import { AuthData } from "../ConnectionsViews/Waba/utils";


export function useLinkCatalog(
  wabaConnection: WabaConnection, setShow: React.Dispatch<React.SetStateAction<boolean>>) {
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);

  const linkFB = async (response: fb.StatusResponse, appConfig: AuthData) => {
    if (response.status === 'connected' && response.authResponse !== null && response.authResponse.accessToken) {
      try {
        await linkFacebook({
          company_id: company.id,
          access_token: response.authResponse.accessToken,
          waba_connection_id: wabaConnection.id,
          app_id: appConfig.id,
          app_secret: appConfig.secret
        });
        dispatch(addAlertWithCustomText({ message: 'connections.waba.catalogs.connected', type: 'info' }));
        dispatch(getWabaCatalogStatus({ company_id: company.id, waba_connection_id: wabaConnection.id }));
        setShow(false);
      } catch(e) {
        if (e.response.data.error_code === 25002) {
          // 25002 catalog not found (not choosen)
          dispatch(addWabaLinkError(wabaConnection));
          dispatch(setAlertWithClosing({
            type: 'alarm',
            payload: { title: 'connections.waba.catalogs.no_catalog.title', text: '' },
            inner: 'connections_waba_catalogs' }));
        } else if (e.response.data.error_code === 25003) {
          // 25003 account not linked to number
          dispatch(setAlertWithClosing({
            payload: {
              title: 'connections.waba.catalogs.no_phone.title',
              text: 'connections.waba.catalogs.no_phone.message'
            },
            type: "alarm"
          }));
        } else if (e.response.data.error_code === 25000) {
          // 25000 unable to bind (no access)
          dispatch(setAlertWithClosing({
            payload: {
              title: 'connections.waba.catalogs.no_access.title',
              text: 'connections.waba.catalogs.no_access.message'
            },
            type: "alarm"
          }));
        } else if (e.response.data.error_code === 25004) {
          // 25004 unhandled exeption
          dispatch(setAlertWithClosing({
            type: "alarm",
            payload: { title: 'error.unknown', text: '' },
            inner: 'error_call_support'
          }));
        } else if (e.response.data.error_code === 25001) {
          // 25001 token expired
          dispatch(setAlertWithClosing({
            payload: {
              title: 'connections.waba.catalogs.no_token.title',
              text: 'connections.waba.catalogs.no_token.message'
            },
            type: "alarm",
          }));
        } else {
          // other errors
          dispatch(addAlertWithCustomText({ code: e.response.data.error_code }));
        }
      }
  }};

  return { linkFB };
}
