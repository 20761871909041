import { FC, useEffect } from 'react';
import './Registration.scss';
import { Route, Switch } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { companiesSelector, isLoadingAddNewCompanySelector, profileSettingsInfo } from '../../api/companiesSelector';
import { ReactComponent as LogoutIcon } from '../../assets/logout-icon.svg';
import radistLogo from '../../assets/radist-logo.svg';
import radistSaly from '../../assets/Saly-1.png';
import Invitation from './Invitation';
import RegistrationContent from './RegistrationContent';
import InvitationCancel from './InvitationCancel';
import Loader from '../../components/Loader';


type Props = {
  invitationCompanyName: string | undefined;
};

const Registration:FC<Props> = ({ invitationCompanyName }) => {
  const { url } = useRouteMatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { keycloak } = useKeycloak();

  const isLoadingAddNewCompany = useSelector(isLoadingAddNewCompanySelector);
  const companies = useSelector(companiesSelector);
  const infoProfile = useSelector(profileSettingsInfo);

  useEffect(() => {
    if (infoProfile && infoProfile.language){
      i18n.changeLanguage(infoProfile.language);
    }
  }, [infoProfile]);

  const getTitle = (pathname: string, url: string) => {
    if (pathname === `${url}/cancel`) {
      return 'registration.invite.cancel';
    }
    if (invitationCompanyName) {
      return 'registration.reg';
    }
    return 'registration.title';
  };

  if (isLoadingAddNewCompany) {
    return (<Loader />);
  }

  return (
    <div className="registrationAllContainer">
      <div className="registrationImgContainer">
        <img className="registrationImgContainer_logo" src={radistLogo} alt="" />
        <img className="registrationImgContainer_saly" src={radistSaly} alt="" />
      </div>
      <div className="registrationRightContainer">
        <div className="registrationRightContainer_center">
          <h1>{t(`${getTitle(location.pathname, url)}`)}</h1>
          <Switch>
            <Route path='/registration' exact>
              <RegistrationContent />
            </Route>
            <Route path={`${url}/invitation`}>
              <Invitation invitationCompanyName={invitationCompanyName} companies={companies} />
            </Route>
            <Route path={`${url}/cancel`} exact>
              <InvitationCancel />
            </Route>
          </Switch>
        </div>
        <div className='registrationRightContainer_logout'>
          {location.pathname === '/registration' &&
            <div
              className='registrationRightContainer_logout_item'
              onClick={() => keycloak.logout()}
            >
              <div className='registrationRightContainer_logout_item_icon'>
                <LogoutIcon />
              </div>
              <p>{t('navbar.exit')}</p>
            </div>
          }
          </div>
      </div>
    </div>
  );
};

export default Registration;
