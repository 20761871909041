import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store/store';
import { currentCompanySelector } from '../../../../../api/companiesSelector';
import { updateSubscriptions } from '../../SubscriptionsSlice';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { ConnectionsType } from '../../../Connections/ConnectionsTypes';
import { fetchConnectionsList } from '../../../Connections/connectionsSlice';
import UserSubscription from '../../models/UserSubscription';
import ListIcon from '../ListIcon';
import { PaidWarn } from '../PaidWarn/PaidWarn';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';
import { ReactComponent as ArrowBack } from '../../../../../assets/arrow-back.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-left.svg';
import { ReactComponent as SmallLoader } from '../../../../../assets/small-loader.svg';
import loader from '../../../../../assets/grid.svg';
import './EditForm.scss';


const SUBSCRIPTION_NOT_ENOUGH_DAY = 22002;
const CONNECTIONS_LIMIT = 100;

const isDifference = (a: number[], b: number[]): boolean => {
  if (a.length !== b.length) {
    return true;
  }
  for (const i of a) {
    if (!b.includes(i)) {
      return true;
    }
  }
  return false;
};

type Props = {
  editSubscription: UserSubscription;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type ConnectionItemType = {
  connId: number;
  title: string;
  index?: number;
  type?: string;
};

type SubscriptionEditType = {
  connectionsIn: ConnectionItemType[];
  connectionsOut: ConnectionItemType[];
};

const EditForm: React.FC<Props> = ({ editSubscription, setIsOpen }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const allConnections =
    useAppSelector((state) => state.connections.connectionsStore[editSubscription.type as ConnectionsType]);
  const [paidWarn, setPaidWarn] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [connIndexOut, setConnIndexOut] = useState<number>();  // save index for return it back if paid warn occurs!
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchConnectionsList({
      companyId: currentCompany.id,
      limit: CONNECTIONS_LIMIT,
      types: editSubscription.type as ConnectionsType
    }));
  }, []);

  const cancelHandler = () => {
    setIsOpen(false);
  };

  const formatSubscriptionStatus = (subscription: UserSubscription) => {
    const statusDescription = t(`subscriptions.card.status.${subscription.statusName}`);
    return `${statusDescription} ${subscription.formatSubscriptionDateStatus(i18n.language)}`;
  };
  const [status, setStatus] = useState<string>(formatSubscriptionStatus(editSubscription));

  const getAllConnections = (): ConnectionItemType[] =>
    allConnections.map(item => ({ connId: item.id, title: item.name, type: item.type }));

  const getConnectionsIn = () => {
    const connections = getAllConnections();
    return connections?.filter((conn) => editSubscription.connections.includes(conn.connId));
  };

  const getConnectionsOut = () => {
    const connections = getAllConnections();
    return connections?.filter((conn) => conn.type === editSubscription.type)
      .filter((conn) => !editSubscription.connections.includes(conn.connId));
  };

  const defaultValues: SubscriptionEditType = {
    connectionsIn: getConnectionsIn(),
    connectionsOut: getConnectionsOut()
  };

  const methods = useForm({ mode: 'onSubmit', defaultValues });
  const { fields: connectionsIn, append: appendConsIn, remove: removeConsIn } =
    useFieldArray({ name: 'connectionsIn', control: methods.control });
  const {
    fields: connectionsOut, append: appendConsOut, remove: removeConsOut,
    insert: insertConsOut, prepend: prependConsOut
  } = useFieldArray({ name: 'connectionsOut', control: methods.control });

  useEffect(() => {
    methods.reset({
      connectionsIn: getConnectionsIn(),
      connectionsOut: getConnectionsOut(),
    });
  }, [allConnections]);

  const addConnection = (index: number) => {
    if (editSubscription.maxSize > connectionsIn.length) {
      const connOut = methods.getValues(`connectionsOut.${index}`);
      removeConsOut(index);
      setConnIndexOut(index);
      appendConsIn({ ...connOut, index });
    } else dispatch(addAlertWithCustomText({ message: t('errors.22009'), type: 'alarm' }));
  };

  const delConnection = (index: number, indexOut?: number) => {
    const connIn = methods.getValues(`connectionsIn.${index}`);
    const conn = { connId: connIn.connId, title: connIn.title };
    removeConsIn(index);
    if (indexOut) {
      insertConsOut(indexOut, conn);
    } else if (connIn.index !== undefined) {
      if (connIn.index === 0) {
        prependConsOut(conn);
      } else {
        insertConsOut(connIn.index, conn);
      }
    } else {
      appendConsOut(conn);
    }
  };

  const retConnection = () => {
    // return last added connection back to list
    delConnection(connectionsIn.length - 1, connIndexOut);
    setConnIndexOut(undefined);
  };

  const paidWarnConfirmHandle = () => {
    if (connIndexOut !== undefined) {
      // if paid warn, so couldn't add new connection and move it back to connectionsOut
      retConnection();
    }
    setPaidWarn(false);
  };

  useEffect(() => {
    calcSubscription(true);
    setIsChanged(isDifference(
      defaultValues.connectionsIn.map((conn) => Number(conn.connId)),
      connectionsIn.map((conn) => Number(conn.connId))
    ));
  }, [connectionsIn]);

  const calcSubscription = async (preview: boolean) => {
    let responseStatus = false;
    const connections = connectionsIn.map((conn) => Number(conn.connId));
    setIsPending(true);
    await dispatch(updateSubscriptions({
      subscriptionId: editSubscription.id,
      companyId: currentCompany.id,
      data: { connections, preview }
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (typeof res.payload === 'object') {
          const subscription = new UserSubscription(res.payload);
          setStatus(formatSubscriptionStatus(subscription));
          responseStatus = true;
        }
      } else if (connIndexOut !== undefined) {
        // on error move connection back to connectionsOut list
        if (res.payload === SUBSCRIPTION_NOT_ENOUGH_DAY) {
          // if error SUBSCRIPTION_NOT_ENOUGH_DAY: not enough days to recalculate, then show paid warn before move back
          setPaidWarn(true);
        } else {
          retConnection();
        }
      }
      setIsPending(false);
    });
    return responseStatus;
  };

  const saveSubscription = async (data: SubscriptionEditType) => {
    const responseStatus = await calcSubscription(false);
    if (responseStatus) {
      methods.reset(data);
      setIsChanged(false);
      dispatch(addAlertWithCustomText({ message: t('subscriptions.card.form.save_message') }));
      setIsOpen(false);
    }
  };

  const submitHandler = async (data: SubscriptionEditType) => {
    await saveSubscription(data);
  };

  const termComponent = () => {
    return (
      <div className="editForm__date">
        <p className="regularText">
          {isPending ? `${t(`subscriptions.card.status.${editSubscription.statusName}`)}` : status}
        </p>
        {isPending && <SmallLoader />}
      </div>
    );
  };

  return (
    <>
      {paidWarn && <PaidWarn handleConfirm={paidWarnConfirmHandle} />}
      {isPending && <div className="load"><img src={loader} alt="loading..." /></div>}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitHandler)}>
          <div className="editForm__details regularText">
            {editSubscription.hadTariff ? (
              <>
                <p className='editForm__plan'>
                  {t('subscriptions.card.plan')}: {t(`subscriptions.plans.${editSubscription.plan}`)}
                </p>
                <p className='editForm__paid'>{t('subscriptions.card.paid_numbers')}: {editSubscription.maxSize}</p>
                {termComponent()}
              </>
            ) : (
              <p className="regularText">
                {`${t(`subscriptions.card.connections.${editSubscription.serviceType}`)}${
                  editSubscription.currentSize
                }`}
              </p>
            )}
          </div>
          <div className="editForm">
            <ListIcon
              fields={connectionsOut}
              label={t('subscriptions.card.form.list_out')}
              labelKey="title"
              image={<ArrowIcon />}
              callback={addConnection}
            />
            <ListIcon
              fields={connectionsIn}
              label={t('subscriptions.card.form.list_in')}
              labelKey="title"
              image={<ArrowIcon />}
              position="left"
              callback={delConnection}
            />
          </div>
          <div className="editForm__buttonsWrapper">
            <button type="submit" className="editForm__buttonsWrapper_edit" disabled={!isChanged}>
              <EditIcon />
              <span className="smallText">{t('save')}</span>
            </button>
            <button className="editForm__buttonsWrapper_cancel" onClick={cancelHandler}>
              <ArrowBack />
              <span className="smallText">{t('cancel')}</span>
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default EditForm;
