import { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../../../store/store";
import { currentCompanySelector } from "../../../../api/companiesSelector";
import { createIntegrationAPI, deleteIntegrationAPI, integrationsUpdate } from "../integrationSlice";
import { IntegrationAPISchema } from "../IntegrationTypes";
import { APIUpdate } from "../types";
import { integrationContext } from "../utils";


export function useApiIntegrationUpdate() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const company = useSelector(currentCompanySelector);
  const { setIntegration } = useContext(integrationContext);

  const integrationUpdate = async (data: APIUpdate) => {
    let response: boolean = false;

    if (company) {
      await dispatch(integrationsUpdate({
        companyId: company.id,
        integration_id: data.id,
        requestOption: {
          name: data.name,
          type: data.type,
          status: data.status ? 'enabled' : 'disabled',
          params: {
            scopes: data.scopes,
            resetApiKey: data.resetApiKey
          }
        }
      }))
        .then((result) => {
          response = result.meta.requestStatus === 'fulfilled';
          if (response && typeof result.payload === 'object') setIntegration(result.payload);
        });
    }

    return response;
  };

  const integrationDelete = async (integrationId: number) => {
    await dispatch(deleteIntegrationAPI({
      company_id: company.id,
      integration_id: integrationId
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') history.push(`/companies/${company.id}/settings/integrations`);
    });
  };

  const integrationCreate = async (data: IntegrationAPISchema) => {
    await dispatch(createIntegrationAPI({
      company_id: company.id,
      data,
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled' && typeof res.payload === 'object') {
        history.push({ pathname: `/companies/${company.id}/settings/integrations/${res.payload.id}`, state: true });
      }
    });
  }

  return { integrationUpdate, integrationDelete, integrationCreate };
}
