import React from 'react';
import './TagsList.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { tagsSelector } from '../TagsSelector';
import { getTagsList } from '../TagsSlice';
import { currentCompanySelector } from '../../../../api/companiesSelector';
import TagItem from '../TagItem';
import InfiniteScroll from 'react-infinite-scroll-component';


type Props = {
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
};

const TagsList = ({ setIsEdit, setIsDisable }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const tags = useSelector(tagsSelector);

  const onUpload = () => {
    dispatch(getTagsList({ companyId: currentCompany.id, limit: 25, offset: tags.length }));
  };

  if (!tags) return <></>;

  return (
    <div className="tagsList">
      <div className="tagsList__header">
        <p className="smallText semibold">{t('settings.tags.name')}</p>
        <p className="smallText semibold">{t('settings.tags.description')}</p>
      </div>
      <InfiniteScroll next={onUpload} hasMore={true} loader={null}
        scrollableTarget="scrollList" dataLength={tags.length}>
        <div className="tagsList__content" id="scrollList">
          {tags.map((tag) => (
            <TagItem key={tag.id} tag={tag} setIsEdit={setIsEdit} setIsDisable={setIsDisable} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default TagsList;
