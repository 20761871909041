
export type BdAnchors = Record<string, string>;

export const bdAnchors: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web',
  es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web',
  en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web',
};

export const bdAnchorsSupport: BdAnchors = {
  ru: 'https://radist.online/support',
  es: 'https://radist.online/esp/support',
  en: 'https://radist.online/en/support',
};

export const bdAnchorsAPIDocument: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/api/v2',
  es: 'https://api.radist.online/v2/docs',
  en: 'https://api.radist.online/v2/docs',
};

export const bdAnchorsTariffs: BdAnchors = {
  RUB: 'https://docs.radist.online/radist.online-docs/nashi-uslugi/tarify',
  USD: 'https://docs.radist.online/docs/v/english-2/our-services/tariffs',
  // es: 'https://docs.radist.online/docs/nuestros-servicios/tarifas'
};

export const bdAnchorsWaba: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/whatsapp-business-api/podklyuchenie-k-waba',
  en: 'https://docs.radist.online/docs/v/english-2/our-products/whatsapp-business-api/connecting-a-number-to-the-waba',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/whatsapp-business-api/conectar-un-numero-a-waba'
};

export const bdAnchorsOnboarding: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web',
  en: 'https://docs.radist.online/docs',
  es: 'https://docs.radist.online/docs/v/espanol-1'
};

export const bdAnchorsConnections: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/podklyucheniya',
  en: 'https://docs.radist.online/docs/our-products/radist-web/connections',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/radist-web/conexiones'
};

export const bdAnchorsIntegrations: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/integracii',
  en: 'https://docs.radist.online/docs/our-products/radist-web/integrations',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/radist-web/integracion'
};

export const bdAnchorsAnalytics: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/amocrm/skvoznaya-analitika/nastroika-skvoznoi-analitiki',
  en: 'https://docs.radist.online/docs/our-products/end-to-end-analytics',
  es: 'https://docs.radist.online/docs/espanol-1/productos-1/analisis-de-extremo-a-extremo'
};

export const bdAnchorsAnalyticsTariffs: BdAnchors = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/analitika',
  en: 'https://docs.radist.online/docs/our-products/radist-web/analytics',
  es: 'https://docs.radist.online/docs/espanol-1/productos-1/radist-web/analista'
};
