import { useContext, useEffect, useState } from 'react';
import './APIIntegration.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../store/store';
import { currentCompanySelector } from '../../../../../api/companiesSelector';
import { bdAnchorsAPIDocument } from '../../../../../pages/Home/utils/consts';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { APISchema, listAPIs } from '../../types';
import { integrationContext } from '../../utils';
import { useApiIntegrationUpdate } from '../../api';
import { PermissionsType } from '../../../../../utils/WrapperPermission/permission-maps';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';
import { ReactComponent as FilesIcon } from '../../../../../assets/files.svg';
import ActionDialog from '../../../../../components/Modals/ActionDialog';
import Button from '../../../../../components/Button/Button';
import CheckControl from '../../../../../components/CheckControl';
import EditName from '../EditName';
import PopupAPIKey from "./PopupAPIKey/PopupAPIKey";
import SwitchControl from '../../../../../components/SwitchControl';
import ModalWrapper from '../../../../../components/Modals/ModalWrapper';
import Loader from '../../../../../components/Loader/Loader';
import SwitchRequest from '../SwitchRequest';


function APIIntegration() {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const currentCompany = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const { isPartner } = useSelector(currentCompanySelector);
  const location = useLocation<boolean>();
  const { state } = location;
  const { integration } = useContext(integrationContext);
  const { integrationUpdate, integrationDelete, integrationCreate } = useApiIntegrationUpdate();
  const [loaderReq, setReqLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [updateConfirm, setUpdateConfirm] = useState<boolean>(false);
  const [isOpenPopupApiKey, setIsOpenPopupApiKey] = useState(false);
  const [isFirstCreateAPI, setIsFirstCreateAPI] = useState(false);
  const accessPermission: PermissionsType = integration ? 'integrationsEdit' : 'integrationsCreate'
  const [switchRequest, setSwitchRequest] = useState<boolean>(false);

  const defaultValues: APISchema = {
    status: integration?.status === 'enabled',
    scopes: integration?.params.scopes || [],
  };

  const methods = useForm<APISchema>({ mode: 'onSubmit', defaultValues });

  useEffect(() => {
    setIsFirstCreateAPI(state);
    if (state) updateAPIKey();
  }, [state]);

  useEffect(() => {
    if (!isOpenPopupApiKey && isFirstCreateAPI) setIsFirstCreateAPI(false);
  }, [isOpenPopupApiKey]);

  const saveData = async (data: APISchema) => {
    if (currentCompany?.params.companyId) {
      if (integration) {
        setReqLoader(true);
        const result = await integrationUpdate({
          id: integration.id, name: integration.name, type: integration.type, ...data
        });
        if (result) {
          methods.reset(data);
          setReqLoader(false);
        }
      } else {
        setReqLoader(true);
        await integrationCreate({
          name: t('settings.integrations.api.title'), type: 'api', params: { scopes: data.scopes }
        });
        setReqLoader(false);
      }
    }
  };

  const onSwitchOn = () => {
    setSwitchRequest(false);
    const data = methods.getValues();
    saveData({ ...data, status: !data.status });
  };

  const onSwitchOff = () => {
    setSwitchRequest(false);
    saveData(methods.getValues());
  };

  const onSubmit = async (data: APISchema) => {
    if (data.scopes.length <= 0) {
      return dispatch(addAlertWithCustomText({ message: t('settings.integrations.api.alertError'), type: 'alarm' }));
    }
    if (integration && !data.status && integration.status === 'disabled') {
      setSwitchRequest(true);
    } else {
      saveData(data);
    }
  };

  const updateName = async (name: string) => await integrationUpdate({
    name, id: integration.id, type: integration.type, ...defaultValues
  });

  const updateAPIKey = async () => {
    await integrationUpdate({
      name: integration.name, id: integration.id, type: integration.type, ...defaultValues, resetApiKey: true
    });
    setIsOpenPopupApiKey(true);
  };

  const onDeleteClick = () => setDeleteConfirm(true);
  
  const onUpdateClick = () => setUpdateConfirm(true);

  const onHandlerDelete = () => {
    setDeleteConfirm(false);
    integrationDelete(integration.id);
  };

  const onHandlerUpdate = () => {
    setUpdateConfirm(false);
    updateAPIKey();
    setIsOpenPopupApiKey(true);
  };

  if (loaderReq) {
    return (
      <div className='APIIntegration'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='APIIntegration'>
      {switchRequest && <SwitchRequest onAction={onSwitchOn} onClose={onSwitchOff} setIsOpen={setSwitchRequest} />}
      {deleteConfirm &&
        <ActionDialog onAction={onHandlerDelete} setIsOpen={setDeleteConfirm}
          background='light' action={t('delete')} cancel={t('cancel')}
          title={t('settings.integrations.api.delete_modal.title')} message={t('settings.integrations.api.delete_modal.text')} />
      }
      {updateConfirm &&
        <ActionDialog onAction={onHandlerUpdate} setIsOpen={setUpdateConfirm}
          background='light' action={t('update')} cancel={t('cancel')}
          title={t('settings.integrations.api.update_modal.title')} message={t('settings.integrations.api.update_modal.text')} />
      }
      {isOpenPopupApiKey &&
        <ModalWrapper setIsOpen={setIsOpenPopupApiKey} background='light'>
          <PopupAPIKey isFirstCreateAPI={isFirstCreateAPI} integration={integration} />
        </ModalWrapper>
      }
      {integration
        ? <>
          <EditName integration={integration} onUpdate={updateName} />
          <p className='regularText middle'>{t('settings.integrations.integration_id')}: {integration.id}</p>
        </>
        : <h1>{t('settings.integrations.api.title')}</h1>
      }
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='APIIntegration__title'>
            <h3>{integration
              ? t('settings.integrations.api.accessText')
              : t('settings.integrations.api.choiceText')
            }</h3>
            <a className='smallText middle'
              href={bdAnchorsAPIDocument[i18n.language]} target="_blank" rel="noreferrer">
              <FilesIcon />
              <p>{t('settings.integrations.api.document')}</p>
            </a>
          </div>
          <div className='APIIntegration__checkboxes'>
            {listAPIs.filter(item => isPartner ? item : item !== 'partners').map(item =>
              <WrapperPermission key={item} permission={accessPermission} disabledProps={{ disabled: true }}>
                <CheckControl label={t(`settings.integrations.api.choice.${item}`)} value={item} name='scopes' />
              </WrapperPermission>
            )}
          </div>
          <div className='APIIntegration__footer'>
            {integration && <WrapperPermission permission='integrationsEdit'>
              <Button color='orange' textType='small' text={t('settings.integrations.api.updateApi')}
                onClick={onUpdateClick} />
            </WrapperPermission>}
            {integration && <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
              <SwitchControl label={t('settings.integrations.status')} name='status' />
            </WrapperPermission>}
            <div className='APIIntegration__footer_buttons'>
              <Button type='submit' color='orange' textType='regular' disabled={!methods.formState.isDirty}
                text={integration
                  ? t('contacts.editContact.saveChanges')
                  : t('settings.integrations.api.connectApi')
                } />
              {integration && <WrapperPermission permission='integrationsDelete'>
                <Button text={t('settings.integrations.api.deleteIntegration')} color='white' textType='regular'
                  onClick={onDeleteClick} image={<DeleteIcon />} id='delete' />
              </WrapperPermission>}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default APIIntegration;
