import './ItemsList.scss';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { currencyFormat } from '../../../../CompanySettings/currency';
import { SubscriptionInvoice } from '../../../SubscriptionsTypes';
import { InvoiceItems, SubscriptionInvoiceItemType } from '../helper';
import { SubscriptionInvoiceSchema } from '../../../schema/schema';
import UserSubscription from '../../../models/UserSubscription';
import PlanItem from '../PlanItem';
import InvoiceItem from '../InvoiceItem';


type ItemsListProps = {
  invoice: SubscriptionInvoice | null;
  subscriptions?: UserSubscription[];
};

function ItemsList({ invoice, subscriptions }: ItemsListProps) {
  const { t, i18n } = useTranslation();
  const methods = useFormContext<SubscriptionInvoiceSchema>();
  const fieldsMethods = useFieldArray<SubscriptionInvoiceSchema>({ control: methods.control, name: 'subscriptions' });
  const invoiceItems: SubscriptionInvoiceItemType[]
    = InvoiceItems(invoice && invoice.summary.items, subscriptions, i18n.language);

  return (
    <div className='subscriptionsInvoicePay__subscriptions'>
      <h3>{t('selected')}</h3>
      <table className='subscriptionsInvoicePay__subscriptions_container'>
        <thead>
          <tr className='subscriptionsInvoicePay__subscriptions_container_title'>
            <th>{t('subscriptions.invoice.sub_list.name')}</th>
            <th>{t('subscriptions.invoice.sub_list.price')}</th>
            <th>{t('subscriptions.invoice.sub_list.discount')}</th>
            <th>{t('subscriptions.invoice.sub_list.amount')}</th>
          </tr>
        </thead>
        <tbody className='subscriptionsInvoicePay__subscriptions_container'>
          {invoiceItems.length > 0 && fieldsMethods.fields.map((item, index) => {
            const invoiceItem = invoiceItems.find((elem) => elem.subscriptionId === item.subscriptionId);
            if (!invoiceItem) return null;
            return <PlanItem key={item.id} id={index} item={invoiceItem} currency={invoice?.currency}
              {...methods} {...fieldsMethods} />
          })}
          {invoiceItems.length > 0 && invoiceItems.find((item) => item.type === 'waba_dialog') &&
            <InvoiceItem item={invoiceItems.find((item) => item.type === 'waba_dialog')} />}
        </tbody>
      </table>
      <div className='subscriptionsInvoicePay__subscriptions_container_bottom'>
        <h2>{t('subscriptions.invoice.total_amount')}:</h2>
        <div className='amountStyle'>
          {invoice && currencyFormat(invoice.amount, invoice.currency, i18n.language)}
        </div>
      </div>
    </div>
  );
}

export default ItemsList;
