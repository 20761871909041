import { MessageRequest, SocketDeliveryStatus } from '../../ChatTypes';
import { ConvertedMessage, SocketWrapper } from './socketIoContext';
import { ResGetAllConnForChatType } from '../../../../api/types';


type ChannelType = 'subscribe' | 'unsubscribe';

export type ChannelConnectionsNewMessage = {
  connections: ResGetAllConnForChatType[];
  connectionTypeList: string[];
  chatSocket: SocketWrapper;
  socketNewMessageHandler: (convertedMessage: ConvertedMessage, message: MessageRequest) => void;
  channel: ChannelType;
};

export const channelConnectionsNewMessage = (
  { connections, connectionTypeList, chatSocket, socketNewMessageHandler, channel }: ChannelConnectionsNewMessage) => {
  connections.forEach((connection) => {
    if (connectionTypeList.includes(connection.type)) {
      chatSocket.emit(channel, { connection_id: connection.connectionId });
    }
  });
  if (channel === 'subscribe') {
    chatSocket.onNewMessage(socketNewMessageHandler);
  } else {
    chatSocket.offNewMessage(socketNewMessageHandler);
  }
};

export type ChannelConnectionDeliveryStatus = {
  currentChat: { chatId: number; connectionId: number; };
  chatSocket: SocketWrapper;
  onNewMessageDeliveryStatus: (deliveryStatus: SocketDeliveryStatus) => void;
  channel: ChannelType;
};

export const channelConnectionDeliveryStatus = (
  { currentChat, chatSocket, onNewMessageDeliveryStatus, channel }: ChannelConnectionDeliveryStatus) => {

  if (currentChat.chatId) {
    chatSocket.emit(channel, {
      connection_id: currentChat.connectionId,
      chat_id: currentChat.chatId,
    });
    if (channel === 'subscribe') {
      chatSocket.onNewDeliveryStatus(onNewMessageDeliveryStatus);
    } else {
      chatSocket.offNewDeliveryStatus(onNewMessageDeliveryStatus);
    }
  }
};
