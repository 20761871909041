import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/store';
import { IsLoaderSelector, SubscriptionsSelector } from './SubscriptionsSelector';
import { clearSubscriptionsInfo, fetchPaymentRequisite, fetchSubscriptionsInfo } from './SubscriptionsSlice';
import { useCurrencyBill } from './utils';
import { useHasPermission } from "../../../utils/WrapperPermission/WrapperPermission";
import SubscriptionsPlug from './SubscriptionsPlug';
import SwitchTabs from '../../../components/SwitchTabs';
import SubscriptionView from './components/SubscriptionView';
import RequisitesView from './components/RequisitesView';
import TariffButton from "./components/TariffButton";
import Loader from "../../../components/Loader/Loader";


function Subscriptions() {
  const { t } = useTranslation();
  const params = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const dispatch = useAppDispatch();
  const subscriptions = useSelector(SubscriptionsSelector);
  const loading = useSelector(IsLoaderSelector);
  const [switchPosition, setSwitchPosition] = useState<string>('subscriptions');
  const isViewRequisites = useCurrencyBill();
  const permissionEdit = useHasPermission('subscriptionsEdit');

  useEffect(() => {
    const companyId = params?.params.companyId;
    dispatch(clearSubscriptionsInfo());
    if (companyId) {
      dispatch(fetchPaymentRequisite({ company_id: Number(companyId) }));
      dispatch(fetchSubscriptionsInfo({ companyId: Number(companyId) }));
    }
  }, [params?.params.companyId]);

  if (!subscriptions || subscriptions.length <= 0) {
    return <>{loading ? <div className="subscriptions"><Loader /></div> : <SubscriptionsPlug />}</>;
  }

  const tabs = [
    { tabId: 'subscriptions', tabName: t('subscriptions.switch.subscriptions') },
    { tabId: 'requisites', tabName: t('subscriptions.switch.requisites') },
  ];

  const onSwitchPosition = (e: React.MouseEvent<HTMLButtonElement>) => {
    const switcherValue = e.currentTarget.id;
    setSwitchPosition(switcherValue);
  };

  return (
    <div className="subscriptions">
      <div className="subscriptions__header">
        <div className="subscriptions__header_title">
          <h1>{t('subscriptions.title')}</h1>
          <TariffButton />
        </div>
        {permissionEdit && isViewRequisites &&
          <SwitchTabs tabs={tabs} selectedTab={switchPosition} onSelectHandler={onSwitchPosition} />}
      </div>
      <div className={`subscriptions__content ${permissionEdit && isViewRequisites ? '' : 'extended'}`}>
        {switchPosition === 'subscriptions' && <SubscriptionView setSwitchPosition={setSwitchPosition} />}
        {switchPosition === 'requisites' && <RequisitesView />}
      </div>
    </div>
  );
}

export default Subscriptions;
