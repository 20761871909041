import React from 'react';
import * as yup from 'yup';
import './NotificationCreateWhatsapp.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../../store/store";
import { usePhoneSchema } from '../../../../../components/PhoneInput/utils';
import { currentCompanySelector } from '../../../../../api/companiesSelector';
import { createNotificationsChannel } from '../../NotificationsSlice';
import { NotificationChannelTypes } from '../../NotificationsTypes';
import SidePanel from '../../../../../components/Modals/SidePanel';
import Button from '../../../../../components/Button';
import PhoneField from '../../../../../components/PhoneInput/PhoneField';


type Props = {
  setChannelConnection: React.Dispatch<NotificationChannelTypes | null>;
};

type SubmitSchema = {
  phone: string;
}

const notificationNumber = '+79046761146';

const NotificationPanelWa = ({ setChannelConnection }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const phoneSchema = usePhoneSchema({ required: true });

  const schema = yup.object().shape({
    ...phoneSchema,
  });

  const methods = useForm<SubmitSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: '',
    },
  });

  const onClose = () => {
    setChannelConnection(null);
  }

  const onSubmit = (data: SubmitSchema) => {
    dispatch(
      createNotificationsChannel({
        companyId: currentCompany.id,
        type: 'whatsapp',
        whatsapp: { phone: data.phone },
        params: { events: [] },
      })
    ).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setChannelConnection(null);
      }
    });
  };

  return (
    <SidePanel setIsOpen={onClose} background="light">
      <div className="notificationPanelWhatsapp">
        <h1>{t('settings.notifications.title_wa')}</h1>
        <p className='regularText'>{t('settings.notifications.phone_text', { number: notificationNumber })}</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <PhoneField name="phone" />
            <Button type="submit" color="orange" textType="regular" text={t('settings.notifications.add_number')} />
          </form>
        </FormProvider>
      </div>
    </SidePanel>
  );
};


export default NotificationPanelWa;
