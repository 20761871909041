import './RadioControl.scss';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';


type Props = {
  label: string;
  value: number | string;
  name: string;
  info?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
};

function RadioControl({ label, value, name, info, defaultChecked = false, disabled = false }: Props) {
  const { register } = useFormContext();

  return (
    <fieldset className='radioControl'>
      <label>
        <input {...register(name)} type="radio" defaultChecked={defaultChecked} disabled={disabled}
          name={name} value={value} />
        <span className="checkmark" />
        <span>{label}</span>
      </label>
      {info && <span className="info__block">
        <span className="info__icon"><InfoIcon /></span>
        <div className="info__message">{info}</div>
      </span>}
    </fieldset>
  );
}

export default RadioControl;
