import { FC, useEffect, useState } from 'react';
import './UserEdit.scss';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store/store';
import { currentCompanySelector } from '../../../../api/companiesSelector';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import {
  addRoleMember, changePermissionRoleMember, changeRoleMember, deleteRoleMember, getMember, setDeleteRoleCompany
} from '../../../../api/companiesSlice';
import { RoleType, UsersType } from '../../../../api/types';
import {
  getConvertPermissions, getConvertArrStrPermissions, getValidatePermission, PermissionWithKeys
} from './utlity';
import { isDifferenceArrayStr } from '../../../../utils/utils';
import PermissionsList from './components/PermissionsList/PermissionsList';
import Button from '../../../../components/Button/Button';
import RolesList from './components/RolesList/RolesList';
import ActionDialog from '../../../../components/Modals/ActionDialog';
import RoleSelector from './components/RoleSelector';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/trash.svg';


type Props = {
  user: UsersType;
  roles: RoleType[];
  isOwner: boolean;
  onCloseEditCard: () => void;
  initialStateCurrentRoleEdit: RoleType;
};

const UserEdit: FC<Props> = ({ user, roles, isOwner, onCloseEditCard, initialStateCurrentRoleEdit }) => {
  const companyId = useRouteMatch<{ companyId: string }>('/companies/:companyId')?.params.companyId;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const company = useSelector(currentCompanySelector);
  const [isRoleDelete, setIsRoleDelete] = useState<boolean>(false);
  const [changeRole, setChangeRole] = useState<number | null>(null);
  const [currentRoles, setCurrentRoles] = useState<RoleType[]>(roles);
  const [currentRole, setCurrentRole] = useState<RoleType | null>(initialStateCurrentRoleEdit);
  const [addedRole, setAddedRole] = useState<string>('');
  const [permissionsAll, setPermissionsAll] = useState<PermissionWithKeys>({});
  const convertArrStrPermissions = getConvertArrStrPermissions(permissionsAll);
  const isAdminOrManager = currentRole?.name === 'ADMINISTRATOR' || currentRole?.name === 'MANAGER';
  const isCreateRole = addedRole.length === 0;
  const isModeAddNewRole = currentRole?.mockId;

  const havePermissionsChanged = !!(currentRole?.permissions &&
    isDifferenceArrayStr(currentRole?.permissions,  convertArrStrPermissions))
  const haveRoleChanged = currentRole?.id !== initialStateCurrentRoleEdit?.id || addedRole.length > 0
  const isDirty = !(havePermissionsChanged || haveRoleChanged);

  const newRoleHasPermissions = !currentRoles.some(i => !!(i.mockId && convertArrStrPermissions.length > 0));
  const hasNewRole = currentRoles.some(i => i.mockId);
  const isDisabled = hasNewRole ? newRoleHasPermissions : isDirty;

  useEffect(() => {
    if (currentRole && currentRole.permissions) {
      const data = getConvertPermissions(currentRole.permissions, company.isPartner);
      setPermissionsAll(data);
    }
  }, [currentRole]);

  const successAlert = () => dispatch(addAlertWithCustomText({ message: t(`errors.${100000}`), type: 'info' }));

  const onSubmit = async () => {
    const permissions = convertArrStrPermissions;
    if (companyId) {
      if (isCreateRole && currentRole) {
        const payload = {
          companyId,
          name: currentRole.name,
          permissions,
          roleId: currentRole.id,
        };
        onCloseEditCard();
        if (isOwner) { // in the owner role, you can only change the permissions of other roles
          const res = await dispatch(changePermissionRoleMember(payload));
          if (res.meta.requestStatus === 'fulfilled') successAlert();
          return;
        }
        if (haveRoleChanged && !havePermissionsChanged) { // only the role has changed
         await dispatch(changeRoleMember(
            { companyId: payload.companyId, roleId: payload.roleId, memberId: user.id }));
          return;
        }
        if (!haveRoleChanged && havePermissionsChanged) { // only the permission has changed
          const res = await dispatch(changePermissionRoleMember(payload))
          if (res.meta.requestStatus === 'fulfilled') successAlert();
          return;
        }
        const res = await dispatch(changePermissionRoleMember(payload));
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(changeRoleMember({ companyId: payload.companyId, roleId: payload.roleId, memberId: user.id }));
        }
      } else {
        const res = await dispatch(addRoleMember({ companyId, name: addedRole, permissions }));
        if (res.meta.requestStatus === 'fulfilled' && res.payload && typeof res.payload !== 'number') {
          const result = await dispatch(changeRoleMember({ companyId, roleId: res.payload.id, memberId: user.id }));
          if (result.meta.requestStatus === 'fulfilled') {
            setAddedRole('');
            onCloseEditCard();
          }
        } else {
          setAddedRole(addedRole);
        }
      }
    }
  };

  const onChangePermission = (data: Record<string, Record<string, boolean>>) => {
    const keySection = Object.keys(data)[0];
    const valueSection = Object.values(data)[0];
    const namePermission = Object.keys(valueSection)[0];
    const value = Object.values(valueSection)[0];
    const resultValidated = getValidatePermission(permissionsAll, keySection, namePermission, value);
    setPermissionsAll(resultValidated);
  };

  const onHandlerDeleteRole = () => {
    if (isModeAddNewRole) {
      onCloseEditCard();
      return;
    }
    if (currentRole) setIsRoleDelete(true);
  };

  const submitDeleteRoleModal = () => {
    if (isRoleDelete && currentRole && changeRole && company) {
      dispatch(deleteRoleMember({
        roleId: currentRole.id,
        companyId: company.id,
        replacement_role_id: changeRole
      })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(setDeleteRoleCompany(currentRole.id));
          dispatch(getMember(company.id));
          setIsRoleDelete(false);
          onCloseEditCard();
          dispatch(addAlertWithCustomText({ message: t(`errors.${100000}`), type: 'info' }));
        }
      });
    }
  };

  return (
    <div className='userEdit'>
      {isRoleDelete &&
        <ActionDialog action={t('settings.users.user.delete_submit')} cancel={t('cancel')} background='light'
          title={t('settings.users.user.delete_role')}
          message={t('settings.users.user.delete_confirm', { role: currentRole?.name })}
          onAction={submitDeleteRoleModal} setIsOpen={setIsRoleDelete}>
          <RoleSelector setChangeRole={setChangeRole} roles={roles} currentRole={currentRole} isOwner={isOwner}/>
        </ActionDialog>
      }
      <span className='userEdit__close'><CloseIcon onClick={onCloseEditCard} /></span>
      <RolesList currentRole={currentRole} currentRoles={currentRoles} setCurrentRole={setCurrentRole}
        currentAddRole={addedRole} setCurrentRoles={setCurrentRoles} isOwner={isOwner}
        setCurrentAddRole={setAddedRole} />
      <PermissionsList permissions={permissionsAll} onChangePermission={onChangePermission} 
        isAdminManager={isAdminOrManager} />
      <div className='userEdit__rolesButtons'>
        <Button textType='small' text={t('save_edit')} type='submit' color='white'
          onClick={onSubmit} disabled={isDisabled} />
        {currentRole && !isAdminOrManager && !isRoleDelete && (
          <Button type='button' color='white' textType='small' image={isModeAddNewRole ? undefined : <TrashIcon />}
            onClick={onHandlerDeleteRole}
            text={isModeAddNewRole
              ? t('cancel')
              : t('settings.users.user.delete_role')
            }
          />
        )}
      </div>
    </div>
  );
};

export default UserEdit;
