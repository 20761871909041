import React, { useEffect, useState } from 'react';
import './SubscriptionsInvoicePay.scss';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from '../../../../../store/store';
import { InvoiceSelector } from '../../SubscriptionsSelector';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { addSubscriptionInvoice, clearSubscriptionPayInvoice } from '../../SubscriptionsSlice';
import { SubscriptionInvoiceRequestDialog, SubscriptionInvoiceRequestItem } from '../../SubscriptionsTypes';
import { SubscriptionInvoiceSchema } from '../../schema/schema';
import { currentCompanySelector } from '../../../../../api/companiesSelector';
import { useCurrencyBill } from '../../utils';
import { tarifName, TariffType } from "../../../../../utils/dialogs";
import { useRequisites } from './helper';
import UserSubscription from '../../models/UserSubscription';
import Button from '../../../../../components/Button/Button';
import PeriodSelector from './PeriodSelector/PeriodSelector';
import PromoCode from "./PromoCode/PromoCode";
import ItemsList from './ItemsList/ItemsList';
import DialogsInput from './DialogsInput/DialogsInput';
import PaymentMethod from './PaymentMethod';
import Requisites from "./Requisites/Requisites";
import Loader from '../../../../../components/Loader/Loader';
import RadioControl from '../../../../../components/RadioControl';


const DIALOGS_LIMITS = { min: 1, max: 25000 };

type Props = {
  setOnPay: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionsList?: UserSubscription[];
  setSwitchPosition: React.Dispatch<React.SetStateAction<string>>;
  dialogOnly?: boolean;
};

function SubscriptionsInvoicePay({ setOnPay, subscriptionsList, setSwitchPosition, dialogOnly = false }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const invoiceReview = useSelector(InvoiceSelector);
  const isBillAvailable = useCurrencyBill();
  const requisites = useRequisites();
  const [isVisiblePromoCode, setIsVisiblePromoCode] = useState(false);
  const [promoCodeTest, setPromoCodeTest] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const schema = yup.object().shape({
    subscriptions: yup.array().of(
      yup.object().shape({
        subscriptionId: yup.number().required(t('error.form.empty_field')).typeError(t('error.form.empty_field')),
        count: yup.number().required(t('error.form.empty_field')),
        plan: yup.string().required(t('error.form.empty_field'))
    })),
    update: yup.boolean().nullable(true),
    period_months: yup.number().nullable(true),
    company_requisite_id: yup.number().when('payment_method', {
      is: 'BANK_TRANSFER',
      then: yup.number().required(t('error.form.empty_field')).typeError(t('error.form.empty_field')),
      otherwise: yup.number().nullable(),
    }),
    promo_code: yup.string().nullable(),
    payment_method: yup.string().nullable(),
    dialogs: yup.number().integer(t('error.form.integer_field')).nullable(true)
      .transform((_, val) => (val !== "" ? Number(val) : null))
      .min(DIALOGS_LIMITS.min, t('error.form.min_value', { limit: DIALOGS_LIMITS.min }))
      .max(DIALOGS_LIMITS.max, t('error.form.max_value', { limit: DIALOGS_LIMITS.max }))
  });

  const methods = useForm<SubscriptionInvoiceSchema>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      update: true,
      subscriptions: subscriptionsList &&
        subscriptionsList.map((sub) => ({ subscriptionId: sub.id, count: sub.maxSize, plan: sub.plan })),
      company_requisite_id: null,
      promo_code: '',
      payment_method: 'CARD_RU',
    },
  });

  const selectedSubscriptions = dialogOnly
  ? []
  : [...(subscriptionsList || [])];
  const hasWaba = tarifName(currentCompany?.waba360DialogPartnerId as TariffType) === 'russia' &&
  (subscriptionsList?.some((item) => item.type === 'waba') || dialogOnly);
  const update = useWatch({ control: methods.control, name: 'update' });
  const period = useWatch({ control: methods.control, name: 'period_months' });
  const payment = useWatch({ control: methods.control, name: 'payment_method' });
  const promo = useWatch({ control: methods.control, name: 'promo_code' });
  const dialogsInput = useWatch( { control: methods.control, name: 'dialogs' });
  const subscriptions = useWatch({ control: methods.control, name: 'subscriptions' });
  const [dialogs] = useDebounce(dialogsInput, 400);
  const showSubscriptionsParams = !hasWaba || (selectedSubscriptions && selectedSubscriptions?.length > 0);
  const isPaymentMethodRequisite = payment === 'BANK_TRANSFER';

  useEffect(() => () => {
    dispatch(clearSubscriptionPayInvoice());
  }, []);

  useEffect(() => {
    if (period) methods.setValue('update', false);
  }, [period]);
  
  useEffect(() => {
    if (update) methods.setValue('period_months', undefined);
  }, [update]);
  
  useEffect(() => {
    if (promo) checkPromo();
  }, [promo]);
  
  useEffect(() => {
    if (payment) methods.setValue('company_requisite_id', requisites[0]?.id);
  }, [payment]);

  useEffect(() => {
    if (methods.formState.errors.dialogs === undefined) getInvoice(methods.getValues(), true);
  }, [period, dialogs, update, subscriptions]);

  const checkPromo = async () => {
    const invoiceUrl = await getInvoice(methods.getValues(), true);
    setPromoCodeTest(!!invoiceUrl);
    if (!invoiceUrl) methods.setValue('promo_code', '');
  };

  const onClickPromoCode = (promoCodeValue: string) => {
    methods.setValue('promo_code', promoCodeValue);
  };

  const getInvoice = async (data: SubscriptionInvoiceSchema, preview: boolean) => {
    let invoiceUrl = '';
    const items: Array<SubscriptionInvoiceRequestItem | SubscriptionInvoiceRequestDialog> = [];
    if (selectedSubscriptions.length > 0 && data.subscriptions) {
      items.push(...data.subscriptions.map(
        (item) => ({
          type: 'SUBSCRIPTION',
          id: item.subscriptionId,
          new_size: item.count,
          new_subscription_plan: item.plan } as SubscriptionInvoiceRequestItem)));
    }
    if (data.dialogs && data.dialogs > 0) {
      items.push({ type: 'WABA_DIALOGS', count: data.dialogs } as SubscriptionInvoiceRequestDialog);
    }
    if (items.length > 0) {
      await dispatch(addSubscriptionInvoice({
        company_id: currentCompany.id, preview, items,
        payment_method: currentCompany.paymentCurrency === 'USD' ? "CARD_OTHER" : data.payment_method,
        period_months: data.period_months ?? 0,
        ...(data.promo_code ? { promo_code: data.promo_code } : {}),
        ...(data.company_requisite_id && isPaymentMethodRequisite
            ? { company_requisite_id: data.company_requisite_id } : {})
      })).then((res) => {
        if (typeof res.payload === 'object') {
          invoiceUrl = res.payload?.previewUrl;
        }
      });
    }
    return invoiceUrl;
  };

  const onSubmit = async (data: SubscriptionInvoiceSchema) => {
    setClicked(true);
    const invoiceUrl = await getInvoice(data, false);
    if (invoiceUrl) {
      dispatch(addAlertWithCustomText({ message: t('subscriptions.invoice.submit_fulfilled'), type: 'info' }));
      setOnPay(false);
      window.open(invoiceUrl, '_blank');
    }
    setClicked(false);
  };

  const onAddNewRequisites = () => {
    setOnPay(false);
    setSwitchPosition('requisites');
  };

  const showPromoInput = () => {
    setIsVisiblePromoCode(true);
  };

  if (!invoiceReview && !dialogOnly) {
    return (
      <div className='subscriptionsInvoicePay'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='subscriptionsInvoicePay'>
      <h1>{t('subscriptions.pay')}</h1>
      <FormProvider {...methods}>
        <div className='subscriptionsInvoicePay__content'>
        {showSubscriptionsParams && (<>
          <h3>{t('subscriptions.invoice.operation')}</h3>
          <RadioControl name='update' label={t('subscriptions.invoice.switch')} defaultChecked={true} value={'true'} />
          <PeriodSelector />
        </>)}
        {hasWaba && <DialogsInput />}
        <ItemsList invoice={invoiceReview} subscriptions={selectedSubscriptions} />
        {showSubscriptionsParams && <div className='subscriptionsInvoicePay__promoCode'>
          {isVisiblePromoCode
            ? (
              <div className='subscriptionsInvoicePay__promoCode_content'>
                <PromoCode setPromo={onClickPromoCode} showInfo={hasWaba} />
                {promoCodeTest && <span>{t('subscriptions.invoice.success_promo_code')}</span>}
              </div>
            ): <p className='smallText' onClick={showPromoInput}>{t('subscriptions.invoice.have_promo_code')}</p>}
        </div>}
        {currentCompany.paymentCurrency === 'RUB' && <PaymentMethod />}
        {isBillAvailable && isPaymentMethodRequisite &&
          <Requisites onAddNewRequisites={onAddNewRequisites} requisites={requisites} />}
          <div className={`subscriptionsInvoicePay__bottom ${clicked ? 'clicked' : ''}`}>
            <Button type='submit' text={t('next')} color='orange' textType='regular'
              onClick={methods.handleSubmit(onSubmit)} />
          </div>
        </div>
      </FormProvider>
    </div>
  );
}

export default SubscriptionsInvoicePay;
