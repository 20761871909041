import { memo } from 'react';
import './PeriodSelector.scss';
import { useTranslation } from 'react-i18next';
import { getMonthPeriods } from '../helper';
import { useEvent } from '../../../../../../utils/event';
import RadioButton from '../../../../../../components/RadioControl';


function PeriodSelector() {
  const { t } = useTranslation();
  const event = useEvent();

  return (
    <div className='subscriptionsInvoicePay__period'>
      {getMonthPeriods(event.active).map((month, index) =>
        <div key={month.month} className='subscriptionsInvoicePay__period_month'>
          <RadioButton name='period_months' value={month.month}
            label={`${t('subscriptions.invoice.extend')} ${month.month} ${t(month.comment)}`} />
          <div className='addon'>
            {month.component && month.component}
            <div className='discount smallText' hidden={month.discount === '' && true}>{month.discount}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(PeriodSelector);
