import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentCompanySelector } from "../../../api/companiesSelector";
import axiosInstance from "../../../api/AxiosInstance";


type InviteLink = {
  url: string;
};

const getInviteLink = async (companyId: number, signal?: AbortSignal): Promise<InviteLink> =>
  await axiosInstance.get(`/companies/${companyId}/invite_link`, { signal });

export function useInviteLink() {
  const { id: companyId } = useSelector(currentCompanySelector);
  const [ inviteLink, setInviteLink ] = useState<InviteLink>();

  const fetchLink = async (controller?: AbortController) => {
    if (companyId) {
      try {
        const link = await getInviteLink(companyId, controller?.signal);
        if (link) setInviteLink(link);
      } catch {
        setInviteLink(undefined);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchLink(controller);

    return () => controller.abort();
  }, [companyId]);
  
  return { inviteLink, fetchLink };
}
