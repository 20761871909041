import { TFunction } from 'react-i18next';
import {
  keysPermission, PERMISSIONS, VALIDATIONS_PERMISSIONS
} from '../../../../utils/WrapperPermission/permission-maps';


export const rolesSystem = ['ADMINISTRATOR', 'MANAGER', 'PARTNER'];

export type PermissionWithKeys = Record<string, Record<string, boolean>>

export const getConvertArrStrPermissions = (permissions: PermissionWithKeys) => {
  const result: string[] = [];
  Object.entries(permissions).forEach(([_, value]) => {
    if (Object.keys(value).length > 0) {
      Object.entries(value).forEach(([k, v]) => {
        if (v) result.push(k);
      });
    }
  });
  return result;
};

export const getConvertPermissions = (permissionsCurrentRole: string[], isPartner: boolean) => {
  const allPermissions: string[] = isPartner
    ? Object.values(PERMISSIONS)
    : Object.values(PERMISSIONS).filter(p => !p.includes('partner'));
  const resultPermissions: PermissionWithKeys = {};

  allPermissions.forEach(permission => {
      const isPermission = permissionsCurrentRole.length > 0 && permissionsCurrentRole.find(p => p === permission);
      const findKeyPermission = keysPermission.find(key => permission.includes(key));

      if (findKeyPermission) {

        const setPermission = (k: string, v: boolean) => {
          if (resultPermissions[findKeyPermission]) {
            const isDuplicate = resultPermissions[findKeyPermission][k];
            if (!isDuplicate) {
              resultPermissions[findKeyPermission][k] = v;
            }
          } else {
            resultPermissions[findKeyPermission] = {
              [k]: v,
            };
          }
        };

        if (isPermission) {
          setPermission(isPermission, true);
        } else {
          // is it Members_view permissions? then true otherwise false (all roles have permissions to view members)
          const isMembersView = permission.includes('members_view');
          setPermission(permission, isMembersView);
        }
      }
    }
  );

  return resultPermissions;
};

export const getNameRole = (roleName: string, isOwner: boolean, t: TFunction<'translation'>) => {
  const translateKey = 'settings.users.added';
  if (isOwner) return t(`${translateKey}.owner`);
  if (rolesSystem.includes(roleName)) return t(`${translateKey}.${roleName.toLowerCase()}`);
  return roleName;
};

export const getValidatePermission = (
  permissions: PermissionWithKeys,
  keySection: string,
  namePermission: string,
  value: boolean) => {
  permissions[keySection][namePermission] = value;
  const results: PermissionWithKeys = { ...permissions };

  if (value) {
    if (VALIDATIONS_PERMISSIONS[namePermission]) {
      VALIDATIONS_PERMISSIONS[namePermission].necessary.forEach(necessaryPermission => {
        if (!results[keySection][necessaryPermission]) {
          const findKeySectionPermission = keysPermission.find(key => necessaryPermission.includes(key));
          if (findKeySectionPermission) {
            results[findKeySectionPermission][necessaryPermission] = true;
          }
        }
      });
    }
  } else {
    Object.values(results).forEach(permissionObj => {
      Object.keys(permissionObj).forEach(permission => {
        const forShutdowns = VALIDATIONS_PERMISSIONS[namePermission]?.uncheckWhenDisable?.includes(permission);
        if (forShutdowns) {
          permissionObj[permission] = false;
        }
      });
    });
  }
  return results;
};
