import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAlertWithCustomText, setAlertWithClosing } from "../../../../components/Alert/alertSlice";
import { currentCompanySelector } from "../../../../api/companiesSelector";
import { getItegrationBitrixOpenLines, getPipelines } from "../IntegrationAPI";
import { BitrixOpenLine, IntegrationRequest, IntegrationsPipelines, Pipelines } from "../IntegrationTypes";
import { Areas, MessagingPipeline } from "../types";


type UsePipelines = {
  area: Areas;
  integration: number;
};

const uploadPipeline: Partial<Record<Areas, (request: IntegrationRequest) => Promise<IntegrationsPipelines>>> = {
  amocrm: getPipelines,
  bitrix: getItegrationBitrixOpenLines
};

const convertPipelines = (pipelines: Pipelines[]): MessagingPipeline[] =>
  pipelines.map((line) => ({ id: line.pipelineId, name: line.pipelineName, statuses: line.statuses }));

const convertOpenlines = (openlines: BitrixOpenLine[]): MessagingPipeline[] =>
  openlines.map((line) => ({ id: Number(line.id), name: line.name }));

type converterLines =
  ((pipelines: Pipelines[]) => MessagingPipeline[]) | ((pipelines: BitrixOpenLine[]) => MessagingPipeline[]);

const convertLines: Partial<Record<Areas, converterLines>> = {
  amocrm: convertPipelines,
  bitrix: convertOpenlines
};

export function usePipelines({ area, integration }: UsePipelines) {
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const [pipelines, setPipelines] = useState<MessagingPipeline[]>([]);
  const [fetched, setFetched] = useState<boolean>(false);

  useEffect(() => {
    let isFetchData = true;
    const fetchPipelines = async () => {
      const requestPipelines = uploadPipeline[area];
      if (requestPipelines) {
        const pipelinesData = await requestPipelines({ companyId: company.id, integrationId: integration });

        if (isFetchData) {
          const converter = convertLines[area];
          if (converter) {
            setPipelines(converter(pipelinesData.pipelines as (Pipelines[] & BitrixOpenLine[])))
          };
        }
      }
    };

    fetchPipelines().catch(e => {
      if (e.response) {
        dispatch(addAlertWithCustomText({ code: e.response.data.error_code, type: 'alarm' }));
      } else {
        dispatch(setAlertWithClosing({ payload: 'error.amoPipelines', type: 'alarm' }));
      }
    }).finally(() => setFetched(true));

    return () => {
      isFetchData = false;
      setFetched(false);
    };
  }, [integration]);

  return { pipelines, fetched };
}
