import React, { FC } from 'react';
import './PermissionItem.scss';
import { useTranslation } from 'react-i18next';
import { PERMISSIONS } from '../../../../../../utils/WrapperPermission/permission-maps';
import Checkbox from '../../../../../../components/Checkbox';


const optionsArr = ['view', 'create', 'add', 'edit', 'delete', 'access'];
const getCrudName = (name: string) => optionsArr.find(i => name.includes(i));

type PermissionItemProps = {
  keyPermission: string;
  listPermission: Record<string, boolean>;
  isAdminManager: boolean;
  onChangePermission: (obj: Record<string, Record<string, boolean>>) => void;
};

const getDisabled = (permission: string, isAdminManager: boolean) =>
  permission === PERMISSIONS.membersView ? true : isAdminManager;

const PermissionItem: FC<PermissionItemProps> = (
  { keyPermission, listPermission, isAdminManager, onChangePermission }) => {
  const { t } = useTranslation();

  const onChangeCrudCheckValue = (e: React.ChangeEvent<HTMLInputElement>, nameKey: string) => {
    onChangePermission({ [keyPermission]: { [nameKey]: e.target.checked } });
  };

  return (
    <div className='permissionItem'>
      <p className='smallText semibold'>{t(`settings.users.user.permissions.${keyPermission}.title`)}</p>
      <div className='permissionItem__items'>
        {Object.keys(listPermission)?.length > 0 && Object.entries(listPermission).map(([key, value]) => (
          <label id={key} key={key} className='permissionItem__items_label'>
            <Checkbox name='checkbox' value={value} onCheckedHandler={(e) => onChangeCrudCheckValue(e, key)}
              disabled={getDisabled(key, isAdminManager)} />
            <span className='smallText'>
              {t(`settings.users.user.permissions.${keyPermission}.${getCrudName(key)}`)}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default PermissionItem;
