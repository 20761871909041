import React, { useEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import './CompaniesList.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import { ReactComponent as CompanyIcon } from '../../../../../assets/companies.svg';
import { ReactComponent as Arrow } from '../../../../../assets/arrow-down.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Company } from '../../../../../api/types';
import { companiesSelector, currentCompanySelector } from '../../../../../api/companiesSelector';
import { fetchCompanies } from '../../../../../api/companiesSlice';
import { getViewPopup } from "../../utils";
import { useMobile } from '../../../../../components/utils/hooks';
import PopupWrapper from '../../../../../components/PopupWrapper/PopupWrapper';
import usePopupWrapper from '../../../../../components/PopupWrapper/usePopupWrapper';
import PopupTitle from '../PopupTitle';
import Button from "../../../../../components/Button/Button";


type CompaniesListProps = {
  isRolledUp: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  position: string;
};

const COMPANIES_FETCH_LIMIT = 10;

const CompaniesList = ({ isRolledUp, setIsOpen, position }: CompaniesListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const companies = useSelector(companiesSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const { isVisible, onTriggerPopupWrapper, onClosePopupWrapper } = usePopupWrapper();
  const triggerButtonRef = useRef<HTMLDivElement>(null);
  const { viewPopup, onMouseLeave, onMouseEnter } = getViewPopup();
  const isMobile = useMobile();

  const onSelectCompany = (company: Company) => {
    if (company.id === currentCompany.id) return;
    history.push(`/companies/${company.id}`);
    if (isMobile && setIsOpen) setIsOpen(false);
  };

  useEffect(() => {
    dispatch(fetchCompanies({ limit: COMPANIES_FETCH_LIMIT, offset: 0, with_partner_companies: false }));
  }, [currentCompany.id]);

  const getCompanies = () => {
    dispatch(fetchCompanies({ limit: COMPANIES_FETCH_LIMIT, offset: companies?.length, with_partner_companies: false })
    );
  };

  const stylePopup = {
    bottom: isMobile ? 'auto' : 140,
    top: isMobile ? 280 : 'auto',
    left: isMobile ? 20 : 12,
  };

  return (
    <div className={`companyDropdown ${isRolledUp ? 'rolledUp' : ''} ${isMobile ? 'mobile' : ''}`}
      ref={triggerButtonRef} onClick={onTriggerPopupWrapper}>
      {isRolledUp ? (
        <div className='companyDropdown__logo'>
          <PopupTitle text={t('navbar.company')} refPopup={triggerButtonRef}
            image={<CompanyIcon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />}
            viewPopup={viewPopup} />
        </div>
      ) : (
        <>
          <div className="companyDropdown__name">{currentCompany.name}</div>
          <div className="companyDropdown__icon" style={{ transform: isVisible ? 'rotateX(180deg)' : 'inherit' }}>
            <Arrow />
          </div>
        </>
      )}
      {isVisible && ReactDOM.createPortal(<PopupWrapper
        direction={position}
        title={t('navbar.dropdown.title')}
        isVisible={isVisible}
        onClosePopupWrapper={onClosePopupWrapper}
        triggerButton={triggerButtonRef}
        style={stylePopup}
      >
        {companies && (
          <InfiniteScroll
            next={getCompanies}
            hasMore={true}
            inverse={false}
            scrollableTarget="scrollableList"
            loader={null}
            dataLength={companies.length}
          >
            <div className="companyDropdown__list" id="scrollableList">
              {companies.map((company) => (
                <div className={`companyDropdown__list_item ${currentCompany.id === company.id ? 'active' : ''}`}
                     key={company.id} onClick={() => onSelectCompany(company)}>
                  <p>{company.name}</p>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}
        <div className='createCompany'>
          <Button color="white" textType="small" text={t('navbar.dropdown.add_company')}
            image={<PlusIcon />} onClick={() => history.push('/registration', url)} />
        </div>
      </PopupWrapper>, document.body)}
    </div>
  );
};

export default CompaniesList;
