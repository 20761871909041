import React, { useEffect, useRef, useState } from 'react';
import './Select.scss';
import { useOutsideClick } from '../utils/hooks';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-down.svg';


type SelectProps<T> = {
  items: T[];
  labelKey: Extract<keyof T, string>;
  caption?: string;
  onChoose?: ((item: T) => void) | ((item: T) => boolean);
  initValue?: string | number | null;
} & React.InputHTMLAttributes<HTMLInputElement>;

type Items = {
  id: string | number | null;
  name: string;
  logo?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function checkLogo<T extends Items>(item?: T) {
  return item?.logo !== undefined;
}

const Select = <T extends Items & { title?: string | undefined; }>(
  { caption, items, labelKey, onChoose, initValue, ...props }: SelectProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const triggerRef =  useRef<HTMLDivElement>(null);

  useOutsideClick(inputRef, () => setIsOpen(false), triggerRef);

  useEffect(() => {
    if (initValue && inputRef.current) inputRef.current.value = initValue.toString();
  }, [initValue]);

  const onSelectHandler = (value: string) => {
    if (inputRef.current) {
      setIsOpen(false);
      const item = items.find((itm) => itm[labelKey] === value);
      if (onChoose) {
        if (item) {
          const res = onChoose(item);
          if (typeof res === 'boolean') {
            if (res) inputRef.current.value = value;
          } else {
            inputRef.current.value = value;
          }
        }
      } else {
        inputRef.current.value = value;
      }
    }
  };

  const isSelected = (value: string) => {
    if (inputRef.current) {
      return inputRef.current.value === value;
    }
    return false;
  };

  const getLogoStyled = (item: T, styleClass: string) => {
    const Logo = item.logo;
    if (Logo) {
      return <Logo className={styleClass} />;
    }
    return null;
  };

  const getItem = () => {
    if (inputRef.current) {
      return items.find(i => i[labelKey] === inputRef.current?.value);
    }
  };

  const getItemLogo = (item?: T) => item && getLogoStyled(item, 'logoIcon');

  return (
    <div ref={triggerRef} className={`select ${props.disabled ? 'disabled' : ''}`}>
      <label className='regularText middle'>{caption}</label>
      <fieldset>
        {getItemLogo(getItem())}
        <input className={`${checkLogo(getItem()) ? 'logo' : ''}`} ref={inputRef} readOnly={true}
          onClick={() => setIsOpen((prev) => !prev)} {...props} />
        <div className={`arrowIcon ${isOpen && 'opened'}`}>
          <ArrowIcon />
        </div>
        {isOpen && <ul>
          {items.map((item) => (
            <li key={item.id} className={isSelected(String(item[labelKey])) ? 'selected' : ''}
              onClick={() => onSelectHandler(String(item[labelKey]))}>
              {getItemLogo(item)}
              {String(item[labelKey])}
            </li>
          ))}
        </ul>}
      </fieldset>
    </div>
  );
}

export default Select;
