import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as typ from './PartnerCabinetTypes';
import * as api from './PartnerCabinetAPI';


type NotificationNew = 'idle' | 'pending' | 'fulfilled';

export type PartnerCabinetState = {
  parnerProfile: typ.PartnerProfile | null;
  partnerCompany: typ.PartnerCompany | null;
  partnerCompanies: typ.PartnerCompany[];
  partnerClientsList: typ.PartnerCompany[];
  partnerReferralList: typ.PartnerCompany[];
  partnerPayments: typ.CompanyPayment[];
  partnerSubscriptions: typ.CompanySubscription[];
  partnerNotifications: typ.PartnerNotificationChannel[];
  partnerRequisites: typ.CompanyRequisite[];
  partnerHasPayments: boolean;
  partnerHasCompanies: boolean;
  partnerNotificationNew: NotificationNew;
  partnerTelegramChannelNew: number | null;
  partnerBalance: number | null;
  partnerRewards: typ.PartnerReward[];
  partnerPayouts: typ.PartnerPayout[];
  partnerLoader: boolean;
};

const initialState: PartnerCabinetState = {
  parnerProfile: null,
  partnerCompany: null,
  partnerCompanies: [],
  partnerClientsList: [],
  partnerReferralList: [],
  partnerPayments: [],
  partnerSubscriptions: [],
  partnerNotifications: [],
  partnerRequisites: [],
  partnerHasPayments: true,
  partnerHasCompanies: true,
  partnerNotificationNew: 'idle',
  partnerTelegramChannelNew: null,
  partnerBalance: null,
  partnerRewards: [],
  partnerPayouts: [],
  partnerLoader: false,
};

export const fetchPartnerProfile = createAsyncThunk<typ.PartnerProfile, number, { rejectValue: number }>(
  'partnerCabinet/fetchPartnerProfile', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.getPartnerProfile(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchPartnerCompanies =
  createAsyncThunk<typ.PartnerCompany[], typ.PartnerCompaniesRequest, { rejectValue: number }>(
  'partnerCabinet/fetchPartnerCompanies', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.getPartnerCompanies(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchPartnerCompaniesFilter =
  createAsyncThunk<typ.PartnerCompany[], typ.PartnerCompaniesRequest, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerCompaniesFilter', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerCompanies(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const fetchPartnerCompany =
  createAsyncThunk<typ.PartnerCompany, typ.PartnerCompanyRequest, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerCompany', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerCompany(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const fetchPartnerCompanyPayments =
  createAsyncThunk<typ.CompanyPayment[], typ.CompaniesPaymentsRequest, { rejectValue: number }>(
  'partnerCabinet/fetchPartnerCompanyPayments', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.getPartnerCompaniesPayments(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const uploadPartnerCompanyPayments =
  createAsyncThunk<typ.CompanyPayment[], typ.CompaniesPaymentsRequest, { rejectValue: number }>(
  'partnerCabinet/uploadPartnerCompanyPayments', async (requestOptions, { rejectWithValue }) => {
    try {
      const { limit, ...rest } = requestOptions;
      const requestLimit = limit || 3;
      return await api.getPartnerCompaniesPayments({ ...rest, limit: requestLimit + 1 });
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const fetchPartnerCompanySubscriptions =
  createAsyncThunk<typ.CompanySubscription[], typ.PartnerCompanyRequest, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerCompanySubscriptions', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerCompanySubscriptions(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const fetchPartnerCompanyNotifications =
  createAsyncThunk<typ.PartnerNotificationChannel[], {companyId: number}, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerCompanyNotifications', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerNotificationChannels(requestOptions.companyId);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const addPartnerCompanyInvoice =
  createAsyncThunk<typ.CompanyPayment, typ.PartnerCompanyInvoiceRequest, { rejectValue: number }>(
  'partnerCabinet/addPartnerCompanyInvoice', async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.postPartnerCompanyInvoice(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  });

export const updatePartnerCompanyPayment =
  createAsyncThunk<typ.PartnerCompany, typ.PaymentLockRequest, { rejectValue: number }>(
    'partnerCabinet/updatePartnerCompanyPayment', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.patchPartnerCompanyPayment(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const fetchPartnerRequisites =
  createAsyncThunk<typ.CompanyRequisite[], typ.PartnerRequisitesRequest, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerRequisites', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerRequisites(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const createPartnerRequisite =
  createAsyncThunk<typ.CompanyRequisite, typ.PartnerRequisitePostRequest, { rejectValue: number }>(
    'partnerCabinet/addPartnerRequisite', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.postPartnerRequisite(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const updatePartnerRequisite =
  createAsyncThunk<typ.CompanyRequisite, typ.UpdatePartnerRequisite, { rejectValue: number }>(
    'partnerCabinet/updatePartnerRequisite', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.putPartnerRequisite(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const removePartnerRequisite =
  createAsyncThunk<typ.CompanyRequisite, typ.PartnerRequisiteRequest, { rejectValue: number }>(
    'partnerCabinet/removePartnerRequisite', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.deletePartnerRequisite(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });


type CreateNotificationChannel = typ.PartnerNotificationChannel | typ.PartnerNotificationTelegramResponse;
export const createPartnerNotificationChannel =
  createAsyncThunk<CreateNotificationChannel, typ.PartnerNotificationChannelCreate, { rejectValue: number }>(
    'partnerCabinet/createPartnerNotificationChannel', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.postPartnerNotificationChannel(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const updatePartnerNotificationChannel =
  createAsyncThunk<typ.PartnerNotificationChannel, typ.PartnerNotificationChannelUpdate, { rejectValue: number }>(
    'partnerCabinet/updatePartnerNotificationChannel', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.patchPartnerNotificationChannel(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const removePartnerNotificationChannel =
  createAsyncThunk<unknown, typ.PartnerNotificationChannelRequest, { rejectValue: number }>(
    'partnerCabinet/removePartnerNotificationChannel', async (requestOptions, { rejectWithValue }) => {
      try {
        await api.deletePartnerNotificationChannel(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const fetchPartnerBalance =
  createAsyncThunk<typ.PartnerCurrentBalance, { companyId: number }, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerBalance', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerCurrentBalance(requestOptions.companyId);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const fetchPartnerRewards =
  createAsyncThunk<typ.PartnerRewards, typ.PartnerCompanyListRequest, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerRewards', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerRewards(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const fetchPartnerPayouts =
  createAsyncThunk<typ.PartnerPayouts, typ.PartnerCompanyListRequest, { rejectValue: number }>(
    'partnerCabinet/fetchPartnerPayouts', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.getPartnerPayouts(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const createPartnerPayout =
  createAsyncThunk<typ.PartnerPayout, typ.PartnerPayoutRequest, { rejectValue: number }>(
    'partnerCabinet/createPartnerPayout', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.postPartnerPayout(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

export const createPartnerClient =
  createAsyncThunk<typ.PartnerClient, typ.PartnerClientRequest, { rejectValue: number }>(
    'partnerCabinet/createPartnerClient', async (requestOptions, { rejectWithValue }) => {
      try {
        return await api.postPartnerClient(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    });

const PartnerCabinetSlice = createSlice({
  name: 'partnerCabinet',
  initialState,
  reducers: {
    clearPartnerCompany: (state) => {
      state.partnerCompany = null;
      state.partnerSubscriptions = [];
      state.partnerNotifications = [];
      state.partnerPayments = [];
    },
    setPartnerNotificationNew: (state, action: PayloadAction<NotificationNew>) => {
      if (state.partnerNotificationNew === 'idle' && action.payload === 'fulfilled') {
        state.partnerNotificationNew = 'idle';
      } else {
        state.partnerNotificationNew = action.payload;
      }
      if (action.payload === 'idle') {
        state.partnerTelegramChannelNew = null;
      }
    },
    setPartnerTelegramChannelNew: (state, action: PayloadAction<number>) => {
      if (state.partnerNotificationNew === 'pending') {
        state.partnerTelegramChannelNew = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnerProfile.fulfilled, (state, action: PayloadAction<typ.PartnerProfile>) => {
        state.parnerProfile = action.payload;
      })
      .addCase(fetchPartnerCompanies.fulfilled, (state, action) => {
        state.partnerCompanies = action.payload;
        state.partnerLoader = false;
      })
      .addCase(fetchPartnerCompanies.pending, (state) => {
        state.partnerLoader = true;
      })
      .addCase(fetchPartnerCompanies.rejected, (state) => {
        state.partnerLoader = false;
      })
      .addCase(fetchPartnerCompaniesFilter.fulfilled, (state, action) => {
        if (action.meta.arg.partner_company_type === 'partner') {
          state.partnerClientsList = action.payload;
        } else if (action.meta.arg.partner_company_type === 'referral') {
          state.partnerReferralList = action.payload;
        }
        state.partnerLoader = false;
      })
      .addCase(fetchPartnerCompaniesFilter.pending, (state) => {
        state.partnerLoader = true;
      })
      .addCase(fetchPartnerCompaniesFilter.rejected, (state) => {
        state.partnerLoader = false;
      })
      .addCase(fetchPartnerCompany.fulfilled, (state, action: PayloadAction<typ.PartnerCompany>) => {
        state.partnerCompany = action.payload;
      })
      .addCase(fetchPartnerCompanyPayments.fulfilled, (state, action) => {
        if (state.partnerPayments.length > 0 ) {
          const paymentsIds = state.partnerPayments.map(payment => payment.id);
          state.partnerPayments.push(...action.payload.filter((item) => !paymentsIds.includes(item.id)));
          if (action.meta.arg.limit) {
            state.partnerHasPayments = action.meta.arg.limit === action.payload.length;
          }
        } else {
          state.partnerPayments = action.payload;
        }
      })
      .addCase(uploadPartnerCompanyPayments.fulfilled, (state, action) => {
        if (action.meta.arg.limit) {
          if (action.meta.arg.offset) {
            state.partnerPayments.push(...action.payload.slice(0, action.meta.arg.limit));
          } else {
            state.partnerPayments = action.payload.slice(0, action.meta.arg.limit);
          }
          state.partnerHasPayments = action.meta.arg.limit < action.payload.length;
        }
      })
      .addCase(updatePartnerCompanyPayment.fulfilled, (state, action: PayloadAction<typ.PartnerCompany>) => {
        state.partnerCompany = action.payload;
      })
      .addCase(fetchPartnerCompanySubscriptions.fulfilled,
        (state, action: PayloadAction<typ.CompanySubscription[]>) => {
        state.partnerSubscriptions = action.payload;
      })
      .addCase(fetchPartnerCompanyNotifications.fulfilled,
        (state, action: PayloadAction<typ.PartnerNotificationChannel[]>) => {
        state.partnerNotifications = action.payload;
      })
      .addCase(addPartnerCompanyInvoice.fulfilled, (state, action: PayloadAction<typ.CompanyPayment>) => {
        if (!action.payload.preview) {
          if (state.partnerPayments !== null) {
            state.partnerPayments.unshift(action.payload);
          } else {
            state.partnerPayments = [action.payload];
          }
        }
      })
      .addCase(fetchPartnerRequisites.fulfilled, (state, action: PayloadAction<typ.CompanyRequisite[]>) => {
        state.partnerRequisites = action.payload;
      })
      .addCase(createPartnerRequisite.fulfilled, (state, action: PayloadAction<typ.CompanyRequisite>) => {
        state.partnerRequisites.push(action.payload);
      })
      .addCase(updatePartnerRequisite.fulfilled, (state, action: PayloadAction<typ.CompanyRequisite>) => {
        const idIndex = state.partnerRequisites.findIndex((requisite) => requisite.id === action.payload.id);
        state.partnerRequisites[idIndex] = action.payload;
      })
      .addCase(removePartnerRequisite.fulfilled, (state, action) => {
        const idIndex = state.partnerRequisites.findIndex((requisite) => requisite.id === action.meta.arg.requisite_id);
        state.partnerRequisites.splice(idIndex, 1);
      })
      .addCase(createPartnerNotificationChannel.fulfilled, (
        state, action: PayloadAction<CreateNotificationChannel>) => {
          if (typ.isNotificationChannel(action.payload)) {
            state.partnerNotifications.push(action.payload);
          }
      })
      .addCase(updatePartnerNotificationChannel.fulfilled, (
        state, action: PayloadAction<typ.PartnerNotificationChannel>) => {
        const idIndex = state.partnerNotifications.findIndex((channel) => channel.id === action.payload.id);
        state.partnerNotifications[idIndex] = action.payload;
      })
      .addCase(removePartnerNotificationChannel.fulfilled, (state, action) => {
        const idIndex = state.partnerNotifications.findIndex((channel) => channel.id === action.meta.arg.channelId);
        state.partnerNotifications.splice(idIndex, 1);
      })
      .addCase(fetchPartnerBalance.fulfilled, (state, action: PayloadAction<typ.PartnerCurrentBalance>) => {
        state.partnerBalance = action.payload.balance;
      })
      .addCase(fetchPartnerRewards.fulfilled, (state, action) => {
        if (action.meta.arg.offset && state.partnerRewards.length > 0 ) {
          const rewardsIds = state.partnerRewards.map(reward => reward.id);
          state.partnerRewards.push(...action.payload.rewards.filter((item) => !rewardsIds.includes(item.id)));
        } else {
          state.partnerRewards = action.payload.rewards;
        }
      })
      .addCase(fetchPartnerPayouts.fulfilled, (state, action) => {
        if (action.meta.arg.offset && state.partnerPayouts.length > 0 ) {
          const payoutsIds = state.partnerPayouts.map(payout => payout.id);
          state.partnerPayouts.push(...action.payload.payouts.filter((item) => !payoutsIds.includes(item.id)));
        } else {
          state.partnerPayouts = action.payload.payouts;
        }
      })
      .addCase(createPartnerPayout.fulfilled, (state, action) => {
        state.partnerPayouts.unshift(action.payload);
      })
      .addCase(createPartnerClient.fulfilled, (state, action: PayloadAction<typ.PartnerClient>) => {
        const newPartnerCompany: typ.PartnerCompany = {
          id: action.payload.id,
          name: action.payload.name,
          waba360DialogPartnerId: action.payload.waba360DialogPartnerId,
          partnerCompanyType: 'partner',
          wabaDialogsBalance: null,
          paidUntil: null,
          testUntil: null,
          pausedAt: null
        };
        state.partnerClientsList.push(newPartnerCompany);
      })
  }
});

export const {
  clearPartnerCompany, setPartnerNotificationNew, setPartnerTelegramChannelNew } = PartnerCabinetSlice.actions;

export default PartnerCabinetSlice.reducer;
