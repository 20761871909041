
export const introductionSteps = [
  'members', 'connections', 'integrations', 'chats', 'chatsCreate', 'help', 'support'
] as const;

type Styles = {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
  width?: number;
  transform?: string;
};

export type Introductions = typeof introductionSteps[number];
export type IntroductionStepConfig = {
  styles: Styles;
  tailPosition: Styles;
  path?: string;
};

export const introductionStepsConfig: Record<Introductions, IntroductionStepConfig> = {
  members: {
    styles: { top: 92, right: 246, width: 432 },
    tailPosition: { top: -12, right: 24, transform: 'rotate(180deg)' },
    path: '/settings/members'
  },
  connections: {
    styles: { top: 90, left: 240, width: 390 },
    tailPosition: { top: -12, left: 24, transform: 'rotate(180deg)' },
    path: '/settings/connections' },
  integrations: {
    styles: { top: 358, left: 240, width: 390 },
    tailPosition: { top: -12, left: 24, transform: 'rotate(180deg)' },
    path: '/settings/integrations' },
  chats: {
    styles: { top: 110, left: 15, width: 390 },
    tailPosition: { top: -12, left: 24, transform: 'rotate(180deg)' },
    path: '/chats' },
  chatsCreate: {
    styles: { top: 90, left: 578, width: 390 },
    tailPosition: { top: 24, left: -18, transform: 'rotateZ(90deg)' },
    path: '/chats' },
  help: {
    styles: { bottom: 115, left: 236, width: 390 },
    tailPosition: { bottom: 24, left: -18, transform: 'rotateZ(90deg)' }
  },
  support: {
    styles: { bottom: 2, left: 145, width: 390 },
    tailPosition: { bottom: 24, left: -18, transform: 'rotateZ(90deg)' }
  }
};

type Locales = 'ru' | 'en' | 'es';

type StepsAnchorsUris = Partial<Record<Introductions, Record<Locales, string>>>;

export const stepsAnchorsUris: StepsAnchorsUris = {
  members: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/polzovateli',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/users',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/usuarios',
  },
  connections: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/podklyucheniya',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/connections',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/conexiones',
  },
  integrations: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/integracii',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/integrations',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/integracion',
  },
  chats: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/chaty',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/chats',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/chat ',
  },
  chatsCreate: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/chaty/sozdanie-chata',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/chats/create-a-chat',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/chat/crear-chat',
  },
};
