import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNotificationsSocket } from "../../../../api/NotificationsProvider";
import { currentCompanySelector } from "../../../../api/companiesSelector";
import { fetchPartnerCompanyNotifications, setPartnerNotificationNew, setPartnerTelegramChannelNew } from "../../PartnerCabinetSlice";


export function useNotificationsSetup() {
  const dispatch = useDispatch();
  const socket = useNotificationsSocket();
  const company = useSelector(currentCompanySelector);

  useEffect(() => {

    const handler = (payload: { channel_id: number }) => {
      dispatch(setPartnerTelegramChannelNew(payload.channel_id));
      dispatch(setPartnerNotificationNew('fulfilled'));
      dispatch(fetchPartnerCompanyNotifications({ companyId: company.id }));
    };

    if (socket && company.isPartner) {
      socket.emit('subscribe_partner_notification_channels', { company_id: company.id });
      socket.on('new_partner_notification_channel', handler);
    }

    return () => {
      socket.emit('unsubscribe_partner_notification_channels', { company_id: company.id });
      socket.off('new_partner_notification_channel', handler);
    }
  }, [company, socket]);

}
