import './AddConnection.scss';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingSelector } from '../../../../api/companiesSelector';
import { updateOnboardingStep } from '../../../../api/companiesSlice';
import { CompanyOnboardingStep, CompanyOnboardingUpdate } from '../../../../api/types';
import { settingConnections } from '../../../../views/Settings/Connections/utils/connections';
import { selectConnection, SelectConnections } from '../../utils/arrays';
import { useUpdateStep } from '../../utils/onboarding';
import { bdAnchorsSupport } from "../../../../pages/Home/utils/consts";
import { isGuidedIntegration } from '../../utils/guides';
import { configForOnboardingSteps } from '../../utils/getStep';
import { YandexMetrikaCall } from "../../../../utils/utils";
import Button from '../../../../components/Button';
import GuidePanels from '../../GuidePanels';
import Select from '../../../../components/Select';
import MenuButtons from '../MenuButtons';


const AddConnection = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const onboarding = useSelector(onboardingSelector);
  const [connectionStep, setConnectionStep] = useState<CompanyOnboardingStep>();
  const [connection, setConnection] = useState<string>();
  const onSubmitOnboardingStep = useUpdateStep();
  const listConnections = selectConnection();

  useEffect(() => {
    const addConnection = onboarding?.steps.find((step) => step.type === 'add_connection');
    if (addConnection) {
      setConnectionStep(addConnection);
      setConnection(addConnection.result?.value);
    }
  }, [onboarding]);


  useEffect(() => {
    if (onboarding) {
      const addGuideComplite =
      configForOnboardingSteps.add_connection.isFinish(onboarding, 'add_connection') &&
      configForOnboardingSteps.setup_notifications.isFinish(onboarding, 'setup_notifications');
      if (!isGuidedIntegration(onboarding) && addGuideComplite) {
        onSubmitOnboardingStep({
          company_id: Number(url?.params.companyId),
          steps: [{
            is_finished: true,
            type: 'add_integration',
          }]
        });
      }
    }
  }, [onboarding, connection]);


  const onHelp = () => YandexMetrikaCall('reachGoal', 'onb-need-help-btn-clck');

  const onSelect = (connection: SelectConnections) => {
    setConnection(connection.name);
    if (connection.id !== connectionStep?.result?.value) dispatch(updateOnboardingStep({
      isFinished: false,
      type: 'add_connection',
      result: { value: connection.id }
    }));
    YandexMetrikaCall('reachGoal', 'onb-step-choose-connection');
  };

  const onPrevStep = () => {
    if (onboarding) {
      const prevResult = onboarding.steps.find((step) => step.type === 'choose_crm')?.result;

      if (prevResult) {
        const data: CompanyOnboardingUpdate = {
          company_id: Number(url?.params.companyId),
          steps: [
            {
              is_finished: false,
              type: 'choose_crm',
              result: { value: prevResult.value, ...(prevResult.value === 'other'
                  ? { custom_value: prevResult.customValue }
                  : {}) }
            }
          ]
        }
        onSubmitOnboardingStep(data);
      }
    }
  };

  return (
    <div className='addConnection'>
      <MenuButtons />
      {connectionStep?.isFinished
        ? <>
          <label>{t('company_onboarding.add_connection.choosen')}</label>
          <p className='regularText'>{t(settingConnections[connectionStep.result?.value ?? '']?.name ?? '')}</p>
        </>
        : <Select items={listConnections} caption={t('company_onboarding.add_connection.choose')} labelKey='name'
          placeholder={t('select')} onChoose={onSelect} disabled={connectionStep?.isFinished}
          initValue={connection && listConnections.find((item) => item.id === connection)?.name} />
      }
        {connection && <GuidePanels />}
        <div className='addConnection__buttons'>
          <Button color="white" textType="small" text={t('back')} onClick={onPrevStep} />
          <a href={bdAnchorsSupport[i18n.language]} target="_blank" rel="noreferrer">
            <Button color="white" textType="small" text={t('help')} onClick={onHelp} />
          </a>
        </div>
    </div>
  );
};

export default AddConnection;
