import { useEffect, useState } from 'react';
import './Onboarding.scss';
import { useSelector } from 'react-redux';
import { currentStepSelector } from './onboardingSelectors';
import {
  currentCompanySelector, getCurrentCompanyRoleSelector, profileSettingsInfo
} from '../../api/companiesSelector';
import IntroductionPopup from './IntroductionPopup';


const Onboarding = () => {
  const currentCompany = useSelector(currentCompanySelector);
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const profileSettings = useSelector(profileSettingsInfo);
  const currentStep = useSelector(currentStepSelector);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const isAdministrator = currentRole && currentRole.name === 'ADMINISTRATOR';

  useEffect(() => {
    setIsOwner(currentCompany && profileSettings && profileSettings.userId === currentCompany.ownerId);
  }, [profileSettings, currentCompany]);

  if (currentStep < 0 || (!isOwner && !isAdministrator)) return null;

  return (
    <div className="onboarding">
      <IntroductionPopup />
    </div>
  );
};

export default Onboarding;
