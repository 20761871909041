import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { introductionSteps } from './utils';

export const isFirstEntrySelector = (state: RootState) => state.onboarding.isFirstEntry;
export const currentStepSelector = (state: RootState) => state.onboarding.currentStep;

export const getCurrentIntroductionSelector = createSelector([currentStepSelector], (step) => {  
  if (step < 1) return '';
  return introductionSteps[step - 1];
});
