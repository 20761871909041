import './Connections.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { onboardingGuideSelector } from '../../../../../api/companiesSelector';
import ConnectionItems from './ConnectionItems';
import GuideWrapper from '../../../../../components/Guide/GuideWrapper';


function Connections() {
  const { t } = useTranslation();
  const onboardingGuideOn = useSelector(onboardingGuideSelector);

  return (
    <div className={`connectionsContainer ${onboardingGuideOn ? 'guideOn' : ''}`}>
      <GuideWrapper />
      <h1>{t('settings.settings_list.connections')}</h1>
      <div className="connectionsContent">
        <h3>{t('connections.online-chats')}</h3>
        <ConnectionItems serviceTypes={['mobile', 'social', 'bot']} />
        <h3>{t('connections.banks-payments')}</h3>
        <ConnectionItems serviceTypes={['banking']} />
      </div>
    </div>
  );
}

export default Connections;
