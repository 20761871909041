import { MethodsPaymentType } from './components/SubscriptionsInvoicePay/helper';
import { CurrenciesType } from '../CompanySettings/currency';


const subscriptionServices = [
  'whatsapp', 'waba', 'bank', 'telegram', 'telegram_bot', 'odnoklassniki',
  'tinkoff', 'modulbank', 'sberbank', 'paykeeper', 'paypal', 'bepaid', 'yookassa'
] as const; // , 'payselection'

export const subscriptionPlans = ['FREE', 'START', 'STANDARD', 'ADVANCED'] as const;

export type SubscriptionPlanType = typeof subscriptionPlans[number];

export type SubscriptionServiceType = typeof subscriptionServices[number];

type UServiceType<Str extends string> = `CONNECTION_${Uppercase<Str>}`;

export type SubscriptionType = UServiceType<SubscriptionServiceType>;

export const subscriptionServiceMap = Object.fromEntries(subscriptionServices.map((item) => [
  `CONNECTION_${item.toUpperCase()}`, item]));

export type SubscriptionItem = 'SUBSCRIPTION';
export type WabaDialogItem = 'WABA_DIALOGS';

export const invoiceItemTypes: Record<SubscriptionItem | WabaDialogItem, string> = {
  SUBSCRIPTION: 'subscription',
  WABA_DIALOGS: 'dialog'
};

export function checkItemSubscription(
  item: SubscriptionInvoiceItem | SubscriptionInvoiceDialog): item is SubscriptionInvoiceItem {
  return item && (item as SubscriptionInvoiceItem).type === 'SUBSCRIPTION';

}

export function checkItemDialog
(item: SubscriptionInvoiceItem | SubscriptionInvoiceDialog): item is SubscriptionInvoiceDialog {
  return item && (item as SubscriptionInvoiceDialog).type === 'WABA_DIALOGS';

}

export type Subscription = {
  id: number;
  connections: number[];
  subscriptionType: SubscriptionType;
  subscriptionPlan: SubscriptionPlanType;
  currentSize: number;
  maxSize: number;
  testUntil: string;
  paidUntil: string;
  pausedAt: string | null;
};

export type PatchSubscription = {
  connections: number[];
  preview: boolean;
};

export type PaymentRequisite = {
  id: number;
  tin: string;
  name: string;
  approvalStatus: 'APPROVED' | 'DECLINED' | 'PENDING';
};

export type PostPaymentRequisite = {
  company_id: number;
  tin: string;
  name: string;
};

export type UpdatePaymentRequisite = {
  requisite_id: number;
  company_id: number;
  tin: string;
  name: string;
};

export type SubscriptionInvoiceItem = {
  type: SubscriptionItem;
  subscriptionId: number;
  oldSize: number;
  newSize: number;
  oldSubscriptionPlan: SubscriptionPlanType;
  newSubscriptionPlan: SubscriptionPlanType;
  oldPaidUntil: string;
  discounts: {
    periodDiscountPercent: number;
    quantityDiscountPercent: number;
    promoDiscountPercent: number;
    partnerDiscountPercent: number;
    testPeriodDiscountPercent: number;
  };
  topUpAmount: {
    amount: number;
    discount: number;
  };
  upgradeAmount: {
    amount: number;
    discount: number;
  };
};

export type SubscriptionInvoiceDialog = {
  type: WabaDialogItem;
  count: number;
  price: number;
};

export type SubscriptionReceiptItem = {
  name: string;
  quantity: number;
  price: number;
};

export type UpsellRecommendation = {
  type: WabaDialogItem;
  value: {
    previousCount: number;
    recommendedCount: number;
  };
};

export type ProductType = {
  connectionId: number;
  amount: number;
  discount: number;
};

export type SubscriptionInvoice = {
  id: number;
  preview: boolean;
  status: string;
  lifetime: string;
  summary: {
    items: Array<SubscriptionInvoiceItem | SubscriptionInvoiceDialog>;
    periodMonths: number;
    paymentMethod: MethodsPaymentType;
    promoCode: string;
    totalDiscount: number;
    partnerDiscountPercent: number;
    companyRequisite: {
      companyRequisiteId: number;
      partnerRequisiteId: number;
      tin: string;
      name: string;
      approvalStatus: string;
    };
  };
  amount: number;
  currency: CurrenciesType;
  paymentUrl: string;
  previewUrl: string;
  actUrl: string;
  receipt: {
    items: SubscriptionReceiptItem[];
  };
  createdAt: string;
};

export type SubscriptionDialogs = {
  type: WabaDialogItem;
  value: number;
};

export type SubscriptionInvoiceRequestItem = {
  type: SubscriptionItem;
  id: number;
  new_subscription_plan: SubscriptionPlanType;
  new_size: number;
};

export type SubscriptionInvoiceRequestDialog = {
  type: WabaDialogItem;
  count: number;
};

export type PostSubscriptionInvoice = {
  company_id: number;
  preview: boolean;
  items: Array<SubscriptionInvoiceRequestItem | SubscriptionInvoiceRequestDialog>;
  payment_method: MethodsPaymentType;
  period_months?: number;
  company_requisite_id?: number;
  promo_code?: string;
};

export type SubscriptionsInvoicesRequest = {
  companyId: number;
  limit?: number;
  offset?: number;
};

export type SubscriptionDialogsRequest = {
  companyId: number;
};
