import React, { useContext, useState } from 'react';
import './SubscriptionCard.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../../../api/companiesSelector';
import { SubscriptionStatusEnum } from '../../types';
import { selectContext } from "../../SubscriptionContext";
import { subcriptionCardAddon } from "./subcriptionCardAddon";
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import UserSubscription from '../../models/UserSubscription';
import Checkbox from '../../../../../components/Checkbox';
import EditForm from '../EditForm';
import { ReactComponent as SnowIcon } from '../../../../../assets/snowflake.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/edit.svg';


const frozenStyle: React.CSSProperties = {
  backgroundImage: "url(/frozen.jpg)",
  backgroundSize: "cover",
  backgroundOrigin: "border-box"
};

type Props = {
  subscription: UserSubscription;
  children?: React.ReactNode;
};

const SubscriptionCard = ({ subscription, children }: Props) => {
  const { t, i18n } = useTranslation();
  const { checkSelected } = useContext(selectContext);
  const currentCompany = useSelector(currentCompanySelector);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const formatSubscriptionStatus = () => {
    const statusDescription = t(`subscriptions.card.status.${subscription.statusName}`);
    return `${statusDescription} ${subscription.formatSubscriptionDateStatus(i18n.language)}`;
  };

  const onSelectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    subscription.selected = event.target.checked;
    checkSelected();
  };

  const onEdit = () => {
    setIsEdit(!isEdit);
  };

  const checkSelectEnable = (status: SubscriptionStatusEnum) =>
    currentCompany.paymentsLocked || status === SubscriptionStatusEnum.paused;

  return (
    <div className="subscription-card"
      style={subscription.status === SubscriptionStatusEnum.paused ? frozenStyle : undefined}>
      {subscription.hadTariff && (
        <div className="subscription-card__select">
          <Checkbox
            name={String(subscription.id)}
            value={subscription.selected}
            onCheckedHandler={onSelectHandler}
            disabled={checkSelectEnable(subscription.status)} />
        </div>
      )}
      <div className='subscription-card__content'>
        <div className="subscription-card__header">
          <p className="subscription-card__title">{t(`subscriptions.types.${subscription.type}`)}</p>
          {!isEdit && <p className="subscription-card__connection regularText">
            {`${t(`subscriptions.card.connections.${subscription.serviceType}`)}${subscription.currentSize}`}
          </p>}
        </div>
        {isEdit && <EditForm editSubscription={subscription} setIsOpen={setIsEdit} />}
        {subscription.status === SubscriptionStatusEnum.paused && (
          <div className="subscription-card__icon"><SnowIcon /></div>
        )}
        {!isEdit
          ? (<>
            {children}
            {subscription.hadTariff && (
              <div className='subscription-card__tariff smallText'>
                {t('subscriptions.card.plan')}: {t(`subscriptions.plans.${subscription.plan}`)}
              </div>
            )}
            {subscription.hadTariff && (
              <div className={`subscription-card__status smallText ${subscription.statusName}`}>
                {formatSubscriptionStatus()}
              </div>
            )}
            <WrapperPermission permission='subscriptionsEdit'>
              <div className='subscription-card__buttonsGroup'>
                <button type='button' onClick={onEdit}>
                  <EditIcon /><span className='smallText'>{t('change')}</span>
                </button>
              </div>
            </WrapperPermission>
          </>)
          : null}
      </div>
    </div>
  );
};

type TypedProps = {
  subscription: UserSubscription;
};

const SubscriptionCardTyped = ({ subscription }: TypedProps) => {
  const Addon = subcriptionCardAddon[subscription.subscription.subscriptionType];
  return (
    <SubscriptionCard subscription={subscription}>
      {Addon ? <Addon subscription={subscription} /> : null}
    </SubscriptionCard>
  );
}

export default SubscriptionCardTyped;
