import { useRef } from 'react';
import './LinkItem.scss';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { getIconNavbar, getViewPopup } from "../../utils";
import PopupTitle from "../PopupTitle/PopupTitle";


type LinkProps = {
  location: string;
  isRolledUp?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

const LinkItem = ({ location, isRolledUp, setIsOpen }: LinkProps) => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const currentTab = useRouteMatch<{ tab: string; }>('/companies/:companyId/:tab');
  const refDiv = useRef<HTMLDivElement>(null);
  const { viewPopup, onMouseLeave, onMouseEnter } = getViewPopup();
  const isActive = currentTab?.params.tab === location;
  const Icon = getIconNavbar[location];

  const onSelectTab = (e: React.MouseEvent<HTMLDivElement>) => {
    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  return (
    <Link to={`${url?.url}/${location}`}>
      <div className={`tab ${isActive ? 'active' : ''} ${isRolledUp ? 'rolledUp' : ''}`}
        id={location} ref={refDiv} onClick={onSelectTab}>
        {isRolledUp ? <PopupTitle text={t(`navbar.${location}`)}
          image={<Icon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />}
          refPopup={refDiv} viewPopup={viewPopup} />
          : <Icon />}
        {!isRolledUp && <p>{t(`navbar.${location}`)}</p>}
      </div>
    </Link>
  );
};

export default LinkItem;
