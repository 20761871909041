import { FC, useRef, useState } from 'react';
import './User.scss';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store/store';
import { removeMember } from '../../../../api/companiesSlice';
import { getRolesCompanySelector, profileSettingsInfo } from '../../../../api/companiesSelector';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import { RoleType, UsersType } from '../../../../api/types';
import { useRole } from './useRole';
import UserEdit from '../UserEdit/UserEdit';
import ActionDialog from '../../../../components/Modals/ActionDialog';
import CardButtons from '../../../../components/CardButtons';


type Props = {
  user: UsersType;
  isRoleEditable: UsersType | null;
  onCloseEditCard: () => void;
  activeSetIsRoleEditable: (user: UsersType) => void;
};

const User: FC<Props> = ({ user, isRoleEditable, onCloseEditCard, activeSetIsRoleEditable }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const roles = useSelector(getRolesCompanySelector).filter(role => role.name !== 'PARTNER');
  const { userId: currentUserId } = useSelector(profileSettingsInfo);
  const cardRef = useRef<HTMLDivElement>(null);
  const [deleteMember, setDeleteMember] = useState<UsersType | null>(null);
  const { isOwner, roleType, roleName } = useRole(user);
  const showActions = !isOwner && user.userId !== currentUserId && roleType !== 'partner';

  const handlerCloseDeleteMember = () => {
    setDeleteMember(null);
  };

  const submitDeleteMember = () => {
    if (currentCompanyId?.params.companyId) dispatch(
      removeMember({
        companyId: Number(currentCompanyId?.params.companyId),
        memberId: user.id,
      })
    ).then(r => {
      if (r.meta.requestStatus === 'fulfilled') dispatch(
        addAlertWithCustomText({ message: t('settings.users.user.delete_success'), type: 'info' }));
    });
  };

  const activeDeleteMember = () => {
    setDeleteMember(user);
  };

  const closeEditForm = () => {
    if (cardRef.current) cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    onCloseEditCard();
  };

  const initialStateCurrentRoleEdit: RoleType | undefined = roles.find(r => r.id === user.roleId);

  return (
    <div ref={cardRef} className='user'>
      <div className='user__info'>
        {!(isRoleEditable && isRoleEditable?.id === user.id) &&
          <div className={`user__role smallText ${roleType}`}>{roleName}</div>
        }
        <p className="regularText semibold">{user.displayName}</p>
      </div>
      <p className='regularText'>{user.email}</p>
      {isRoleEditable && isRoleEditable?.id === user.id && initialStateCurrentRoleEdit
        ? <UserEdit isOwner={isOwner} roles={roles} user={isRoleEditable}
            onCloseEditCard={closeEditForm} initialStateCurrentRoleEdit={initialStateCurrentRoleEdit} />
        : showActions &&
            <CardButtons deletePermission='membersDelete' onDelete={activeDeleteMember}
              editPermission='membersEdit' onEdit={() => activeSetIsRoleEditable(user)} />   
      }
      {deleteMember &&
        <ActionDialog action={t('settings.users.user.delete_user')} cancel={t('cancel')} background='light'
          title={t('settings.users.user.delete_title')}
          message={t('settings.users.user.delete_message', { user: deleteMember?.displayName })}
          onAction={submitDeleteMember} setIsOpen={handlerCloseDeleteMember} />
      }
    </div>
  );
};

export default User;
