import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { profileSettingsInfo } from '../../../api/companiesSelector';
import { updateFirstLogin } from '../../../api/companiesSlice';
import Button from '../../../components/Button';
import { cancelOnboarding, incrementStep } from '../onboardingSlice';
import './Introduction.scss';


function Introduction() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileSettings = useSelector(profileSettingsInfo);

  const onStartOnboarding = () => {
    dispatch(incrementStep());
  };

  const onCancelOnboarding = () => {
    dispatch(cancelOnboarding());
    if (profileSettings && profileSettings.firstLogin) {
      dispatch(updateFirstLogin());
    }
  };

  return (
    <div className="introduction">
      <h3>{t('onboarding.introduction.title')}</h3>
      <p className="smallText">{t('onboarding.introduction.description')}</p>
      <div className="introduction__buttons">
        <Button type='button' color='orange' textType='small' text={t('onboarding.introduction.start_button')}
          onClick={onStartOnboarding} />
        <Button type='button' color='white' textType='small' text={t('onboarding.introduction.cancel_button')}
          onClick={onCancelOnboarding} />
      </div>
    </div>
  );
}

export default Introduction;
