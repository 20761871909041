import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import './Dropdown.scss';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-down.svg';
import { ReactComponent as DeleteIcon } from '../../assets/trash.svg';
import { useOutsideClick } from '../utils/hooks';


type Props<T> = {
  label: string;
  items: T[];
  labelKey: Extract<keyof T, string>;
  valueKey: keyof T;
  name: string;
  placeholder?: string;
  callback?: (value: T) => void;
  type?: string;
  disabled?: boolean,
  // eslint-disable-next-line
  removeCallback?: (e: any, value: any) => void;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

type StylePosition = {
  top: number;
};

const CONTENT_MARGIN = 8;

const Dropdown = <T extends {
  id: string | number | null;
  custom?: boolean;
  logo?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
}>(
  {
    items,
    label,
    name,
    labelKey,
    valueKey,
    placeholder,
    callback,
    type,
    removeCallback,
    disabled = false,
    ...props
  }: Props<T>): JSX.Element => {
  const { setValue, getValues, register, formState, clearErrors } = useFormContext();
  const [selectedItem, setSelectedItem] = useState<T>();
  const [isOpened, setIsOpened] = useState(false);
  const [stylePosition, setStylePosition] = useState<StylePosition>();
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const isError = formState.errors[name];

  useOutsideClick(contentRef, () => setIsOpened(false), triggerRef);

  useEffect(() => {
    const currentValue = getValues(name);

    if (currentValue === undefined && Array.isArray(currentValue)) {
      return;
    }

    if (currentValue !== undefined) {
      const item = items.find((itemNext) => {
        if (valueKey) {
          return itemNext[valueKey] === currentValue;
        }
        return itemNext === currentValue;
      });
      setSelectedItem(item);
    } else {
      setSelectedItem(undefined);
    }

  }, [formState]);

  useLayoutEffect(() => {
    if (triggerRef.current && contentRef.current) {
      let top;

      if (window.innerHeight - triggerRef.current.getBoundingClientRect().bottom < contentRef.current.offsetHeight) {
        top = -(contentRef.current.offsetHeight + CONTENT_MARGIN);
      } else top = triggerRef.current.offsetHeight + CONTENT_MARGIN;

      setStylePosition({ top });
    }
  }, [isOpened]);

  const onTriggerDropdown = () => {
    if (items.length > 0 && !disabled) {
      setIsOpened(!isOpened);
    }
  };

  const onSelect = (e: React.MouseEvent<HTMLDivElement>, item: T) => {
    const value = valueKey ? item[valueKey] : item;
    e.stopPropagation();
    clearErrors(name);
    setSelectedItem(item);
    if (callback) {
      callback(item);
    } else {
      setValue(name, value, { shouldDirty: true });
    }
    setIsOpened(!isOpened);
  };

  const getLogoStyled = (item: T, styleClass: string) => {
    const Logo = item.logo;
    if (Logo) {
      return <Logo className={styleClass} />;
    }
    return null;
  };

  const getItemLogo = (item: T) => getLogoStyled(item, 'dropdownContainer__dropdown_content__item_logo');

  const getPlaceholderItemStyled = () => {
    if (selectedItem) {
      const Logo = getLogoStyled(selectedItem, 'dropdownContainer__dropdown_logo');
      return (
        <>
          {Logo}
          <p className={`dropdownContainer__dropdown_placeholder selected ${Logo ? 'logo' : ''}`}>
            {selectedItem[labelKey]}
          </p>
        </>
      );
    }
    return null;
  };

  const getPlaceholderEmptyStyled = () => {
    let placeholderText: string | T[Extract<keyof T, string>];
    if (placeholder) {
      placeholderText = placeholder;
    } else {
      placeholderText = items[0][labelKey];
    }
    return <p className='dropdownContainer__dropdown_placeholder'>{placeholderText}</p>;
  };

  const getPlaceholder = () => {
    if (selectedItem) {
      return String(selectedItem[labelKey]);
    }
    if (placeholder) {
      return placeholder;
    }
    return String(items[0][labelKey]);
  };

  return (
    <div className='dropdownContainer' {...props}>
      {label && <p className='dropdownContainer__label'>{label}</p>}
      <div className={`dropdownContainer__dropdown ${isError ? 'error' : ''}`}
        onClick={onTriggerDropdown} ref={triggerRef}>
        {selectedItem ? getPlaceholderItemStyled() : getPlaceholderEmptyStyled()}
        <input {...register(name)} type='text' placeholder={getPlaceholder()} />
        <div className={`dropdownContainer__dropdown_arrowIcon ${isOpened && 'opened'}`}>
          <ArrowIcon />
        </div>
        {items.length > 0 && (
          <div className="dropdownContainer__dropdown_content"
            style={{ ...stylePosition, display: isOpened ? 'flex' : 'none' }} ref={contentRef}>
            {items.map((item) => (
              <div className='dropdownContainer__dropdown_content__item'
                key={item.id} onClick={(e) => onSelect(e, item)}>
                {getItemLogo(item)}
                {labelKey ? item[labelKey] : item}
                {type && type === 'paypal' && item.custom && (
                  <DeleteIcon className='dropdownContainer__dropdown_content__item_trashIcon'
                    onClick={removeCallback ? (e) => removeCallback(e, item) : undefined} />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {isError &&
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <p className='dropdownContainer__error'>{message}</p>}
        />
      }
    </div>
  );
};

export default Dropdown;
