import axiosInstance from '../../api/AxiosInstance';


export type ProductsListRequest = {
  companyId: number;
  catalogId?: number;
  offset?: number;
  limit?: number;
  query?: string;
};

export type PricesProduct = {
  currency: string;
  price: number;
};

export type ProductsList = {
  count: number;
  totalCount: number;
  items: Product[];
};

export type Product = {
  id: number;
  name: string;
  description: string;
  paymentObjectType: string;
  category: string;
  vat: string;
  sku: string;
  images: string[];
  prices: PricesProduct[];
  catalogIds: number[];
};

export interface ProductCreateRequest {
  companyId: number;
  name: string;
  description?: string;
  payment_object_type: string;
  category?: string;
  vat: string;
  sku?: string;
  images?: string[];
  prices: PricesProduct[];
}

export interface ProductEditRequest {
  companyId: number;
  productId: number;
  name: string;
  description?: string;
  payment_object_type: string;
  category?: string;
  vat: string;
  sku?: string;
  images?: string[];
  prices: PricesProduct[];
}

export interface ProductRequest {
  productId: number;
  companyId: number;
}

export interface ProductToCatalogsRequest {
  productId: number;
  companyId: number;
  catalog_ids: number[];
}

export interface ProductImageRequest {
  companyId: number;
  file: File;
}

export interface ICatalog {
  id: number;
  name: string;
  currency: string;
  productsCount: number;
}

export interface ICatalogList {
  count: number;
  items: ICatalog[];
}

export interface ICatalogListRequest {
  companyId: number;
  productId?: number;
  offset?: number;
  limit?: number;
}

export interface ICatalogCreateRequest {
  companyId: number;
  name: string;
  currency: string;
}

export interface ICatalogRequest {
  companyId: number;
  catalogId: number;
}

export interface ICatalogEditRequest extends ICatalogRequest {
  name: string;
  currency: string;
}

export interface ICatalogProductsRequest extends ICatalogRequest {
  products: number[];
}

export const getCatalogList = async (requestOptions: ICatalogListRequest): Promise<ICatalogList> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/sales/catalogs/`, {
    params: {
      product_id: requestOptions.productId,
      offset: requestOptions.offset,
      limit: requestOptions.limit
    }
  });

export const createCatalog = async (requestOptions: ICatalogCreateRequest): Promise<ICatalog> => {
  const { companyId, ...rest } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/sales/catalogs/`, { ...rest });
};

export const getCatalog = async (requestOptions: ICatalogRequest): Promise<ICatalog> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/sales/catalogs/${requestOptions.catalogId}`);

export const deleteCatalog = async (requestOptions: ICatalogRequest): Promise<void> =>
  await axiosInstance.delete(`/companies/${requestOptions.companyId}/sales/catalogs/${requestOptions.catalogId}`);

export const updateCatalog = async (requestOptions: ICatalogRequest): Promise<ICatalog> => {
  const { companyId, catalogId, ...rest } = requestOptions;
  return await axiosInstance.patch(`/companies/${companyId}/sales/catalogs/${catalogId}`, { ...rest });
};

export const deleteProductsCatalog = async (requestOptions: ICatalogProductsRequest): Promise<ICatalog> => {
  const { companyId, catalogId, products } = requestOptions;
  return await axiosInstance.delete(`/companies/${companyId}/sales/catalogs/${catalogId}/delete_products`,
    { data: { product_ids: products } });
};

export const addProductsCatalog = async (requestOptions: ICatalogProductsRequest): Promise<ICatalog> => {
  const { companyId, catalogId, products } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/sales/catalogs/${catalogId}/add_products`,
    { product_ids: products });
};

export const getProductsList =
  async (requestOptions: ProductsListRequest, signal?: AbortSignal): Promise<ProductsList> =>
  await axiosInstance.get(`companies/${requestOptions.companyId}/sales/products/`, {
    params: {
      catalog_id: requestOptions.catalogId,
      offset: requestOptions.offset,
      limit: requestOptions.limit,
      query: requestOptions.query
    },
    signal
  });

export const createProduct = async (requestOptions: ProductCreateRequest): Promise<Product> => {
  const { companyId, ...rest } = requestOptions;
  return await axiosInstance.post(`/companies/${companyId}/sales/products/`, { ...rest });
};

export const getProduct = async (requestOptions: ProductRequest): Promise<Product> =>
  await axiosInstance.get(`/companies/${requestOptions.companyId}/sales/products/${requestOptions.productId}`);

export const deleteProduct = async (requestOptions: ProductRequest): Promise<void> =>
  await axiosInstance.delete(`/companies/${requestOptions.companyId}/sales/products/${requestOptions.productId}`);

export const updateProduct = async (requestOptions: ProductEditRequest): Promise<Product> => {
  const { companyId, productId, ...rest } = requestOptions;
  return await axiosInstance.patch(`/companies/${companyId}/sales/products/${productId}`, { ...rest });
};

export const uploadProductImage = async (requestOptions: ProductImageRequest): Promise<{ url: string; }> => {
  const formData = new FormData();
  formData.append('file', requestOptions.file);
  return await axiosInstance.post(`/companies/${requestOptions.companyId}/files/products/images/`, formData);
};

export const updateProductToCatalogs = async (requestOptions: ProductToCatalogsRequest):
  Promise<Product> => {
  const { companyId, productId, ...rest } = requestOptions;
  return await axiosInstance.put(`/companies/${companyId}/sales/products/${productId}/catalogs/`, { ...rest });
};
