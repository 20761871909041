import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WabaConnection } from "../../api/connections/types";
import { getMessageTemplates, getWabaTemplates } from '../../api/templates/endpoints';
import { MessageTemplate, WabaTemplate } from '../../api/templates/types';
import { getConnectionsList } from '../Settings/Connections/ConnectionsAPI';
import * as api from './templatesAPI';
import * as typ from './templatesTypes';
import { parserWabaErrorMessage } from './utils/parser';


type TemplatesSlice = {
  messageTemplates: MessageTemplate[];
  interactiveMessageTemplates: MessageTemplate[];
  wabaTemplates: WabaTemplate[];
  wabaListTemplates: typ.WabaTemplateInfo[];
  wabaConnections: WabaConnection[];
  wabaListLoading: boolean;
};

const initialState: TemplatesSlice = {
  messageTemplates: [],
  interactiveMessageTemplates: [],
  wabaTemplates: [],
  wabaListTemplates: [],
  wabaConnections: [],
  wabaListLoading: false
};

export const fetchMessageTemplates = createAsyncThunk(
  'templates/getMessageTemplates',
  async (requestOptions: { companyId: number }) => await getMessageTemplates(requestOptions)
);

export const fetchWabaTemplates = createAsyncThunk<WabaTemplate[],
  {companyId: number, connectionId: number },
  { rejectValue: number }>('templates/getWabaTemplates', async (requestOptions, { rejectWithValue }) => {
  try {
    return await getWabaTemplates(requestOptions);
  } catch (e) {
    return rejectWithValue(e.response.data.error_code);
  }
});

export const editMessageTemplate = createAsyncThunk(
  'templates/editMessageTemplate',
  async (requestOptions: typ.PatchMessageTemplateRequestOptions) => await api.patchMessageTemplate(requestOptions)
);

export const createMessageTemplate = createAsyncThunk(
  'templates/createMessageTemplate',
  async (requestOptions: typ.CreateMessageTemplateRequestOptions) => await api.postMessageTemplate(requestOptions)
);

export const createInteractiveTemplate = createAsyncThunk(
  'templates/createInteractiveTemplate',
  async (requestOptions: typ.CreateInteractiveTemplateRequestData) => await api.postInteractiveTemplate(requestOptions)
);

export const editInteractiveTemplate = createAsyncThunk(
  'templates/editInteractiveTemplate',
  async (requestOptions: typ.CreateInteractiveTemplateRequestData & { templateId: number }) =>
    await api.patchInteractiveTemplate(requestOptions, requestOptions.templateId)
);

export const removeCustomTemplate = createAsyncThunk(
  'templates/removeCustomTemplate',
  async (requestOptions:{ companyId: number, templateId: number }, { dispatch }) => {
    await api.deleteMessageTemplate(requestOptions);
    dispatch(removeTemplate(requestOptions.templateId));
  }
);

export const fetchListWabaTemplates = createAsyncThunk<typ.CreateWabaTemplateResponse,
  { companyId: number; connectionId: number }, { rejectValue: string | number }>(
  'templates/getListWabaTemplates',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.getListWabaTemplates(requestOptions);
    } catch (e) {
      let message = '';
      if (e.response.data.details?.message) {
        message = e.response.data.details.message;
      } else {
        message = e.response.data.error_code;
      }
      return rejectWithValue(message);
    }
  }
);

export const createWabaTemplate = createAsyncThunk<typ.WabaTemplateInfo,
  typ.CreateWabaTemplateRequest, { rejectValue: string | number }>(
  'templates/createWabaTemplate',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.postWabaTemplate(requestOptions);
    } catch (e) {
      let message = '';
      if (e.response.data.error_code === 7001 && e.response.data.detail?.message) {
        message = parserWabaErrorMessage(e.response.data.detail.message) ?? e.response.data.error_code;
      } else {
        message = e.response.data.error_code;
      }
      return rejectWithValue(message);
    }
  }
);

export const deleteWabaTemplate = createAsyncThunk<unknown, typ.DeleteWabaTemplateRequest,
  { rejectValue: string | number }>(
  'templates/deleteWabaTemplate',
  async (requestOptions, { rejectWithValue }) => {
    try {
      await api.postDeleteWabaTemplate(requestOptions);
    } catch (e) {
      let message = '';
      if (e.response.data.error_code === 7001 && e.response.data.detail?.message) {
        message = parserWabaErrorMessage(e.response.data.detail.message) ?? e.response.data.error_code;
      } else {
        message = e.response.data.error_code;
      }
      return rejectWithValue(message);
    }
  }
);

export const fetchWabaConnections = createAsyncThunk<WabaConnection[], {companyId: number}, { rejectValue: number }>(
  'templates/getWabaConnections',
  async (requestOptions, { rejectWithValue }) => {
    try {
      const connections = await getConnectionsList({ companyId: requestOptions.companyId, types: 'waba' });
      return connections.connections as WabaConnection[];
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const updateWabaTemplate = 
  createAsyncThunk<typ.WabaTemplateInfo, typ.UpdateWabaTemplateRequest, { rejectValue: number }>(
  'templates/updateWabaTemplateFile',
  async (requestOptions, { rejectWithValue }) => {
    try {
      return await api.patchWabaTemplate(requestOptions);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    removeTemplate: (state, action: PayloadAction<number>) => {
      const customTemplateIdx = state.messageTemplates.findIndex((template) => template.id === action.payload);
      const interactiveTemplateIdx = state.interactiveMessageTemplates.findIndex(
        (template) => template.id === action.payload
      );
      if (customTemplateIdx !== -1) {
        state.messageTemplates.splice(customTemplateIdx, 1);
      }
      if (interactiveTemplateIdx !== -1) {
        state.interactiveMessageTemplates.splice(interactiveTemplateIdx, 1);
      }
    },
    removeWabaTemplate: (state, action: PayloadAction<string>) => {
      const wabaTemplateIdx = state.wabaListTemplates.findIndex((template) => template.name === action.payload);
      if (wabaTemplateIdx !== -1) {
        state.wabaTemplates.splice(wabaTemplateIdx, 1);
      }
    },
    clearTemplates: (state) => {
      state.messageTemplates = [];
      state.interactiveMessageTemplates = [];
      state.wabaTemplates = [];
      state.wabaListTemplates = [];
      state.wabaConnections = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessageTemplates.fulfilled, (state, action) => {
        const templates = action.payload.reverse();
        state.interactiveMessageTemplates = templates.filter((template) => template.type === 'waba_interactive');
        state.messageTemplates = templates.filter((template) => template.type !== 'waba_interactive');
      })
      .addCase(fetchWabaTemplates.fulfilled, (state, action) => {
        state.wabaTemplates = action.payload;
      })
      .addCase(fetchWabaTemplates.rejected, (state) => {
        state.wabaTemplates = [];
      })
      .addCase(editMessageTemplate.fulfilled, (state, action: PayloadAction<MessageTemplate>) => {
        const customTemplateIdx = state.messageTemplates.findIndex((template) => template.id === action.payload.id);
        state.messageTemplates[customTemplateIdx] = { ...action.payload };
      })
      .addCase(createMessageTemplate.fulfilled, (state, action) => {
        state.messageTemplates.unshift(action.payload);
      })
      .addCase(createInteractiveTemplate.fulfilled, (state, action) => {
        state.interactiveMessageTemplates.unshift(action.payload);
      })
      .addCase(editInteractiveTemplate.fulfilled, (state, action) => {
        const editedTemplateId = state.interactiveMessageTemplates.findIndex(
          (template) => template.id === action.payload.id
        );
        if (editedTemplateId !== -1) {
          state.interactiveMessageTemplates[editedTemplateId] = action.payload;
        }
      })
      .addCase(fetchListWabaTemplates.fulfilled, (state, action: PayloadAction<typ.CreateWabaTemplateResponse>) => {
        state.wabaListTemplates = action.payload.wabaTemplates;
        state.wabaListLoading = false;
      })
      .addCase(fetchListWabaTemplates.pending, (state) => {
        state.wabaListTemplates = [];
        state.wabaListLoading = true;
      })
      .addCase(fetchListWabaTemplates.rejected, (state) => {
        state.wabaListLoading = false;
      })
      .addCase(createWabaTemplate.fulfilled, (state, action: PayloadAction<typ.WabaTemplateInfo>) => {
        state.wabaListTemplates.push(action.payload);
        state.wabaListLoading = false;
      })
      .addCase(createWabaTemplate.pending, (state) => {
        state.wabaListLoading = true;
      })
      .addCase(createWabaTemplate.rejected, (state) => {
        state.wabaListLoading = false;
      })
      .addCase(deleteWabaTemplate.fulfilled, (state, action) => {
        state.wabaListTemplates = state.wabaListTemplates
          .filter((template) => template.name !== action.meta.arg.template_name);
      })
      .addCase(fetchWabaConnections.fulfilled, (state, action) => {
        state.wabaConnections = action.payload;
      })
      .addCase(updateWabaTemplate.fulfilled, (state, action) => {
        const wabaTemplateIdx = state.wabaListTemplates.findIndex(
          (template) => template.name === action.meta.arg.template_name);
        if (wabaTemplateIdx !== -1) {
          state.wabaListTemplates[wabaTemplateIdx] = action.payload;
        }
      });
  },
});

export const { removeTemplate, removeWabaTemplate, clearTemplates } = templatesSlice.actions;

export default templatesSlice.reducer;
