import './IntroductionPopup.scss';
import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { updateFirstLogin } from '../../../api/companiesSlice';
import { profileSettingsInfo } from '../../../api/companiesSelector';
import { cancelOnboarding } from '../onboardingSlice';
import { currentStepSelector, getCurrentIntroductionSelector } from '../onboardingSelectors';
import { introductionStepsConfig } from '../utils';
import { ReactComponent as TailIcon } from '../../../assets/dropdown-after-triangle.svg';
import { ReactComponent as CloseIcon } from '../../../assets/cross.svg';
import Introduction from '../Introduction/Introduction';
import IntroductionStep from '../IntroductionStep';


function IntroductionPopup() {
  const { push } = useHistory();
  const url = useRouteMatch('/companies/:companyId');
  const dispatch = useDispatch();
  const currentStep = useSelector(currentStepSelector);
  const introduction = useSelector(getCurrentIntroductionSelector);
  const profileSettings = useSelector(profileSettingsInfo);

  if (currentStep < 0) return null;

  useEffect(() => {
    if (!introduction) return;
    const path = introductionStepsConfig[introduction].path;
    if (url && path) push(`${url.url}${path}`);
  }, [introduction]);

  const onCloseOnboarding = () => {
    dispatch(cancelOnboarding());
    if (profileSettings && profileSettings.firstLogin) {
      dispatch(updateFirstLogin());
    }
  };

  const getStyles = () => {
    if (currentStep === 0) return { top: 30, right: 32, width: 432 };
    if (introduction) return introductionStepsConfig[introduction].styles;
  };

  const getTail = () => {
    if (currentStep === 0) return { top: 0 };
    if (introduction) return introductionStepsConfig[introduction].tailPosition;
  };

  return (
    <div className='introductionPopup' style={getStyles()}>
      {currentStep === 0
        ? <Introduction />
        : <IntroductionStep />
      }
      <CloseIcon className="closeIcon" onClick={onCloseOnboarding} />
      <TailIcon className="tailIcon" style={getTail()} />
    </div>
  );
}

export default IntroductionPopup;
