import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../../store/store";
import { currentCompanySelector } from "../../../../api/companiesSelector";
import { setWhatsappStatusGood, updateConnectionStatus } from "../connectionsSlice";
import { ConnectionStatusType, ConnectionsType, connectionTypes } from "../ConnectionsTypes";
import { NewConnection } from "../../../../api/connections/types";
import { ReactComponent as WhatsAppIcon } from '../../../../assets/draw-whatsapp.svg';
import { ReactComponent as WhatsAppBigIcon } from '../../../../assets/logo-whatsapp.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/draw-telegram.svg';
import { ReactComponent as TelegramBigIcon } from '../../../../assets/logo-telegram.svg';
import { ReactComponent as TelegramBot } from '../../../../assets/draw-telegram-bot.svg';
import { ReactComponent as TelegramBotBigIcon } from '../../../../assets/logo-telegram-bot.svg';
import { ReactComponent as WhatsAppBusinessIcon  } from '../../../../assets/draw-whatsapp-buiness.svg';
import { ReactComponent as WhatsAppBusinessBigIcon } from '../../../../assets/logo-whatsapp-buiness.svg';
import { ReactComponent as OdnoklassnikiIcon } from '../../../../assets/draw-odnoklassniki.svg';
import { ReactComponent as OdnoklassnikiBigIcon } from '../../../../assets/logo-odnoklassniki.svg';
import { ReactComponent as bePaidIcon } from '../../../../assets/draw-bePaid.svg';
import { ReactComponent as bePaidBigIcon } from '../../../../assets/logo-bePaid.svg';
import { ReactComponent as TbankIcon } from '../../../../assets/draw-tbank.svg';
import { ReactComponent as TbankBigIcon } from '../../../../assets/logo-tbank.svg';
import { ReactComponent as SberIcon } from '../../../../assets/draw-sberbank.svg';
import { ReactComponent as SberBigIcon } from '../../../../assets/logo-sberbank.svg';
import { ReactComponent as ModulbankIcon } from '../../../../assets/draw-modulbank.svg';
import { ReactComponent as ModulbankBigIcon } from '../../../../assets/logo-modulbank.svg';
import { ReactComponent as PayKeeperIcon } from '../../../../assets/draw-paykeeper.svg';
import { ReactComponent as PayKeeperBigIcon } from '../../../../assets/logo-paykeeper.svg';
import { ReactComponent as YookassaIcon } from '../../../../assets/draw-yookassa.svg';
import { ReactComponent as YookassaBigIcon } from '../../../../assets/logo-yookassa.svg';


export type ConnectionServiceTypes = 'mobile' | 'social' | 'banking' | 'bot';

export type ConnectionServiceType = {
  type: ConnectionServiceTypes;
  url: string;
  ComponentIcon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
  bigIcon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
  bigIconSize: React.CSSProperties;
  name: string;
  background: string;
};

export type ConnectionButtonsType = Partial<Record<ConnectionsType, ConnectionServiceType>>;

export const connectionsComposeMap = Object.fromEntries(connectionTypes.map((item) => [item, `connections_${item}`]));

export const settingConnections: ConnectionButtonsType = {
  'waba': {
    type: 'mobile',
    url:'waba',
    ComponentIcon: WhatsAppBusinessIcon,
    bigIcon: WhatsAppBusinessBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name:'connections.title.waba',
    background: '#075E54' },
  'whatsapp': {
    type: 'mobile',
    url:'whatsapp',
    ComponentIcon: WhatsAppIcon,
    bigIcon: WhatsAppBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.whatsapp',
    background: '#25D366' },
  'telegram': {
    type: 'mobile',
    url:'telegram',
    ComponentIcon: TelegramIcon,
    bigIcon: TelegramBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.telegram',
    background: '#59A3D6' },
  'telegram_bot': {
    type: 'bot',
    url:'telegram_bot',
    ComponentIcon: TelegramBot,
    bigIcon: TelegramBotBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.telegram_bot',
    background: '#F2F2F2' },
  'odnoklassniki': {
    type: 'social',
    url: 'odnoklassniki',
    ComponentIcon: OdnoklassnikiIcon,
    bigIcon: OdnoklassnikiBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.odnoklassniki',
    background: '#F7931E' },
  'bepaid': {
    type: 'banking',
    url: 'bePaid',
    ComponentIcon: bePaidIcon,
    bigIcon: bePaidBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.bePaid',
    background: '#F2F2F2' },
  'tinkoff': {
    type: 'banking',
    url: 'tinkoff',
    ComponentIcon: TbankIcon,
    bigIcon: TbankBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.tinkoff',
    background: '#FFDD2D' },
  'sberbank': {
    type: 'banking',
    url: 'sber',
    ComponentIcon: SberIcon,
    bigIcon: SberBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.sber',
    background: '#F5F4F3' },
  'modulbank': {
    type: 'banking',
    url: 'modulbank',
    ComponentIcon: ModulbankIcon,
    bigIcon: ModulbankBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.modulbank',
    background: '#0F1A7C' },
  'paykeeper': {
    type: 'banking',
    url: 'paykeeper',
    ComponentIcon: PayKeeperIcon,
    bigIcon: PayKeeperBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.paykeeper',
    background: '#CC1626' },
  'yookassa': {
    type: 'banking',
    url: 'yookassa',
    ComponentIcon: YookassaIcon,
    bigIcon: YookassaBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.yookassa',
    background: '#F5F4F3' }
};

export const getServiceNameByUrl = (serviceUrl: string | undefined) => {
  if (serviceUrl) {
    const filterArray = Object.entries(settingConnections).find(([_, value]) => value.url === serviceUrl);
    if (filterArray && filterArray[0]) return filterArray[0] as ConnectionsType;
  }
  return null;
};

export const useConnectionOuterLink = ( connection: NewConnection): { link: string, href: string } => {
  let link: string = '';
  let href: string = '';
  switch (connection.type) {
    case 'odnoklassniki':
      href = `https://ok.ru/group/${connection.params.groupId.replace(/[^0-9]/g, '')}`;
      link = href;
      return { link, href };
    case 'telegram_bot':
      href = `https://t.me/${connection.params.botUsername}`;
      link = `@${connection.params.botUsername}`;
      return { link, href };
    default:
      return { link, href };
  }
};

export type ConnectionItemContextType = {
  connection: NewConnection;
  onEdit?: (connection: NewConnection) => void;
  onDelete?: (connection: NewConnection) => void;
  onPreview?: (connection: NewConnection) => void;
};

export const connectionItemContext = createContext<ConnectionItemContextType>({ connection: {} as NewConnection });

export type ConnectionsUploadContextType = {
  hasMore: boolean;
  onUpload: () => void;
  service: ConnectionsType;
};

export const connectionsUploadContext = createContext<ConnectionsUploadContextType>(
  { service: '' as ConnectionsType, hasMore: false, onUpload: () => { } }
);

export const useConnectionStatus = () => {
  const dispatch = useDispatch();
  const { connection } = useContext(connectionItemContext);
  const company = useSelector(currentCompanySelector);
  const connectionStatuses = useAppSelector((state) => state.connections.whatsappStatus);
  const [status, setStatus] = useState<ConnectionStatusType>();

  useEffect(() => {
    if (company.id && !status) {
      if (connection.lastStatus?.statusType === 'bad') {
        dispatch(updateConnectionStatus({
          connectionId: connection.id,
          companyId: company.id
        }));
      } else if (connection.lastStatus?.statusType === 'good') {
        dispatch(setWhatsappStatusGood(connection.id));
      }
    }
  }, [company]);

  useEffect(() => {
    if (connectionStatuses) {
      setStatus(connectionStatuses[connection.id]);
    }
  }, [connectionStatuses]);

  return status;
};

export const transformEmptyStringToUndefined = (value: string) => {
  if (value === '') {
    return undefined;
  }
  return value;
};
