import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getIntegrationsList } from './integrationSlice';
import { useAppDispatch } from '../../../store/store';
import { IntegrationService, IntegrationTypeService } from './types';
import { getArea, getService, integrationContext } from './utils';
import { IntegrationsItem } from './IntegrationTypes';
import { getIntegrationsItem } from './IntegrationAPI';
import APIIntegration from './components/APIIntegration';
import Banking from './components/Banking';
import Messaging from './components/Messaging';


function IntegrationSettings() {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const currentCompanyId = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const routeMatch = useRouteMatch<{ integrationId: string; }>();
  const [integration, setIntegration] = useState<IntegrationsItem>();

  useEffect(() => {
    let isFetchData: boolean = true;

    const fetchIntegration = async () => {
      const integrationData = await getIntegrationsItem({
        companyId: Number(currentCompanyId?.params.companyId),
        itemId: Number(routeMatch.params.integrationId)
      });

      if (isFetchData) setIntegration(integrationData);
    };

    fetchIntegration().catch(() => {
      push(`/companies/${currentCompanyId?.params.companyId}/settings/integrations`);
    });

    return () => {
      isFetchData = false;
      if (currentCompanyId?.params.companyId) {
        dispatch(getIntegrationsList(Number(currentCompanyId?.params.companyId)));
      }
    };

  }, [currentCompanyId?.params.companyId]);

  if (integration) {
    const area = getArea(integration.type);

    if (area === 'api') {
      return (
        <integrationContext.Provider value={{ area, integration, setIntegration }}>
          <APIIntegration />
        </integrationContext.Provider>
      );
    }

    const service = getService(integration.type) as IntegrationService;

    if (area === 'amocrm' || area === 'bitrix') {

      if (IntegrationTypeService[service] === 'banking') {
        return (
          <integrationContext.Provider value={{ area, service, integration, setIntegration }}>
            <Banking />
          </integrationContext.Provider>
        );
      }

      if (IntegrationTypeService[service] === 'messaging') {
        return (
          <integrationContext.Provider value={{ area, service, integration, setIntegration }}>
            <Messaging />
          </integrationContext.Provider>
        );
      }
    }

    return <></>;
  }

  return <></>;
}

export default IntegrationSettings;
