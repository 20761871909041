import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useKeycloak } from '@react-keycloak/web';
import { history } from '../store/store';
import { PrivateRoute } from './PrivateRoute';
import CompaniesRoute from "./CompaniesRoute";
import Loader from '../components/Loader';


const AppRouter = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return (<Loader />);
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute path="/" component={CompaniesRoute} />
      </Switch>
    </ConnectedRouter>
  );
};

export default AppRouter;
