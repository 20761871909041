import React, { useEffect, useState } from 'react';
import './NavbarMobile.scss';
import { useSelector } from "react-redux";
import { ReactComponent as MenuIcon } from '../../../../assets/menu-mobile.svg';
import { ReactComponent as CloseIcon } from "../../../../assets/cross.svg";
import { getCurrentCompanyRoleSelector, isViewNavbarMobileSelector, isOpenNavbarMobileSelector } from "../../../../api/companiesSelector";
import { SubMenuTabsType, SubMenuTabType, TabsListType, tabsSales, tabsSettings } from '../subMenuLists';
import SubMenu from './SubMenu';
import MainTabs from './MainTabs';


const subMenuTabs: Record<SubMenuTabType, SubMenuTabsType[]> = {
  sales: tabsSales, settings: tabsSettings
};

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectSubTab, setSelectSubTab] = useState<SubMenuTabType | null>(null);
  const [currentSubTabs, setCurrentSubTabs] = useState<TabsListType | null>(null);
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const tabsWithOther = currentRole ? [...currentRole.permissions, 'other'] : [];
  const isViewNavbarMobile = useSelector(isViewNavbarMobileSelector);
  const isOpenNavbarMobile = useSelector(isOpenNavbarMobileSelector);

  const onClose = () => {
    setIsOpen(false);
    setCurrentSubTabs(null);
  };

  useEffect(() => {
    if(isOpenNavbarMobile) setIsOpen(!isOpen);
  }, [isOpenNavbarMobile]);

  useEffect(() => {
    setSelectSubTab(null);
    if (selectSubTab) setCurrentSubTabs({
      title: selectSubTab,
      tabs: subMenuTabs[selectSubTab].filter(i => tabsWithOther.includes(i.permission))
    });
  }, [selectSubTab]);

  if (!isOpen) {
    return (
      <div className="OpeningIcon" onClick={() => setIsOpen(!isOpen)} style={{ display: isViewNavbarMobile ? 'flex' : 'none' }}>
        <MenuIcon />
      </div>
    );
  }

  return (
    <div className="NavbarMobile_wrapper">
      <div className={`NavbarMobile ${currentSubTabs ? 'sub' : ''}`}>
        <CloseIcon className='closeIcon' onClick={onClose} />
        {currentSubTabs && currentSubTabs.tabs.length > 0 ? (
          <SubMenu setIsOpen={setIsOpen} setCurrentSubTabs={setCurrentSubTabs} currentSubTabs={currentSubTabs} />
        ) : (
          <MainTabs setSelectSubTab={setSelectSubTab} setIsOpen={setIsOpen} />
        )}
      </div>
    </div>
  );
};

export default NavbarMobile;
