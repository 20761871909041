import React, { FC, useEffect, useRef, useState } from 'react';
import './SalesList.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SalesIcon } from '../../../../../assets/sales.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-navbar.svg';
import { getCurrentCompanyRoleSelector } from '../../../../../api/companiesSelector';
import { tabsSales } from "../../subMenuLists";
import {getViewPopup, KeysTabsSalesType} from "../../utils";
import PopupTitle from "../../components/PopupTitle/PopupTitle";
import SalesItem from '../SalesItem';


type Props = {
  selectedTab: string | undefined;
  onSelectTab: (e: React.MouseEvent<HTMLDivElement>) => void;
  isRolledUp: boolean;
}
export const shopTabList: Array<KeysTabsSalesType> = ['shops', 'products', 'catalogs', 'orders'];

const SalesList: FC<Props> = ({ selectedTab, onSelectTab, isRolledUp }) => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ id: string }>('/companies/:companyId');
  const currentTab = useRouteMatch<{ tab: string }>('/companies/:companyId/:tab');
  const [isShowingSales, setIsShowingSales] = useState(false);
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const { viewPopup, onMouseLeave, onMouseEnter } = getViewPopup();
  const salesRef = useRef<HTMLDivElement>(null);

  const tabsWithOther = currentRole ? [...currentRole.permissions, 'other'] : [];
  const listSales = tabsSales.filter(i => tabsWithOther.includes(i.permission));

  useEffect(() => {
    const openSalesState = localStorage.getItem('onSales');
    if (openSalesState !== null) setIsShowingSales(JSON.parse(openSalesState));
  }, []);

  const onShowingSales = () => {
    setIsShowingSales(!isShowingSales);
    const openSalesState = JSON.stringify(!isShowingSales);
    localStorage.setItem('onSales', openSalesState);
  };

  return (
    <div
      className={`tabShadowShops ${isRolledUp ? 'rolledUp' : ''} ${isShowingSales && 'active'} ${(!isShowingSales && shopTabList.find((tab) => tab === currentTab?.params.tab)) && 'outActive'}`}>
      <div className={`tabShop ${ isShowingSales ? '': 'rolledUp' }`} onClick={onShowingSales}>
        <div className={`tab__icon ${isRolledUp ? 'rolledUp' : ''}`} ref={salesRef}>
          {isRolledUp ? <PopupTitle text={t('navbar.sales')} refPopup={salesRef} image={<SalesIcon onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
              className={`salesIcon ${(isShowingSales && shopTabList.find((tab) => tab === currentTab?.params.tab)) && 'outActive'}`} />} viewPopup={viewPopup} /> :
            <SalesIcon width={24} height={24} />
          }
        </div>
        {!isRolledUp &&
          <>
            <p> {t('navbar.sales')}</p>
            <ArrowIcon className={`arrow ${isShowingSales && 'active'}`} />
          </>
        }
      </div>
      {!isRolledUp && isShowingSales && <div className='tabList'>
        {listSales.map(({tab}) =>
          <Link key={tab} to={`${url!.url}/${tab}`}>
            <p className={`tabItem ${selectedTab === tab && 'active'}`} id={tab} onClick={onSelectTab}>
              {t(`navbar.${tab}`)}
            </p>
          </Link>)}
      </div>}
      {isRolledUp && isShowingSales && <div className='tabList rolledUp'>
        {listSales.map(({tab}) =>
          <SalesItem key={tab} tab={tab} selectedTab={selectedTab} />
        )}
      </div>}
    </div>
  );
};

export default SalesList;
