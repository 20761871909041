import { YMInitializer } from 'react-yandex-metrika';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../store/store';
import { useQuery } from '../components/utils/hooks';
import { currentCompanySelector, invitationCompanyNameSelector, profileSettingsInfo } from '../api/companiesSelector';
import { fetchCompanyName, fetchInitialData } from '../api/companiesSlice';
import { updateIsActionBill } from '../views/Chat/billSlice';
import { YandexMetrikaCall } from '../utils/utils';
import moment from 'moment-timezone';
import SocketIOProvider from '../views/Chat/utils/soketio/socketIoContext';
import Registration from "../pages/Registration/Registration";
import Error404 from '../pages/Error404';
import NotificationsProvider from '../api/NotificationsProvider';
import Loader from '../components/Loader';
import Home from "../pages/Home";


const yandexId = Number(process.env.REACT_APP_YANDEX_METRIKA_ID);

const CompaniesRoute = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const params = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const history = useHistory();
  const { i18n } = useTranslation();
  const infoProfile = useSelector(profileSettingsInfo);
  const currentCompany = useSelector(currentCompanySelector);
  const invitationCompanyName = useSelector(invitationCompanyNameSelector);
  const code = query.get('code');

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(fetchInitialData(Number(params.params.companyId)));
    } else {
      dispatch(fetchInitialData(undefined));
    }
  }, []);

  useEffect(() => {
    if (currentCompany) {
      moment.tz.setDefault(currentCompany.timezone);
    } else moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [currentCompany]);

  useEffect(() => {
    if (infoProfile) {
      i18n.changeLanguage(infoProfile.language);
      if (infoProfile.firstLogin) {
        YandexMetrikaCall('reachGoal', 'registration_completed');
      }
    }
  }, [infoProfile]);

  useEffect(() => {
    // не забыть удалить, когда будем менять выставление счета в Чатах
    if (history.location.pathname.endsWith('bill')) {
      dispatch(updateIsActionBill(true));
    } else dispatch(updateIsActionBill(false));
  }, [history.location]);

  useEffect(() => {
    if (code) {
      dispatch(fetchCompanyName(code)).then(res => {
        if (res.meta.requestStatus === 'rejected') {
          history.push('/404');
        }
      });
    }
  }, [code]);

  if (!currentCompany.id) {
    return (<Loader />);
  }

  return (
    <>
      {yandexId > 0 && (<YMInitializer accounts={[yandexId]}
        options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true, trackHash: true }}
        version="2"/>
      )}
      <SocketIOProvider>
        <NotificationsProvider>
          <Switch>
            {currentCompany.id && (
              <Route path="/companies">
                <Home />
              </Route>
            )}
            <Route path="/registration">
              <Registration invitationCompanyName={invitationCompanyName} />
            </Route>
            <Route path="/404">
              <Error404 />
            </Route>
            <Route path="/invite">
              {invitationCompanyName && code &&
                <Redirect to={`/registration/invitation/${code}`} />
              }
            </Route>
            {currentCompany.id
              ? <Redirect exact from="/" to={`/companies/${currentCompany.id}`} />
              : <Redirect to="/registration" />
            }
            <Redirect to="/404" />
          </Switch>
        </NotificationsProvider>
      </SocketIOProvider>
    </>
  );
};

export default CompaniesRoute;
