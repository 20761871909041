import './SubscriptionSlot.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import React, { useState } from 'react';
import Button from '../../../../components/Button';


type SubscriptionSlotProps = {
  currentSlot: number;
  mininumSlots: number;
  maximumSlots: number;
  setOpen: (open: boolean) => void;
  setSlot: (slot: number) => void;
  discounts: Array<{ value: string, discount: string }>;
};

function SubscriptionSlot( props: SubscriptionSlotProps) {
  const { currentSlot, maximumSlots, mininumSlots, setOpen, setSlot, discounts } = props;
  const { t } = useTranslation();
  const [slotsNumber, setSlotsNumber] = useState(currentSlot);

  const onClose = () => {
    setOpen(false);
  };

  const onSlotChange = (step: number) => {
    setSlotsNumber((prev) => {
      if (slotsNumber + step >= mininumSlots && slotsNumber + step <= maximumSlots) return slotsNumber + step;
      return prev;
    });
  };

  const onSlotInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= mininumSlots && Number(e.target.value) <= maximumSlots) {
      setSlotsNumber(Number(e.target.value));
    } else {
      e.target.value = String(slotsNumber);
    }
  };

  const onFocusInput = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const onSetSlot = () => {
    setSlot(slotsNumber);
    setOpen(false);
  };

  return (
    <div className="subscriptionSlot">
      <CloseIcon onClick={onClose} />
      <h4>{t('subscriptions.slots.add')}</h4>
      <div className="subscriptionSlot__content">
        <div className='subscriptionSlot__counter'>
          <p className="smallText">
            {`${currentSlot} ${t('subscriptions.slots.number.number', { count: currentSlot })}`}
            &nbsp;{t('subscriptions.slots.in_subscription')}
          </p>
          <div className="subscriptionSlot__slots">
            <Button type="button" color="white" textType="regular" text={'-'} onClick={() => onSlotChange(-1)} />
            <input className="smallText" type="number" value={slotsNumber} onWheel={onWheel}
              onChange={onSlotInput} min={mininumSlots} max={maximumSlots} onFocus={onFocusInput} />
            <Button type="button" color="white" textType="regular" text={'+'} onClick={() => onSlotChange(1)} />
          </div>
          <Button type="button" color="white" textType="small" text={t('save')} onClick={onSetSlot} />
        </div>
        <div className="subscriptionSlot__discounts">
          <p className="discount">{t('subscriptions.plans.discount')}</p>
          <div className="subscriptionSlot__discounts_list">
            {discounts?.map((item) => (
              <p key={item.discount}>
                {t('subscriptions.plans.discount_description', { value: item.value, discount: item.discount })}
              </p>
            ))}
          </div>
        </div>
      </div>
      <p className="subscriptionSlot__warning smallText">{t('subscriptions.slots.warning')}</p>
    </div>
  );
}

export default SubscriptionSlot;
