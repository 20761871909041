import { useEffect, useState } from 'react';
import './ModulBankEdit.scss';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { ModulbankEditSchema, PaymentTypes } from '../../shemas';
import { ModulBankConnection } from "../../../../../../api/connections/types";
import { ModulbankPaymentMethods } from '../../../ConnectionsTypes';
import { updateConnection } from '../../../connectionsSlice';
import {
  getMethodPaymentModulbank, switchItems, TaxationListType, taxationTypes, MAX_LIFETIME, MIN_LIFETIME
} from "../../../utils/terminals";
import { transformEmptyStringToUndefined } from '../../../utils/connections';
import Dropdown from '../../../../../../components/Dropdown';
import Input from '../../../../../../components/Input';
import Switch from '../../../../Switch';
import Button from '../../../../../../components/Button';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import LifetimePayment from "../../../components/LifetimePayment";


const PaymentMethodTypes: TaxationListType = [
  { id: 'card_sbp', option: 'Все доступные ' },
  { id: 'card', option: 'Оплата только картой' },
  { id: 'sbp', option: 'СБП (только QR-код) ' },
];

const getPaymentMethodOfConnection = (paymentMethods: string[] | undefined): PaymentTypes => {
  if (paymentMethods?.length === 1) {
    switch (paymentMethods[0]) {
      case 'card': {
        return 'card';
      }
      case 'sbp': {
        return 'sbp';
      }
    }
  }
  return 'card_sbp';
}

const ModulBankEdit = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { connectionId } = useParams<{ connectionId: string }>();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.modulbank);
  const [connection, setConnection] = useState<ModulBankConnection>();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      merchant_password: yup.string().max(128, t('error.form.max_length', { limit: 128 })).transform(transformEmptyStringToUndefined),
      is_testing: yup.boolean(),
      taxation: yup.string(),
      payment_methods: yup.string(),
      invoice_lifetime_minutes: yup.number().min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    }),
  });

  const methods = useForm<ModulbankEditSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name,
      params: {
        merchant_password: '',
        is_testing: connection?.params.isTesting,
        taxation: connection?.params.taxation,
        payment_methods: getPaymentMethodOfConnection(connection?.params.paymentMethods),
        invoice_lifetime_minutes: connection?.params.invoiceLifetimeMinutes,
      },
    },
  });

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as ModulBankConnection;
    if (connect) {
      setConnection(connect);
      methods.reset({
        name: connect?.name,
        params: {
          is_testing: connect?.params.isTesting,
          taxation: connect?.params.taxation,
          payment_methods: getPaymentMethodOfConnection(connect?.params.paymentMethods),
          invoice_lifetime_minutes: connect?.params.invoiceLifetimeMinutes,
        }
      });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmitChanges: SubmitHandler<ModulbankEditSchema> = async (data) => {
    if (!methods.formState.isDirty) {
      return;
    }
    if (connection !== undefined && currentCompanyId?.params.companyId) {
      dispatch(updateConnection({
        companyId:Number(currentCompanyId?.params.companyId),
        itemId: Number(connectionId),
        name: data.name,
        type: 'modulbank',
        params: {
          ...data.params,
          payment_methods: getMethodPaymentModulbank(data.params.payment_methods) as ModulbankPaymentMethods[]
        }
      })).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          push(`/companies/${currentCompanyId?.params.companyId}/settings/connections/modulbank`);
        }
      });
    }
  };

  return connection ? (
      <div className="modulBankEditPage">
        <GoBackWrapper title={t('edit')}>
          <p className="modulBankEditPage__connectionNumber">Номер подключения: #{connection.id}</p>
          <p className="modulBankEditPage__terminalKey">Merchant ID: {connection.params.merchantId}</p>
          <FormProvider {...methods}>
            <div className="modulBankEditPage__form">
              <Input label="Название подключения" name="name" />
              <Input label="Обновить Secret" name="params.merchant_password" inputType="password" />
              <Dropdown
                items={taxationTypes}
                label="Система налогообложения"
                name="params.taxation"
                labelKey="option"
                valueKey="id"
              />
              <Dropdown
                items={PaymentMethodTypes}
                label="Способ оплаты по умолчанию"
                name="params.payment_methods"
                labelKey="option"
                valueKey="id"
              />
              <Switch items={switchItems} name="params.is_testing" />
              <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={connection.params.invoiceLifetimeMinutes} />
            </div>
            <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                    onClick={methods.handleSubmit(onSubmitChanges)} disabled={!methods.formState.isDirty} />
          </FormProvider>
        </GoBackWrapper>
      </div>
  ) : null;
};

export default ModulBankEdit;
