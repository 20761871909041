import moment from 'moment-timezone';
import { TFunction } from 'react-i18next';
import { Subscription, SubscriptionInvoice, checkItemSubscription } from "../SubscriptionsTypes";
import { currencyFormat } from '../../CompanySettings/currency';
import { LegalEntity } from './LegalEntity';
import UserSubscription from "./UserSubscription";
import UserInvoiceItem from "./UserInvoiceItem";


class UserSubscriptionInvoice {
  private subscriptions: Record<number, UserSubscription> = {};

  public items: UserInvoiceItem[];

  private readonly invoice: SubscriptionInvoice;

  private readonly t: TFunction;

  private readonly locale: string;

  constructor(subscriptions: Subscription[], invoice: SubscriptionInvoice, t: TFunction, locale: string = 'ru') {
    subscriptions.forEach(subscription => {
      this.subscriptions[subscription.id] = new UserSubscription(subscription);
    });
    this.invoice = invoice;
    this.t = t;
    this.locale = locale;
    this.items = this.getInvoiceItems(invoice);
  }

  private getInvoiceItems(invoice: SubscriptionInvoice): UserInvoiceItem[] {
    return invoice.summary.items.map((item) => {
      if (checkItemSubscription(item)) {
          const invoiceItem = new UserInvoiceItem(
            item.subscriptionId,
            this.subscriptions[item.subscriptionId].name,
            item.topUpAmount.amount + item.upgradeAmount.amount
              - item.topUpAmount.discount - item.upgradeAmount.discount,
            item.topUpAmount.discount + item.upgradeAmount.discount,
            this.t,
            this.locale
          );
        invoiceItem.setVolume = item.newSize;
        invoiceItem.setType = this.subscriptions[item.subscriptionId].serviceType;
        return invoiceItem;
      }
      return new UserInvoiceItem(0, 'WabaDialog', item.count * item.price, 0, this.t, this.locale);
    });
  }

  private formatDate(date: string): string {
    moment.locale(this.locale);
    return moment(date).format('L');
  }

  public get id() : number {
    return this.invoice.id;
  }

  public get number(): string {
    if (this.locale === 'ru') {
      return `№ ${this.invoice.id}`;
    }
    return this.invoice.id.toString();
  }

  /**
   * formatInvoiceNumberDate
   */
  public formatInvoiceNumberDate() : string {

    const invoiceNumber = this.t('subscriptions.invoice.title.bill_number');
    const dateDelimiter = this.t('subscriptions.invoice.title.date_delimiter');
    return `${invoiceNumber}${this.invoice.id}${dateDelimiter}${this.date}`;
  }

  public get amount() : string {
    return currencyFormat(this.invoice.amount, this.invoice.currency, this.locale);
  }

  public get date(): string {
    return this.formatDate(this.invoice.createdAt);
  }

  public get status() : string {
    return this.invoice.status;
  }

  public formatStatus(): string {
    const status = this.t(`subscriptions.invoice.status.${this.invoice.status}`);
    if (this.invoice.status === 'new') {
      const actual = this.t('subscriptions.invoice.status.actual');
      return `${status}, ${actual} ${this.formatDate(this.invoice.lifetime)}`;
    }
    return status;
  }

  public get paymentUrl() : string {
    return this.invoice.paymentUrl;
  }

  public get previewUrl() : string {
    return this.invoice.previewUrl;
  }

  public get actUrl() : string {
    return this.invoice.actUrl;
  }

  public get description() : string {
    const itemText = this.t('subscriptions.invoice.description_text.item');
    const monthsText = this.t('subscriptions.invoice.description_text.period');
    return `${itemText}, ${this.invoice.summary.periodMonths} ${monthsText}`;
  }

  public get isBuyer() : boolean {
    return !!this.invoice.summary.companyRequisite;
  }

  public get buyer() : LegalEntity {
    return {
      name: this.invoice.summary.companyRequisite.name,
      tin: this.invoice.summary.companyRequisite.tin
    };
  }

}

export default UserSubscriptionInvoice;
