import './Error404.scss';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import errorDude from '../../assets/Saly-11.png';
import logo from '../../assets/logo-r-online.svg';


const Error404 = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <div className="errorContainer">
      <div className="errorContainer__left">
        <h1>{t('error.title')}</h1>
        <div className="errorContainer__left_text" onClick={() => push('/')}>
          <img src={logo} alt="radistLogo" />
          <p>{t('error.error_text')}</p>
        </div>
      </div>
      <div className="errorContainer__right">
        <img src={errorDude} alt="errorDude" />
      </div>
    </div>
  );
};

export default Error404;
