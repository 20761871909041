import React, { useRef, useState } from 'react';
import '../Dropdown/Dropdown.scss';
import { useHistory, useRouteMatch } from 'react-router';
import { useOutsideClick } from '../utils/hooks';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-down.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import Button from '../Button';


type Props = {
  label: string;
  link: string;
  linkText: string;
  placeholder: string;
  options?: () => void;
  visibleButton?: boolean;
}

const DropdownEmptyLink = ({ label, link, linkText, placeholder, options, visibleButton }: Props) => {
  const history = useHistory();
  const params = useRouteMatch<{ companyId: string }>('/companies/:companyId/');
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  useOutsideClick(contentRef, () => setIsOpened(false), triggerRef);

  const onTriggerDropdown = () => setIsOpened(!isOpened);

  const onLink = () => {
    history.push(`/companies/${params?.params.companyId}/${link}`);
    if (options) {
      options()
    }
  };

  return (
    <div className="dropdownContainer">
      <p className="dropdownContainer__label">{label}</p>
      <div className="dropdownContainer__dropdown" ref={triggerRef} onClick={onTriggerDropdown}>
        <p className='dropdownContainer__dropdown_placeholder notSelected'>
          {placeholder}
        </p>
        <div className={`dropdownContainer__dropdown_arrowIcon ${isOpened && 'opened'}`}><ArrowIcon /></div>
        {isOpened && (
          <div className="dropdownContainer__dropdown_content" ref={contentRef}>
            {visibleButton && <div className="dropdownContainer__dropdown_content__item">
              <Button type='button' textType='small' color='white' text={linkText} onClick={onLink}
                image={<PlusIcon />} />
            </div>}
          </div>
        )}
      </div>
    </div>
  );
}

export default DropdownEmptyLink;
