import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RequisitesSelector } from '../../SubscriptionsSelector';
import { CurrenciesType, currencyFormat } from '../../../CompanySettings/currency';
import {
  SubscriptionInvoiceDialog, SubscriptionInvoiceItem, checkItemDialog, checkItemSubscription, invoiceItemTypes,
  SubscriptionServiceType, SubscriptionPlanType, subscriptionPlans
} from '../../SubscriptionsTypes';
import { formatNumberLocale } from '../../../../../utils/utils';
import { SubscriptionStatusEnum } from '../../types';
import UserSubscription from '../../models/UserSubscription';
import Gift from './Gift';


export type SubscriptionInvoiceItemType = {
  type: string;
  itemType: string;
  serviceType: string;
  subscriptionId?: number;
  subscriptionPlan?: SubscriptionPlanType;
  currentSize?: number;
  maxSize?: number;
  status?: SubscriptionStatusEnum;
  volume?: number;
  amount?: string;
  discount?: string;
  total?: string;
};

export function checkInvoiceItemSubscription(item: SubscriptionInvoiceItemType): item is SubscriptionInvoiceItemType {
  return item && (item as SubscriptionInvoiceItemType).itemType === 'subscription';
}

export function InvoiceItems(
  subsInvoice: Array<SubscriptionInvoiceItem | SubscriptionInvoiceDialog> | null,
  subsSelected?: UserSubscription[], lang: string = 'ru') {
  const items = [];
  if (subsSelected && subsSelected.length > 0) {
    items.push(...subsSelected.map((sub) => {
      const subscription = subsInvoice?.find((item) => (
        checkItemSubscription(item) && sub.id === item.subscriptionId));
      const subItem = {
        type: sub.type, serviceType: sub.serviceType, itemType: 'subscription',
        subscriptionId: sub.id, currentSize: sub.currentSize, maxSize: sub.maxSize,
        status: sub.status, subscriptionPlan: sub.plan
      };
      if (subscription && checkItemSubscription(subscription)) {
        return {
          ...subItem,
          amount: formatNumberLocale(AmountItemPay(subscription), 2, lang),
          discount: formatNumberLocale(DiscountItemPay(subscription), 2, lang),
          total: formatNumberLocale(AmountPay(subscription), 2, lang)
        };
      }
      return subItem;
    }));
  }
  const dialogItem = subsInvoice?.find((item) => checkItemDialog(item));
  if (dialogItem && checkItemDialog(dialogItem)) {
    items.push({ type: 'waba_dialog', itemType: invoiceItemTypes[dialogItem.type],
      serviceType: 'dialog', volume: dialogItem.count, amount: formatNumberLocale(dialogItem.price, 4, lang),
      total: formatNumberLocale(dialogItem.price * dialogItem.count, 2, lang) });
  }
  return items;
}

const AmountItemPay = (item: SubscriptionInvoiceItem) =>
  item.topUpAmount.amount + item.upgradeAmount.amount;

const DiscountItemPay = (item: SubscriptionInvoiceItem) =>
  item.topUpAmount.discount + item.upgradeAmount.discount;

const AmountPay = (item: SubscriptionInvoiceItem) =>
  item.topUpAmount.amount - item.topUpAmount.discount + item.upgradeAmount.amount - item.upgradeAmount.discount;

type MonthPeriodDiscont = { month: number, comment: string; discount: string; component?: JSX.Element; };


const monthPeriods: MonthPeriodDiscont[] = [
  { month: 1, comment: 'subscriptions.invoice.period.month', discount: '' },
  { month: 3, comment: 'subscriptions.invoice.period.months_a', discount: '-10%' },
  { month: 6, comment: 'subscriptions.invoice.period.months_b', discount: '-15%' },
  { month: 12, comment: 'subscriptions.invoice.period.months_b', discount: '-20%' }
];

const gifts: Record<string, JSX.Element> = {
  1: <>{null}</>,
  3: <>{null}</>,
  6: <>{null}</>,
  12: <>{null}</>
};

export const METHODS_PAYMENT = ['CARD_RU', 'CARD_OTHER', 'BANK_TRANSFER'] as const;
export type MethodsPaymentType = typeof METHODS_PAYMENT[number];

export const getMonthPeriods = (eventOn: boolean) => {

  if (eventOn) {
    return monthPeriods.map(value => ({ ...value, component: gifts[value.month] }));
  }
  return  monthPeriods;
};

export function useRequisites() {
  const requisites = useSelector(RequisitesSelector);
  return requisites.filter((item) => item.approvalStatus === 'APPROVED');
}

export type TarifPrice = Record<CurrenciesType, number>;

export type TarrifPlan = Partial<Record<SubscriptionPlanType, TarifPrice>>;

export const subscriptionsPlanTariffs: Partial<Record<SubscriptionServiceType, TarrifPlan>> = {
  whatsapp: {
    START: { USD: 20, RUB: 1000 },
    STANDARD: { USD: 40, RUB: 4000 },
    ADVANCED: { USD: 60, RUB: 6000 }
  },
  waba: {
    STANDARD: { USD: 140, RUB: 5000 }
  },
  telegram: {
    START: { USD: 20, RUB: 1000 },
    STANDARD: { USD: 40, RUB: 4000 },
    ADVANCED: { USD: 60, RUB: 6000 }
  }
};

type TariffPrice = {
  id: string;
  name: string;
  price: string;
  disabled?: boolean;
};

export const getTariffsPlan = (serviceType: string, plan: SubscriptionPlanType, paid: boolean,
  currency: CurrenciesType = 'RUB') => {
  const { t, i18n } = useTranslation();
  const tariffs = subscriptionsPlanTariffs[serviceType];
  if (tariffs && Object.keys(tariffs).length > 1) {
    const tariffPlans: TariffPrice[] = [];
    const paidIndex = paid ? subscriptionPlans.findIndex((tariff) => tariff === plan) : undefined;
    subscriptionPlans.forEach((tariff, index) => {
      const tariffService = subscriptionsPlanTariffs[serviceType]
      const tariffPlan = tariffService && tariffService[tariff];
      if (tariffPlan) {
        tariffPlans.push({
          id: tariff,
          name: t(`subscriptions.plans.${tariff}`),
          price: currencyFormat(tariffPlan[currency], currency, i18n.language),
          ...(paidIndex ? { disabled: paid && index < paidIndex } : {})
        });
      }
    });
    return tariffPlans;
  }
};

export const subscriptionsSlotDiscount = [
  { value: '2-4', discount: '20%' },
  { value: '5-9', discount: '30%' },
  { value: '10-19', discount: '40%' },
  { value: '20+', discount: '50%' }
];
