import { TFunction } from "react-i18next";
import { formatNumberLocale } from "../../../../utils/utils";


class UserInvoiceItem {
  private readonly itemId: number;

  private readonly itemName: string;

  private readonly itemAmount: number;
  
  private readonly itemDiscount: number;

  private volume: number = 0;

  private type: string = '';

  private readonly t: TFunction;

  private readonly locale: string;

  constructor(id: number, name: string, amount: number, discount: number, t: TFunction, locale: string) {
    this.itemId = id;
    this.itemName = name;
    this.itemAmount = amount;
    this.itemDiscount = discount;
    this.t = t;
    this.locale = locale;
  }
  
  public set setVolume(v : number) {
    this.volume = v;
  }

  public set setType(v : string) {
    this.type = v;
  }
  
  public get id() : number {
    return this.itemId;
  }
  
  public get name() : string {
    const title = this.t('subscriptions.invoice.title_item.subscription');
    const connectionName = `subscriptions.invoice.sub_list.${this.type}`;
    return `${title} ${this.itemName}, ${this.t(connectionName)}${this.volume}`;
  }
  
  public get amount() : string {
    return formatNumberLocale(this.itemAmount, 2, 'ru');
  }
  
  public get discount() : string {
    return formatNumberLocale(this.itemDiscount, 2, 'ru');
  }
  
}

export default UserInvoiceItem;
