import './IntroductionStep.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentStepSelector, getCurrentIntroductionSelector } from '../onboardingSelectors';
import { decrementStep, incrementStep, selectStep } from '../onboardingSlice';
import { introductionSteps, stepsAnchorsUris } from '../utils';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow-left.svg';
import Button from '../../../components/Button';


function IntroductionStep() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(currentStepSelector);
  const introduction = useSelector(getCurrentIntroductionSelector);

  if (!introduction) return null;

  const link = stepsAnchorsUris[introduction];

  const onClickNextStep = () => {
    dispatch(incrementStep());
  };

  const onClickPreviousStep = () => {
    dispatch(decrementStep());
  };

  const onSelectStep = (e: React.MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.id;
    dispatch(selectStep(parseInt(step, 10)));
  };
  
  return (
    <div className='introductionStep'>
      <h3>{t(`onboarding.${introduction}.title`)}</h3>
      <p className="smallText">{t(`onboarding.${introduction}.description`)}</p>
      {link &&
        <a className="smallText" href={link[i18n.language]} target="_blank" rel="noreferrer">
          {t('onboarding.more_anchor')}
        </a>
      }
      <div className='introductionStep__stepper'>
        <div className='introductionStep__stepper_steps'>
          <div className='scale'>
            {introductionSteps.map((step, index) => (
                <div key={step} className={`step ${index + 1 === currentStep ? 'active' : ''}`}
                id={(index + 1).toString()} onClick={onSelectStep} />
            ))}
          </div>
          <p className='smallText bold'>{currentStep} {t('onboarding.steps_delimiter')} {introductionSteps.length}</p>
        </div>
        <div className='introductionStep__stepper_nav'>
          {currentStep > 1 &&
            <Button color="white" textType="small" image={<ArrowIcon />} onClick={onClickPreviousStep} id='prev' />
          }
          <Button color="orange" textType="small" image={<ArrowIcon />}
            onClick={onClickNextStep} disabled={currentStep === introductionSteps.length} id='next' />
        </div>
      </div>
    </div>
  );
}

export default IntroductionStep;
