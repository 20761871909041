import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { onboardingGuideSelector } from '../../../../../../api/companiesSelector';
import IntegrationItem from '../IntegrationItem';


type IntegrationsInstalledProps = {
  integrations: Array<{ name: string; logoUrl: string; id: number; status: string; }>;
};

function IntegrationsInstalled({ integrations }: IntegrationsInstalledProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const matchRoute = useRouteMatch();
  const onboardingGuideOn = useSelector(onboardingGuideSelector);

  const onItemClick = (id: number) => {
    return (e: React.MouseEvent<HTMLElement>) => { if (!onboardingGuideOn) history.push(`${matchRoute.url}/${id}`); };
  };

  if (integrations?.length > 0) {
    return (<>
      <h1>{t('settings.integrations.installed')}</h1>
      <div className="integrationsList__items">
        {integrations.map((item) =>
          <IntegrationItem key={item.id} name={item.name} logoUrl={item.logoUrl} status={item.status}
            onChoose={onItemClick(item.id)} />
        )}
      </div>
    </>);
  } else {
    return null;
  }
}

export default IntegrationsInstalled;
