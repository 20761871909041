import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { guideDispatchContext } from '../../../../../../components/Guide/guide';
import { onboardingGuideSelector } from '../../../../../../api/companiesSelector';
import { ConnectionServiceType } from '../../../utils/connections';
import './ConnectionItem.scss';


type ConnectionItemProps = {
  conn: ConnectionServiceType;
  guideOn: boolean;
};

function ConnectionItem({ conn, guideOn }: ConnectionItemProps) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { setElementRef } = useContext(guideDispatchContext);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);
  const { ComponentIcon } = conn;

  useEffect(() => {
    if (guideOn && contentRef?.current) {
      contentRef.current.scrollIntoView();
      setElementRef(contentRef);
    }
  }, [contentRef?.current, guideOn]);

  const onChoose = () => { if (!onboardingGuideOn) history.push(`${url}/${conn.url}`); };

  return (
    <div className="connectionItem" onClick={onChoose}>
      <div ref={contentRef} className="connectionItem__content" style={{ background: conn.background }}>
        <ComponentIcon />
      </div>
      <p className="smallText middle">{t(conn.name)}</p>
    </div>
  );
}

export default ConnectionItem;
