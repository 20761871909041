import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OnboardingSlice = {
  isFirstEntry: boolean | null;
  currentStep: number;
};

const initialState: OnboardingSlice = {
  currentStep: -1,
  isFirstEntry: !localStorage.getItem('isFirstEntry'),
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    selectStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    incrementStep: (state) => {
      state.currentStep += 1;
    },
    decrementStep: (state) => {
      state.currentStep -= 1;
    },
    cancelOnboarding: (state) => {
      state.currentStep = -1;
    },
    startOnboarding: (state) => {
      state.currentStep = 0;
    },
  }
});

export const { incrementStep, cancelOnboarding, decrementStep, selectStep, startOnboarding } = onboardingSlice.actions;

export default onboardingSlice.reducer;
