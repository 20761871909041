import { useEffect } from 'react';
import './Questioning.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NichesType, RolesType } from '../../api/types';
import { currentCompanySelector } from '../../api/companiesSelector';
import { createQuestioning, updateIsViewNavbarMobile } from '../../api/companiesSlice';
import { getTranslationNiches, getTranslationRoles } from '../utils/arrays';
import { useMobile } from '../../components/utils/hooks';
import { ReactComponent as CloseIcon } from '../../assets/cross.svg';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input';


type QuestioningSchema = {
  role: RolesType;
  custom_role: string;
  niche: NichesType;
  custom_niche: string;
};

const Questioning = () => {
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const dispatch = useDispatch();
  const isMobile = useMobile();

  useEffect(() => () => {
    dispatch(updateIsViewNavbarMobile(true));
  }, []);

  useEffect(() => {
    if (isMobile) {
      dispatch(updateIsViewNavbarMobile(false));
    } else dispatch(updateIsViewNavbarMobile(true));
  }, [isMobile]);

  const schema = yup.object().shape({
    role: yup.string().required(t('error.form.empty_field')),
    custom_role: yup.string().max(256, t('error.form.max_length', { limit: 256 }))
      .when('role', (value, customValue) =>
        value === 'other' ? customValue.typeError(t('empty_field')).min(1, t('empty_field')) : customValue.nullable()
      ),
    niche: yup.string().required(t('error.form.empty_field')),
    custom_niche: yup.string().max(256, t('error.form.max_length', { limit: 256 }))
      .when('niche', (value, customValue) =>
        value === 'other' ? customValue.typeError(t('empty_field')).min(1, t('empty_field')) : customValue.nullable()
      ),
  });

  const methods = useForm<QuestioningSchema>({
    mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: {
      custom_role: '',
      custom_niche: '',
    },
  });

  const roles = getTranslationRoles();
  const niches = getTranslationNiches();

  const onClose = () => {
    dispatch(
      createQuestioning({ type: 'ROLE_AND_NICHE', company_id: currentCompany.id, is_cancelled: true, params: null })
    );
  };

  const onSubmit = (data: QuestioningSchema) => {
    dispatch(
      createQuestioning({
        type: 'ROLE_AND_NICHE',
        company_id: currentCompany.id,
        is_cancelled: false,
        params: {
          role: data.role,
          ...(data.custom_role ? { custom_role: data.custom_role } : {}),
          niche: data.niche,
          ...(data.custom_niche ? { custom_niche: data.custom_niche } : {}),
        },
      })
    );
  };

  const disabledRole =
    methods.watch('role') === 'other' ? methods.watch('custom_role').length > 0 : methods.watch('role');
  const disabledNiche =
    methods.watch('niche') === 'other' ? methods.watch('custom_niche').length > 0 : methods.watch('niche');

  return (
    <>
      <div className="questioning__wrapper">{}</div>
      <div className="questioning">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='questioning__header'>
              <h2>{t('company_onboarding.questionare.title')}</h2>
              <CloseIcon className="closeIcon" onClick={onClose} />
            </div>
            <div className="questioning__role">
              <Dropdown
                label={t('company_onboarding.questionare.select_role')}
                items={roles} labelKey="name" placeholder={t('select')}
                valueKey="id" name="role" />
              {methods.watch('role') === 'other' && (
                <Input name="custom_role" label={t('company_onboarding.questionare.description_role')} />
              )}
            </div>
            <div className="questioning__industry">
              <Dropdown
                label={t('company_onboarding.questionare.select_niche')}
                items={niches} labelKey="name" placeholder={t('select')}
                valueKey="id" name="niche" />
              {methods.watch('niche') === 'other' && (
                <Input name="custom_niche" label={t('company_onboarding.questionare.description_niche')} />
              )}
            </div>
            <Button color="orange" textType={isMobile ? 'regular' : 'small'} text={t('send')} type="submit"
                    disabled={!disabledRole || !disabledNiche} />
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default Questioning;
