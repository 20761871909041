import { useEffect, useRef, useState } from 'react';
import './WabaProfile.scss';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseEmoji } from 'emoji-mart';
import { useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { addAlertWithCustomText } from '../../../../../../../components/Alert/alertSlice';
import { getWabaProfile, patchWabaProfile, postWabaProfilePicture } from '../../../../ConnectionsAPI';
import { verticales } from '../../../../ConnectionsTypes';
import { useWabaProfileSchema, WabaProfileData } from '../../utils';
import Input from '../../../../../../../components/Input';
import Dropdown from '../../../../../../../components/Dropdown';
import EmojiComponent from '../../../../../../../components/EmojiComponent';
import WabaImageProfile from './components/WabaImageProfile';
import WabaTextarea from './components/WabaTextarea';
import Button from '../../../../../../../components/Button';
import Loader from '../../../../../../../components/Loader/Loader';
import { ReactComponent as ImageIcon } from "../../../../../../../assets/image.svg";


const WabaProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string; connectionId: string }>(
    '/companies/:companyId/settings/connections/waba/edit/:connectionId'
  );
  const pickerTriggerButtonRef = useRef<SVGSVGElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [picker, setPicker] = useState(false);
  const [profileFile, setProfileFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const wabaSchema = useWabaProfileSchema();

  const methods = useForm<WabaProfileData>({ mode: 'onChange', resolver: yupResolver(wabaSchema) });

  useEffect(() => {
    let isFetchData = true;
    const fetchProfile = async () => {
      const profile = await getWabaProfile({
        companyId: Number(url?.params.companyId),
        connectionId: Number(url?.params.connectionId)
      });

      if (isFetchData) {
        methods.reset({
          about: profile.about ?? '',
          address: profile.address ?? '',
          description: profile.description ?? '',
          email: profile.email ?? '',
          vertical: profile.vertical ?? '',
          website_main: profile.websites?.length > 0 ? profile.websites[0] : '',
          website_additional: profile.websites?.length > 1 ? profile.websites[1] : '',
          profile_picture_url: profile.profilePictureUrl ?? ''
        });
      }
    };

    fetchProfile()
      .catch((e) => dispatch(addAlertWithCustomText({ code: e.response.data.error_code })))
      .finally(() => setLoading(false));

    return () => { isFetchData = false; };
  }, []);

  const onSelectEmoji = (emoji: BaseEmoji) => {
    const currentAbout = methods.getValues('about');
    const newAbout = currentAbout + emoji.native;
    methods.setValue('about', newAbout, { shouldDirty: true });
  };

  const onClickEmoji = () => {
    setPicker(!picker);
  };
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onDeleteFile = () => {
    methods.setValue('profile_picture_url', null, { shouldDirty: true });
    setIsFileUploaded(false);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setProfileFile(file);
      methods.setValue('profile_picture_url', URL.createObjectURL(file));
      setIsFileUploaded(true);
    }
  };

  const picture = useWatch({ name: 'profile_picture_url', control: methods.control });

  const onSubmit = async (data: WabaProfileData) => {
    let pictureUrl = '';
    if (profileFile) {
      pictureUrl = await postWabaProfilePicture(Number(url?.params.companyId), profileFile)
        .then((res) => res.url)
        .catch((e) => {
          dispatch(addAlertWithCustomText({ code: e.response.data.error_code }));
          return '';
        });
    }

    if (!data.profile_picture_url) {
      return;
    }

    await patchWabaProfile({
      companyId: Number(url?.params.companyId),
      connectionId: Number(url?.params.connectionId),
      profile_picture_url: pictureUrl || data.profile_picture_url,
      about: data.about,
      address: data.address,
      description: data.description,
      email: data.email,
      vertical: data.vertical,
      websites: [data.website_main, data.website_additional].filter((website) => website !== '')
    })
      .then(() => {
        dispatch(addAlertWithCustomText({ code: 100000 }));
        methods.reset(data);
      })
      .catch((e) => dispatch(addAlertWithCustomText({ code: e.response.data.error_code })));
  };

  if (loading) {
    return (
      <div className="wabaProfile">
        <Loader />
      </div>
    );
  }

  return (
    <div className="wabaProfile">
      <FormProvider {...methods}>
        <div className="wabaProfile__about">
          <Input name='about' label={t('connections.waba.profile.descrip')}
            rightText={t('connections.waba.profile.max_139')} maxLength={139} />
          <EmojiComponent picker={picker} setPicker={setPicker} onSelect={onSelectEmoji}
            pickerRef={pickerTriggerButtonRef} onClick={onClickEmoji} />
        </div>
        <WabaTextarea name="description" label={t('connections.waba.profile.full_descip')} id="description"
          rightText={t('connections.waba.profile.max_512')} className="regularText" maxLength={512} />
        <Dropdown name='vertical' label={t('connections.waba.profile.vertical')} placeholder={t('select')}
          labelKey='name' valueKey='id' items={verticales.map((vertical: string) => ({
            id: vertical,
            name: t(`connections.waba.profile.${vertical}`)
          }))} />
        <Input name='email' label={t('connections.waba.profile.email')} type="email" />
        <Input name='website_main' label={t('connections.waba.profile.websites')} type="url" />
        <Input name='website_additional' label={t('connections.waba.profile.secondary')} type="text" />
        <div className='wabaProfile__address'>
          <WabaTextarea name="address" label={t('connections.waba.profile.address')} id="address"
            className='regularText' maxLength={256} />
        </div>
        <div className='wabaProfile__pictures' >
          <p className='regularText'>{t('connections.waba.profile.picture')}</p>
          {!isFileUploaded && !picture
            ? (
              <div className={`wabaProfile__pictures_preview ${methods.formState.errors.profile_picture_url ? 'error' : ''}`}
                onClick={triggerFileInput} style={{ cursor: 'pointer' }}>
                <ImageIcon className='previewIcon' />
                <p className='smallText'>{t('connections.waba.profile.loader')}</p>
                <p className='smallText'>{t('connections.waba.profile.extensions')}</p>
              </div>
            ) : (
              <WabaImageProfile
                profileFile={profileFile ?? undefined}
                deleteImage={onDeleteFile}
                imageData={{ fileUrl: picture }}
                removeTrashIcon={true}
              />
            )
          }
          <input type="file" ref={fileInputRef} accept=".jpg,.jpeg,.png" style={{ display: 'none' }}
            onChange={handleFileChange} />
        </div>
        <div className='wabaProfile__save'>
          <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
            onClick={methods.handleSubmit(onSubmit)}
            disabled={!methods.formState.isDirty || methods.formState.isSubmitting} />
        </div>
      </FormProvider>
    </div>
  );
};

export default WabaProfile;
