import moment from 'moment-timezone';

enum FormatsUsedDateTimeEnum {
  hourMinutes = 'hourMinutes',
  month = 'month',
  dayMonthYear = 'dayMonthYear',
  dayMonthYearHourMin = 'dayMonthYearHourMin',
  withCustomFormat = 'withCustomFormat'
}

type KeysFormatsUsed = keyof typeof FormatsUsedDateTimeEnum
const listFormat: Record<KeysFormatsUsed, string> = {
  hourMinutes: 'HH:mm',
  month: 'MM',
  dayMonthYear: 'DD.MM.YYYY',
  dayMonthYearHourMin: 'DD.MM.YYYY HH:mm',
  withCustomFormat: 'withCustomFormat',
};
export const getDateTime = () => ({
  moment: (date?: string | Date): moment.Moment => date ? moment(date) : moment(),
  format: (date: string, format: KeysFormatsUsed, withCustomFormat?: string): string => {
    if (withCustomFormat) {
      return moment(date).format(withCustomFormat);
    }
    return moment(date).format(listFormat[format]);
  },
  isPast:(date: string | Date): boolean => {
    const today = moment().startOf('day');
    return moment(date).startOf('day').isBefore(today)
  }
});

/**
 * Check if now date is between startDateTime and endDateTime
 * @param startDateTime - start date in format 'YYYY-MM-DDTHH:mm:ssZ'
 * @param endDateTime - end date in format 'YYYY-MM-DDTHH:mm:ssZ'ься`
 */
export function isTodayBetween(startDateTime: string, endDateTime: string): boolean {
  return moment().isBetween(startDateTime, endDateTime);
}

/**
 * Check if date is today 
 */
export function isToday(date: string): boolean {
  return moment().isSame(date, 'date');
}

/**
* Check if date is before target date or now date if target is undefined
*/
export function isBefore(date: string, target?: string): boolean {
  const point = target ? moment(target) :  moment();
  return moment(date).isBefore(point);
}

/**
* Check if date is after target date or now date if target is undefined
*/
export function isAfter(date: string, target?: string): boolean {
  const point = target ? moment(target) :  moment();
  return moment(date).isAfter(point);
}

type Difference = 'years' | 'months' | 'days';

/**
 * Calculate difference between two dates
 * @param date1 - first date in format 'YYYY-MM-DDTHH:mm:ssZ'
 * @param date2 - second date in format 'YYYY-MM-DDTHH:mm:ssZ', if undefined, now date will be used
 * @param difference - type of difference
 * @returns difference between date1 and date2 in given type, positive if date1 is before date2
 */
export function dateDiff(date1: string, date2?: string, difference: Difference = 'days'): number {
  return moment(date2).diff(date1, difference);
}
