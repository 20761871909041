import { useTranslation } from "react-i18next";
import { isTodayBetween } from "./getDateTime/getDateTime";
import promotionLogoRu from '../assets/promotion-logo-ru.png';
import promotionLogoEn from '../assets/promotion-logo-en.png';
import promotionLogoEs from '../assets/promotion-logo-es.png';
import promotionBannerRu from '../assets/promotion-ru.png';
import promotionBannerEn from '../assets/promotion-en.png';
import promotionBannerEs from '../assets/promotion-es.png';


const eventStart = '2025-01-23T00:01:00';
const eventEnd = '2025-02-14T23:59:00';

type eventDetails = {
  active: boolean;
  discount: boolean;
  link: string;
  banner: string;
  logo: string;
};

type PromotionType = {
  image: string;
  link: string;
  logo: string;
};

const promotionDetails: Record<string, PromotionType> = {
  ru: {
    image: promotionBannerRu,
    link: 'https://docs.radist.online/radist.online-docs/nashi-uslugi/tarify',
    logo: promotionLogoRu
  },
  en: {
    image: promotionBannerEn,
    link: 'https://docs.radist.online/docs/our-services/subscription-tariffs-valid-from-february-01-2025',
    logo: promotionLogoEn
  },
  es: {
    image: promotionBannerEs,
    link: 'https://docs.radist.online/docs/espanol-1/nuestros-servicios/tarifas-de-suscripcion-valido-desde-01-febrero-2025',
    logo: promotionLogoEs
  },
};

export function isEventActive(): boolean {
  return isTodayBetween(eventStart, eventEnd);
}

export function useEvent(): eventDetails {
  const { i18n } = useTranslation();
  const isActive = isTodayBetween(eventStart, eventEnd);

  if (!isActive) {
    return { active: false, discount: false, link: '', banner: '', logo: '' };
  }

  return {
    active: isTodayBetween(eventStart, eventEnd),
    banner: promotionDetails[i18n.language].image,
    logo: promotionDetails[i18n.language].logo,
    discount: false,
    link: promotionDetails[i18n.language].link
  };
}
