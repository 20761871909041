import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../store/store';
import { addNewCompany } from '../../api/companiesSlice';
import { usePhoneSchema } from '../../components/PhoneInput/utils';
import { ReactComponent as InfoIcon } from '../../assets/warning.svg';
import Input from '../../components/Input';
import PhoneField from '../../components/PhoneInput/PhoneField';
import Button from '../../components/Button/Button';


type SubmitSchema = {
  phone: string;
  name: string;
  promocode?: string;
};

const currencyFromLanguage: Record<string, string> = {
  ru: 'RUB',
  en: 'USD',
  es: 'USD'
};

const RegistrationContent = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const sp = new URLSearchParams(location.search);
  const [togglePromo, setTogglePromo] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>();
  const phoneSchema = usePhoneSchema({ required: true });

  useEffect(() => {
    const promo = sp.get('promo');
    if (promo) {
      setPromoCode(promo);
      setTogglePromo(true);
    }
  }, []);

  const schema = yup.object().shape({
    ...{
      name: yup.string().required(t('error.form.empty_field')),
      promoCode: yup.string(),
    }, ...phoneSchema
  });

  const methods = useForm({
    mode: 'onSubmit', resolver: yupResolver(schema),
    defaultValues: {
      phone: '',
      name: '',
      promocode: promoCode,
    },
  });

  const onSubmit = async (data: SubmitSchema) => {
    const { name, phone, promocode } = data;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await dispatch(addNewCompany({
      name, phone, timezone,
      ...(promoCode ? { promo_code: promoCode } : { promo_code: promocode }),
      payment_currency: currencyFromLanguage[i18n.language],
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled' && typeof res.payload === 'object') {
        if (res.payload) history.push(`/companies/${res.payload.id}`);
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className='registrationRightContent'>
          <div className='registrationRightContent__create'>
            <div className='registrationRightContent__create_name'>
              <Input name='name' label={t('registration.create.name')} />
            </div>
            <div className='registrationRightContent__create_phone'>
              <PhoneField name='phone' label={t('registration.create.contactPhone')} />
              <div className='registrationWarningContainer'>
                <InfoIcon />
                <div className='registrationWarningContainer_message'>{t('registration.warning')}</div>
              </div>
            </div>
            <div className='registrationRightContent__create_promocode'>
              {togglePromo
                ? <Input name='promocode' label={t('registration.create.cod')}
                  defaultValue={promoCode} readOnly={!!promoCode} disabled={!!promoCode} />
                : <p className='regularText middle' onClick={() => setTogglePromo(!togglePromo)}>
                  {t('registration.create.isCod')}
                </p>
              }
            </div>
            <Button type='submit' color='orange' textType='regular' text={t('registration.create_company')}
              disabled={!methods.formState.isDirty} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default RegistrationContent;
