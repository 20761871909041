import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import {
  integrationsListSelector, integrationStateInfoSelector, integrationStateSelector, integrationsTypesSelector
} from './integrationSelectors';
import { getIntegrationBitrixBindLink } from './IntegrationAPI';
import { addAlertWithCustomText } from '../../../components/Alert/alertSlice';
import { currentCompanySelector } from '../../../api/companiesSelector';
import { integrationsState, integrationsStateInfo } from './integrationSlice';
import { IntegrationType } from './IntegrationTypes';
import { bitrixCodeConfig } from "./types";
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Dropdown from "../../../components/Dropdown";


type SubmitData = {
  domain: string,
  country: string,
};

const countries = ['ru', 'kz', 'by'];

const IntegrationDescriptionBitrix = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();
  const url = useRouteMatch<{ id: string; }>('/companies/:id');
  const { integrationName } = useParams<{ integrationName: string; }>();
  const currentCompany = useSelector(currentCompanySelector);
  const integrationsTypesArr = useSelector(integrationsTypesSelector);
  const integrationsArr = useSelector(integrationsListSelector);
  const stateIntegration = useSelector(integrationStateSelector);
  const stateInfo = useSelector(integrationStateInfoSelector);
  const [itemIntegration, setItemIntegration] = useState<IntegrationType>();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const [popupWindow, setPopupWindow] = useState<Window>();

  const bitrixCountriesConfig: Record<string, string> = {
    ru: t('settings.integrations.bitrix.countries.ru'),
    kz: t('settings.integrations.bitrix.countries.kz'),
    by: t('settings.integrations.bitrix.countries.by'),
  };

  const bitrixCountries = countries.map((i) => ({ id: i, country: bitrixCountriesConfig[i] }))

  const stopCheckStateInfo = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  };

  useEffect(() => {
    const integration = integrationsTypesArr.find((item) => item.type === integrationName);
    setItemIntegration(integration);
  }, [integrationsTypesArr]);

  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId]
  );

  const reState = () => {
    // get new state
    if (currentCompany.id) {
      dispatch(integrationsState(currentCompany.id));
    }
  };

  useEffect(() => {
    if ((stateInfo?.integrationId && intervalId) || (stateInfo?.error && intervalId)) {
      // stop check integration state info
      stopCheckStateInfo();
      popupWindow?.close();
      setPopupWindow(undefined);
      // check if integration already exists
      let integrationExist: boolean = false;
      if (integrationsArr) {
        for (const item of integrationsArr) {
          if (item.id === stateInfo?.integrationId) {
            integrationExist = true;
            break;
          }
        }
      }
      if (!integrationExist) {
        // if new integrations then check error and redirect if no error
        if (!stateInfo?.error) {
          push(`${url!.url}/settings/integrations/${stateInfo?.integrationId}`);
        } else {
          let errorMessage: string;
          if (stateInfo?.error === 13005) {
            errorMessage = t(`errors.${stateInfo.error}`)
              .replace('amoCRM', t('settings.integrations.bitrix.bitrix'));
          } else if (t(`errors.${stateInfo.error}`) !== `errors.${stateInfo.error}`) {
            errorMessage = t(`errors.${stateInfo.error}`);
          } else {
            errorMessage = t(`errors.${stateInfo?.error}`).startsWith('errors.')
              ? t('settings.integrations.connection_error')
              : t(`errors.${stateInfo?.error}`);
          }
          dispatch(addAlertWithCustomText({ message: errorMessage, type: 'alarm' }));
        }
      } else {
        // if integration already exists then redirect to integration settings
        push(`${url!.url}/settings/integrations/${stateInfo?.integrationId}`);
      }
      // make new state
      reState();
    }
  }, [stateInfo]);

  const checkStateInfo = (): void => {
    stopCheckStateInfo();
    if (currentCompany.id) {
      // setCheckState(true);
      const id = setInterval(async () => {
        dispatch(integrationsStateInfo({
          companyId: currentCompany.id,
          state_value: stateIntegration.state
        }));
      }, 5000);
      setIntervalId(id);
    }
  };

  const showBindLinkWindow = (link: string): void => {
    const topOffset = window.innerHeight / 2 + window.screenTop - 290;
    const leftOffset = window.innerWidth / 2 + window.screenLeft - 375;
    const popup = window.open(link, '_blank', `popup, width=750, height=580, top=${topOffset}, left=${leftOffset}`);
    if (popup) setPopupWindow(popup);
  };

  const methods = useForm({
    mode: 'onSubmit', defaultValues: { domain: '', country: 'ru' }, resolver: yupResolver(yup.object().shape({
      domain: yup.string().required(t('error.form.empty_field')),
      country: yup.string()
    }))
  });

  const onSubmit = async (data: SubmitData) => {
    // prepare request data to bind integration
    let domain: string;
    const prefix = 'https://';
    if (data.domain.startsWith(prefix)) {
      domain = data.domain.substring(prefix.length);
    } else {
      domain = data.domain;
    }
    const requestData = {
      companyId: currentCompany.id,
      state: stateIntegration.state,
      domain: domain.replace('/', '').trim(),
      app_code: bitrixCodeConfig[`${itemIntegration?.type}_${data.country}`] || '',
    };
    // get bitrix bind link
    try {
      const bindLink = await getIntegrationBitrixBindLink(requestData);
      showBindLinkWindow(bindLink.link);
      // check state info
      checkStateInfo();
    } catch (e) {
      const errorMessage = t(`errors.${e.response.data.error_code}`).startsWith('errors.')
        ? t('settings.integrations.connection_error')
        : t(`errors.${e.response.data.error_code}`);
      dispatch(addAlertWithCustomText({ message: errorMessage, type: 'alarm' }));
    }
  };

  const getInstallUrl = () => {
    const getCountry = methods.getValues('country');
    if (getCountry && itemIntegration?.params.bitrix.installUrl) {
      const newUrl = new URL(itemIntegration?.params.bitrix.installUrl);
      newUrl.hostname = `www.bitrix24.${getCountry}`;
      newUrl.search = `?app=${bitrixCodeConfig[`${itemIntegration?.type}_${getCountry}`]}`;
      return newUrl.href;
    }
    return itemIntegration?.params.bitrix.installUrl;
  };

  return (
    <div className="integrationDescription">
      <h1>{itemIntegration?.name}</h1>
      <FormProvider {...methods}>
        <form className="integrationDescription__form" onSubmit={methods.handleSubmit(onSubmit)}>
          <ol className='regularText middle'>
            <li className='selectedCountry'>
              {t('settings.integrations.bitrix.description.selected_country')}
              <Dropdown label='' items={bitrixCountries} labelKey='country' valueKey='id' name='country' />
            </li>
            <li>
              <Trans i18nKey='settings.integrations.bitrix.description.link'>
                plain<a target='_blank' rel='noreferrer' href={getInstallUrl()}>link</a>
              </Trans>
            </li>
            <li>{t('settings.integrations.bitrix.description.account')}</li>
          </ol>
          <Input name='domain' placeholder='company.bitrix24.ru' />
          <Button type='submit' color='orange' textType='regular' text={t('connect')}
                  disabled={!methods.formState.isDirty} />
          </form>
      </FormProvider>
      <div className="integrationDescription__text">{itemIntegration ? parse(itemIntegration.description) : ''}</div>
    </div>
  );
});

export default IntegrationDescriptionBitrix;
