import axiosInstance from '../../api/AxiosInstance';
import { SubscriptionInfo } from '../../api/connections/types';


export type InvoiceRequest = {
  id: number;
  order_id: string;
  bank_order_id: string;
  id_uuid: string;
  external_id: string;
  status: string;
  amount: number;
  currency: number;
  url: string;
  receipt_status: string;
  receipt: {
    items: [
      {
        name: string;
        quantity: number;
        price: number;
        vat: string;
        type: string;
        payment_type: string;
        tax_name: string;
        tax_value: number;
      }
    ];
    client_email: string;
    client_phone: string;
    client_name: string;
    buyer: {
      name: string;
      tin: string;
      iec: string;
      psrn: string;
      bic: string;
      address: string;
    };
    tax_included: boolean;
  };
  meta: unknown;
  connection_id: number;
  integration_id: number;
  contact_id: number;
  responsible_member_id: number;
};

export type GetInvoiceWithParameters = {
  count: number;
  totalCount: number;
  order_by: string;
  items: Invoice[];
};

export type InvoiceItem = {
  name: string;
  quantity: number;
  price: number;
  vat: string;
  type: string;
  paymentType: string;
  taxName: string;
  taxValue: number;
};

export type Invoice = {
  id: number;
  orderId: string;
  bankOrderId: string;
  idUuid: string;
  externalId: string;
  status: string;
  amount: number;
  currency: number;
  url: string;
  receiptStatus: string;
  receipt: {
    items: InvoiceItem[];
    clientEmail: string;
    clientPhone: string;
    clientName: string;
    buyer: {
      name: string;
      tin: string;
      iec: string;
      psrn: string;
      bic: string;
      address: string;
    },
    taxIncluded: boolean;
  },
  meta: unknown;
  connectionId: number;
  integrationId: number;
  contactId: number;
  responsibleMemberId: number;
  createdAt: string;
  updatedAt: string;
};

export type InvoicesRequest = {
  companyId: number;
  limit?: number;
  offset?: number;
  connectionId?: number;
  contactId?: number;
};

export type InvoicesProduct = {
  name: string;
  quantity: number;
  price: number;
  vat: string;
  type: string;
  payment_type: string;
  tax_name: string;
  tax_value: number | null;
};

export type InvoicesBuyer = {
  name: string | null;
  tin: string | null;
  iec: string | null;
  psrn: string | null;
  bic: string | null;
  address: string | null;
};

export type PostNewInvoices = {
  company_id: number;
  connection_id: number | null;
  amount: number;
  order_id: string;
  external_id: string | null;
  currency: number;
  description: string;
  signer: number | null;
  receipt: {
    items: InvoicesProduct[];
    client_email: string;
    client_phone: string;
    client_name: string;
    buyer: InvoicesBuyer | null;
    tax_included: boolean;
  };
  meta: {
    payment_methods: string[];
  };
  integration_id: number;
  live_due_date: string | null;
  contact_id: number;
};

export type Currency = {
  numberCode: number;
  alphaCode: string;
  name: string;
  symbol: string;
};

export type Terminal = {
  id: number;
  type: string;
  name: string;
  currencies: Currency[];
  paymentMethods: string[];
  subscription: SubscriptionInfo;
};

export type TypePaymentMethods = {
  id: string;
  name: string;
};

export const getInvoices = async (requestOption: InvoicesRequest): Promise<GetInvoiceWithParameters> => {
  const URL = new URLSearchParams();
  URL.set('offset', `${requestOption.offset}`);
  URL.set('limit', `${requestOption.limit}`);
  if (requestOption.contactId) {
    URL.set('contact_id', requestOption.contactId.toString());
  }
  URL.set('company_id', requestOption.companyId.toString());
  return await axiosInstance.get(`/companies/${requestOption.companyId}/banking/invoices/`, {
    params: URL,
  });
};

export const getListTerminal = async (companyId: number): Promise<Terminal[]> =>
  await axiosInstance.get(`/companies/${companyId}/banking/terminals/`);

export const postInvoice = async (requestOption: PostNewInvoices): Promise<InvoiceRequest> =>
  await axiosInstance.post(`/companies/${requestOption.company_id}/banking/invoices/`, {
    company_id: requestOption.company_id,
    connection_id: requestOption.connection_id,
    amount: requestOption.amount,
    order_id: requestOption.order_id,
    external_id: requestOption.external_id,
    currency: requestOption.currency,
    description: requestOption.description,
    signer: requestOption.signer,
    receipt: {
      items: requestOption.receipt.items,
      client_email: requestOption.receipt.client_email,
      client_phone: requestOption.receipt.client_phone,
      client_name: requestOption.receipt.client_name,
      buyer: requestOption.receipt.buyer,
      tax_included: requestOption.receipt.tax_included,
    },
    meta: requestOption.meta,
    integration_id: requestOption.integration_id,
    live_due_date: requestOption.live_due_date,
    contact_id: requestOption.contact_id,
  });
