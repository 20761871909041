import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store/store';
import { currentCompanySelector } from '../../../api/companiesSelector';
import { postCreateTags, putUpdateTag } from './TagsSlice';
import { Tag } from './TagsTypes';


export const useSubmitTag = () => {
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const onSubmitTag = (
    data: { name: string; description: string; backgroundColor: string },
    isEdit: boolean,
    editTag: Tag | null
  ) => {
    if (isEdit && editTag) {
      return dispatch(
        putUpdateTag({
          tagId: editTag.id,
          companyId: currentCompany.id,
          name: data.name,
          description: data.description,
          background_color: data.backgroundColor,
        })
      );
    } else {
      return dispatch(
        postCreateTags({
          companyId: currentCompany.id,
          name: data.name,
          description: data.description,
          background_color: data.backgroundColor,
        })
      );
    }
  };

  return onSubmitTag;
};
