import { useTranslation } from 'react-i18next';
import { niches, roles } from '../../api/types';


type Named = {
  id: string;
  name: string;
};

export const getTranslationRoles = () => {
  const { t } = useTranslation();
  const arrayRoles: Named[] = [];

  for (let i = 0; i < roles.length; i++) {
    arrayRoles.push({ id: roles[i], name: t(`company_onboarding.role.${roles[i]}`) });
  }

  return arrayRoles;
};

export const getTranslationNiches = () => {
  const { t } = useTranslation();
  const arrayNiches: Named[] = [];

  for (let i = 0; i < niches.length; i++) {
    niches[i] !== 'other' && arrayNiches.push({ id: niches[i], name: t(`company_onboarding.niche.${niches[i]}`) });
  }

  arrayNiches.sort((a, b) => a.name.localeCompare(b.name));
  arrayNiches.push({ id: 'other', name: t(`company_onboarding.niche.other`) });

  return arrayNiches;
};
