import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { invitationCompanyNameSelector } from '../../api/companiesSelector';
import { useAppDispatch } from '../../store/store';
import { fetchCompanyName } from '../../api/companiesSlice';

const InvitationCacnel = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const invitationCode = useRouteMatch<{ invitationCode: string }>('/registration/invitation/:invitationCode');
  const invitationCompanyName = useSelector(invitationCompanyNameSelector);

  useEffect(() => {
    if (!invitationCompanyName && invitationCode && invitationCode.params.invitationCode) {
      dispatch(fetchCompanyName(invitationCode.params.invitationCode));
    }
  }, []);

  return (
    <div className="registrationRightContent_invitationCancel">
      <div>
       {t('registration.invite.text1')}<span>{invitationCompanyName}</span>
      </div>
      <div className="registrationRightContent_invitation_button">
        {t('registration.invite.text2')}
      </div>
    </div>
  );
};

export default InvitationCacnel;
