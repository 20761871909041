import './SubscriptionPlan.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import RadioControl from '../../../../components/RadioControl';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { FormProvider, useForm } from 'react-hook-form';


type TariffPrice = {
  id: string;
  name: string;
  price: string;
  disabled?: boolean;
};

type SubscriptionPlanProps = {
  tariffs: TariffPrice[];
  current?: string;
  notes?: string[];
  setOpen: (open: boolean) => void;
  setTariff: (tariff: string) => void;
};

function SubscriptionPlan({ tariffs, current, notes, setOpen, setTariff }: SubscriptionPlanProps) {
  const { t } = useTranslation();
  const methods = useForm<{ tariff: string; }>();

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: { tariff: string; }) => {
    setTariff(data.tariff);
  };

  return (
    <div className='subscriptionPlan'>
      <CloseIcon onClick={onClose} />
      <h4>{t('subscriptions.plans.choose')}</h4>
      <FormProvider {...methods}>
        <div className='subscriptionPlan__tariffs'>
          {tariffs.map((tariff, index) => <RadioControl key={index} name="tariff" value={tariff.id}
            label={`${tariff.name}, ${tariff.price}`} defaultChecked={current === tariff.id}
            disabled={tariff.disabled} />)}
        </div>
        <Button type='submit' textType='small' text={t('save')} color='white'
          onClick={methods.handleSubmit(onSubmit)} />
      </FormProvider>
      {notes && notes.length > 0 &&
        <div className='subscriptionPlan__notes'>
          {notes.map((note, index) => <p key={index}>{note}</p>)}
        </div>
      }
    </div>
  );
}

export default SubscriptionPlan;
