import { useEffect } from 'react';
import './ChooseRole.scss';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getTranslationRoles } from '../../../utils/arrays';
import { bdAnchorsSupport } from '../../../../pages/Home/utils/consts';
import { useUpdateStep } from '../../utils/onboarding';
import { CompanyOnboardingUpdate } from '../../../../api/types';
import { onboardingSelector } from '../../../../api/companiesSelector';
import { YandexMetrikaCall } from "../../../../utils/utils";
import Input from '../../../../components/Input';
import Dropdown from '../../../../components/Dropdown';
import Button from '../../../../components/Button';
import MenuButtons from '../MenuButtons';


const ChooseRole = () => {
  const { t, i18n } = useTranslation();
  const onboarding = useSelector(onboardingSelector);
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const onSubmitOnboardingStep = useUpdateStep();
  const selectRole = getTranslationRoles();

  const schema = yup.object().shape({
    value: yup.string().typeError(t('empty_field')),
    custom_value: yup.string().max(256, t('error.form.max_length', { limit: 256 }))
      .when('value', (value, customValue) =>
        value === 'other' ? customValue.typeError(t('empty_field')).min(1, t('empty_field')) : customValue.nullable()
      )
  });

  const methods = useForm({
    mode: 'onChange', resolver: yupResolver(schema),
    defaultValues: {
      value: '',
      custom_value: ''
    },
  });

  useEffect(() => {
    if (onboarding) {
      const findStep = onboarding.steps.find((step) => step.type === 'choose_role');
      if (findStep) {
        methods.reset({ value: findStep.result?.value, custom_value: findStep.result?.customValue });
      }
    }
  }, []);

  const onHelp = () => YandexMetrikaCall('reachGoal', 'onb-need-help-btn-clck');

  const onNextStep = (result: { value: string, custom_value: string }) => {
    const data: CompanyOnboardingUpdate = {
      company_id: Number(url?.params.companyId),
      steps: [
        {
          is_finished: true,
          type: 'choose_role',
          result: { value: result.value, ...(result.value === 'other' ? { custom_value: result.custom_value } : {}) }
        }
      ]
    };
    YandexMetrikaCall('reachGoal', 'onb-step-choose-role');
    onSubmitOnboardingStep(data);
  };

  const onPrevStep = () => {
    const data: CompanyOnboardingUpdate = {
      company_id: Number(url?.params.companyId),
      steps: [
        {
          is_finished: false,
          type: 'watch_intro_video',
        }
      ]
    };
    onSubmitOnboardingStep(data);
  };

  return (
    <div className="chooseRole">
      <MenuButtons integrator={methods.watch('value')}/>
      <p className="regularText middle">{t('company_onboarding.chooseRole.title')}</p>
      <p className="smallText">{t('company_onboarding.chooseRole.description')}</p>
      <FormProvider {...methods}>
        <Dropdown label="" items={selectRole} labelKey="name" valueKey="id" name="value" placeholder={t('select')} />
        {methods.watch('value') === 'other' &&
          <Input name="custom_value" label={t('company_onboarding.chooseRole.otherRole')} />}
        <div className='chooseRole__buttons'>
          <Button color="white" textType="small" text={t('back')} onClick={onPrevStep} />
          <div className='chooseRole__buttons_right'>
            <a href={bdAnchorsSupport[i18n.language]} target="_blank" rel="noreferrer">
              <Button color="white" textType="small" text={t('help')} onClick={onHelp} />
            </a>
            <Button color="orange" textType="small" text={t('next')} onClick={methods.handleSubmit(onNextStep)} />
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ChooseRole;
