import { cloneElement, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCompanyRoleSelector } from '../../api/companiesSelector';
import { PERMISSIONS, PermissionsType } from './permission-maps';


export const useHasPermission = (permission?: PermissionsType, permissions?: PermissionsType[]) => {
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  if (permissions) {
    return permissions.some(p => currentRole?.permissions.includes(PERMISSIONS[p]));
  }
  if (permission && currentRole?.permissions) {
    return currentRole.permissions.includes(PERMISSIONS[permission]);
  }
  return false;
};

type WrapperPermissionPropsType = {
  permission?: PermissionsType;
  children: ReactElement;
  disabledProps?: { disabled: boolean; };
  permissions?: PermissionsType[];
};

export const WrapperPermission = ({ children, permission, disabledProps, permissions }: WrapperPermissionPropsType) => {
  const permissionGranted = useHasPermission(permission, permissions);

  if (!permissionGranted) {
    if (!disabledProps) {
      return <></>;
    }
    return cloneElement(children, { ...disabledProps });
  }
  return (
    <>{children}</>
  );
};
