import { useCallback, useEffect } from "react";
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from "../store/store";
import {
  onNewMessageHandler, selectChat, updateMessageDeliveryStatus, updateSearchedChatsValue,
  updateSearchedChatsWithContact
} from "../views/Chat/chatsSlice";
import { allConnectionsForChatSelector } from "../api/companiesSelector";
import { clearOnboarding, getMember, getRolesCompany, setCurrentCompany } from "../api/companiesSlice";
import { ChatType, MessageRequest, SocketDeliveryStatus } from "../views/Chat/ChatTypes";
import { ConvertedMessage, useSocketIO } from "../views/Chat/utils/soketio/socketIoContext";
import {
  channelConnectionDeliveryStatus, channelConnectionsNewMessage
} from "../views/Chat/utils/soketio/handlersSubscribeUnsubscribe";
import {
  handleReconnectConnectionDeliveryStatus, handleReconnectConnectionsNewMessage,
  ReconnectConnectionDeliveryStatusType, ReconnectConnectionsNewMessageType
} from "../views/Chat/utils/soketio/handlersReconnect";
import { clearTemplates } from "../views/Templates/templatesSlice";
import { clearNotifications } from "../views/Settings/Notifications/NotificationsSlice";


export function useUpdateCompany() {
  const dispatch = useAppDispatch();

  const updateCompany = useCallback((companyId: number) => {
    dispatch(selectChat({} as ChatType));
    dispatch(updateSearchedChatsWithContact(null));
    dispatch(updateSearchedChatsValue(''));
    dispatch(clearNotifications());
    dispatch(clearOnboarding());
    dispatch(clearTemplates());
    dispatch(getRolesCompany(companyId));
    dispatch(getMember(companyId)).then(() => dispatch(setCurrentCompany(companyId)));
  }, []);

  return updateCompany;
}

const connectionTypeList = ['whatsapp', 'waba', 'telegram', 'telegram_bot'];

const useSocketHandlers = () => {
  const { chatSocket } = useSocketIO();

  const dispatch = useAppDispatch();

  const socketNewMessageHandler = useCallback((convertedMessage: ConvertedMessage, message: MessageRequest) => {
    dispatch(onNewMessageHandler({ convertedMessage, message }));
  }, [chatSocket]);

  const onNewMessageDeliveryStatus = useCallback((deliveryStatus: SocketDeliveryStatus) => {
    dispatch(updateMessageDeliveryStatus(deliveryStatus));
  }, [chatSocket]);

  return { socketNewMessageHandler, onNewMessageDeliveryStatus };
};

export function useSocketSetup() {
  const { chatSocket } = useSocketIO();
  const connections = useSelector(allConnectionsForChatSelector);
  const currentChatId = useSelector((state: RootState) => state.chats.currentChat.chatId);
  const currentChatConnectionId = useSelector((state: RootState) => state.chats.currentChat.connectionId);
  const { socketNewMessageHandler, onNewMessageDeliveryStatus } = useSocketHandlers();

  useEffect(() => {
    if (connections.length > 0) {
      const payload = {
        connections,
        connectionTypeList,
        chatSocket,
        socketNewMessageHandler,
      };
      // Подписываемся на все подключения и на новые сообщения
      channelConnectionsNewMessage({ ...payload, channel: 'subscribe' });

      // Отписываемся от всех подключений и от новых сообщений при размонтировании компонента
      return () => channelConnectionsNewMessage({ ...payload, channel: 'unsubscribe' });
    }
  }, [connections]);

  useEffect(() => {
    const payload: ReconnectConnectionsNewMessageType = {
      connections,
      connectionTypeList,
      chatSocket,
      socketNewMessageHandler
    };
    const reconnectHandler = () => handleReconnectConnectionsNewMessage(payload);
    chatSocket.onReconnect(reconnectHandler);
    return () => {
      chatSocket.offReconnect(reconnectHandler);
    };
  }, [connections]);

  useEffect(() => {
    const payload: ReconnectConnectionDeliveryStatusType = {
      chatSocket,
      currentChat: { chatId: currentChatId, connectionId: currentChatConnectionId },
      onNewMessageDeliveryStatus
    };
    channelConnectionDeliveryStatus({
      ...payload,
      channel: 'subscribe',
    });
    const reconnectHandler = () => handleReconnectConnectionDeliveryStatus(payload);
    chatSocket.onReconnect(reconnectHandler);

    return () => {
      channelConnectionDeliveryStatus({
        ...payload,
        channel: 'unsubscribe',
      });
      chatSocket.offReconnect(reconnectHandler);
    };
  }, [currentChatId, currentChatConnectionId]);

}
