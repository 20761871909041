import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { onboardingGuideSelector, onboardingSelector } from '../../../../../../api/companiesSelector';
import { IntegrationType } from '../../../IntegrationTypes';
import { isConnectingIntegration } from '../../../../../../modals/OnboardingWizard/utils/guides';
import IntegrationItem from '../IntegrationItem';


type IntegrationsAvailableProps = {
  integrations: IntegrationType[];
};

function IntegrationsAvailable({ integrations }: IntegrationsAvailableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const matchRoute = useRouteMatch();
  const onboarding = useSelector(onboardingSelector);
  const onboardingGuideOn = useSelector(onboardingGuideSelector);

  const guideIntegration = () => {
    if (onboardingGuideOn === 'add_integration' && onboarding) {
      return isConnectingIntegration(onboarding);
    }
  };

  const onIntegrationClick = (item: IntegrationType) => {
    return (e: React.MouseEvent<HTMLElement>) => {
      if (item.published) {
        if (!onboardingGuideOn) {
          const path = item.type === 'api' ? '/setup/' : '/description/';
          history.push(`${matchRoute.url}${path}${item.type}`);
        }
      }
    }
  };

  return (<>
    <h1>{t('settings.integrations.available')}</h1>
    <div className='integrationsList__items'>{integrations.map((item) =>
      <IntegrationItem key={item.type} name={item.name} logoUrl={item.logoUrl} onChoose={onIntegrationClick(item)}
        guideOn={item.type === guideIntegration()} />
    )}
    </div>
  </>);
}

export default IntegrationsAvailable;
